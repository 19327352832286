import React, { ReactNode } from 'react';
import { getContainerClass } from '../../utils/conditional-style';
import { tw } from '../../utils/tailwind';
import { View, useWindowDimensions } from 'react-native';
import { HEADER_HEIGHT, twCb } from '../../utils/helper';

interface HeaderBarBaseProps {
  children: ReactNode;
  classNames?: string;
}

export const HeaderBarBase = (props: HeaderBarBaseProps) => {
  const { width } = useWindowDimensions();

  return (
    <View
      style={tw`${twCb(
        `flex-row m-h-full justify-between items-center py-2.5 text-white m-h-[${HEADER_HEIGHT}px]`,
        getContainerClass(width),
        props.classNames || '',
      )}`}>
      {props.children}
    </View>
  );
};
