import React, { useRef } from 'react';
import { tw } from '../../../utils/tailwind';
import Modal from 'react-native-modal';
import {
  View,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Text,
  ScrollView,
  TextInput,
  useWindowDimensions,
} from 'react-native';
import { CloseSVG } from '../../../../assets/svg';
import { QuantityInput } from '../../shared/quantity-input';
import { CartCommentInput } from '../cart/cart-comment-input';
import { LineItemOption } from './modal-line-item-form/line-item-option';
import {
  Bundle,
  Product,
} from '../../../api/types/cash-register/product-category-types';
import {
  LineItemFormInitialValuesType,
  useLineItemForm,
} from '../../../forms/use-line-item-form.hook';
import {
  FormattedBundleOptionType,
  formatBundleOption,
} from '../../../utils/cash-register/line-item-form.helper';
import { formatNumber, useT } from '../../../utils/helper';
import { LineItemAttributes } from '../../../api/types/cash-register/line-item-types';
import { calculateLineItemTotal } from '../../../utils/cash-register/line-item-total.helper';

interface ModalLineItemFormProps {
  isVisible: boolean;
  product: Product;
  onModalHide: () => void;
  onFormSubmit: (attributes: LineItemAttributes, product: Product) => void;
  initialValues?: LineItemFormInitialValuesType;
}

export const ModalLineItemForm = (props: ModalLineItemFormProps) => {
  // Hooks
  const t = useT();
  const windowDimensions = useWindowDimensions();
  const form = useLineItemForm({
    product: props.product,
    initialValues: props.initialValues,
    onSubmit: attributes => props.onFormSubmit(attributes, props.product),
  });

  // Refs to track LineItemOptions
  const scrollViewRef = useRef<ScrollView>(null);
  const lineItemRefs = useRef<{ [key: string]: View | null }>({});

  // Other constants
  const selectedBundles = form.values
    .line_item_bundle_selections_attributes as FormattedBundleOptionType[];
  const itemPrice = calculateLineItemTotal({
    product: props.product,
    productSize: form.values.product_size?.size,
    bundleSelections: selectedBundles.map(a => ({
      bundle_id: a.bundle_id,
      selection_id: a.selection_id,
    })),
    ingredientSelections: form.values.line_item_ingredients_attributes.map(
      a => ({
        ingredient_id: a.ingredient_id || a.id,
        quantity: a.quantity,
      }),
    ),
  });
  // Callbacks
  const onSelectedBundleOption = (
    bundle: Bundle,
    option: FormattedBundleOptionType,
  ) => {
    // Find current bundle option
    const currentBundle = selectedBundles.find(a => a.bundle_id === bundle.id);
    // Update current bundle option
    if (currentBundle && !option) {
      // option unselected, remove bundle from list
      form.setFieldValue(
        'line_item_bundle_selections_attributes',
        selectedBundles.filter(
          a => ![bundle.id, bundle.depending_bundle_id].includes(a.bundle_id),
        ),
      );
    } else {
      // option selected, add bundle to list
      form.setFieldValue('line_item_bundle_selections_attributes', [
        ...selectedBundles.filter(a => a.bundle_id !== bundle.id),
        option,
      ]);
    }
  };
  const onModalHide = () => {
    form.resetForm();
    props.onModalHide();
  };

  const handleSubmit = async () => {
    const isValid = await form.validateForm();

    if (Object.keys(isValid).length > 0) {
      // Find the first error field and scroll to it
      const firstErrorKey = Object.keys(isValid)[0];

      if (lineItemRefs.current[firstErrorKey]) {
        lineItemRefs.current[firstErrorKey]?.measureLayout(
          scrollViewRef.current as any,
          (x, y) => {
            scrollViewRef.current?.scrollTo({ y, animated: true });
          },
        );
      }
    } else {
      form.submitForm();
    }
  };

  return (
    <Modal
      isVisible={props.isVisible}
      onBackdropPress={onModalHide}
      onBackButtonPress={onModalHide}
      hideModalContentWhileAnimating={true}
      backdropTransitionInTiming={1}
      backdropTransitionOutTiming={1}
      animationOutTiming={1}
      animationInTiming={1}
      deviceWidth={windowDimensions.width}
      deviceHeight={windowDimensions.height}
      style={tw`xl:my-2.5 xl:max-w-[1004px] my-[22px] max-w-full w-full p-6 pt-0 bg-white 2xl:rounded-2xl rounded-2xl justify-start mx-auto`}>
      <TouchableWithoutFeedback touchSoundDisabled={true}>
        <View style={tw`flex-1 flex-col`}>
          <View style={tw`h-[21px] m-b-[3px]`}>
            <View
              style={tw`grid justify-self-center md:w-[140px] w-[86px] h-[5px] rounded-full bg-black m-auto`}
            />
          </View>
          {/* Close modal button */}
          <TouchableOpacity
            onPress={onModalHide}
            style={tw`absolute top-[21px] z-10 right-0 justify-center items-center`}>
            <CloseSVG
              stroke={tw.color('gray-500')}
              style={tw`w-8 h-8`}
              strokeWidth={2}
            />
          </TouchableOpacity>
          {/* Product name */}
          <Text
            style={tw`mb-5 max-w-[320px] w-full md:text-2xl text-xl md:leading-8 leading-[26px] font-bold text-gray-800`}>
            {props.product.name}
          </Text>
          {/* Form */}
          <ScrollView
            ref={scrollViewRef}
            style={tw`basis-10/12`}
            contentContainerStyle={tw`gap-y-6`}>
            {/* Included Ingredient options */}
            {props.product.included_ingredients ? (
              <LineItemOption
                title={t('includedIngredientsHeadline')}
                ref={ref =>
                  (lineItemRefs.current['included_ingredients'] = ref)
                }
                type="checkbox"
                options={props.product.included_ingredients}
                initialValue={form.values.included_ingredients}
                onSelect={options =>
                  form.setFieldValue('included_ingredients', options)
                }
              />
            ) : null}
            {/* Size options */}
            {props.product.sizes ? (
              <LineItemOption
                title={t('productSizeHeadline')}
                ref={ref => (lineItemRefs.current['product_size'] = ref)}
                type="radio"
                required={true}
                options={props.product.sizes}
                initialValue={form.values.product_size}
                onSelect={option => form.setFieldValue('product_size', option)}
              />
            ) : null}
            {/* Bundle options */}
            {props.product.bundles.map((bundle, index) =>
              !bundle.dependent_bundle_id ||
              selectedBundles.some(
                a => a.bundle_id === bundle.dependent_bundle_id,
              ) ? (
                <LineItemOption
                  key={index}
                  title={bundle.title}
                  ref={ref =>
                    (lineItemRefs.current[
                      'line_item_bundle_selections_attributes'
                    ] = ref)
                  }
                  required={!bundle.allow_blank}
                  type="radio"
                  isError={
                    !!form.errors.line_item_bundle_selections_attributes?.[
                      bundle.id
                    ]
                  }
                  initialValue={form.values.line_item_bundle_selections_attributes?.find(
                    a => a.bundle_id === bundle.id,
                  )}
                  options={formatBundleOption(bundle.id, bundle.options)}
                  onSelect={option => onSelectedBundleOption(bundle, option)}
                />
              ) : null,
            )}
            {/* Ingredient options */}
            {props.product.ingredients && props.product.ingredient_headline ? (
              <LineItemOption
                title={props.product.ingredient_headline}
                ref={ref =>
                  (lineItemRefs.current['line_item_ingredients_attributes'] =
                    ref)
                }
                type="checkbox"
                options={props.product.ingredients}
                initialValue={form.values.line_item_ingredients_attributes}
                canInputQuantity={true}
                onSelect={options =>
                  form.setFieldValue(
                    'line_item_ingredients_attributes',
                    options,
                  )
                }
              />
            ) : null}
            <CartCommentInput
              initialValue={form.values.comment}
              onChange={form.handleChange('comment')}
            />
          </ScrollView>
          {/* Quantity and total price */}
          <View style={tw`pt-4`}>
            <View style={tw`flex-row justify-between mb-4 gap-x-1`}>
              <View
                style={tw`flex-row w-[162px] h-[50px] justify-start justify-self-start items-center border border-gray-100 rounded-lg overflow-hidden`}>
                <QuantityInput
                  size="md"
                  initialValue={form.values.quantity}
                  min={1}
                  onChange={quantity =>
                    form.setFieldValue('quantity', quantity)
                  }
                />
              </View>
              <View
                style={tw`flex-row p-3.5 max-w-[156px] w-full border border-solid border-gray-400 rounded-lg`}>
                <TextInput
                  inputMode="numeric"
                  value={formatNumber(itemPrice * form.values.quantity)}
                  style={tw`text-xl leading-none font-medium text-gray-800 text-end max-w-[102px] mr-3.5 text-right`}
                />
                <Text
                  style={tw`text-xl leading-none font-medium text-gray-800 self-center`}>
                  €
                </Text>
              </View>
            </View>
            {/* Add and Back buttons */}
            <View style={tw`flex-row`}>
              <TouchableOpacity
                onPress={onModalHide}
                style={tw`md:w-[240px] w-[160px] md:py-6 py-4 px-3 mr-1 bg-gray-800 rounded-lg`}>
                <Text
                  style={tw`text-2xl leading-none text-center font-normal text-white`}>
                  {t('back')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handleSubmit}
                style={tw`grow md:py-6 py-4 px-3 bg-success rounded-lg`}>
                <Text
                  style={tw`text-2xl leading-none text-center font-normal text-white`}>
                  {t('add')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};
