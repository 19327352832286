import React from 'react';
import { Text, View } from 'react-native';
import { tw } from '../utils/tailwind';
import { twCb } from '../utils/helper';

type Props = {
  svg: React.ReactNode;
  children: string;
  textStyle?: string;
};

export const TextWithSVG = (props: Props) => {
  return (
    <View style={tw`xl:w-[49%] w-full`}>
      <View style={tw`flex-row gap-x-2.5 justify-start items-center`}>
        <View style={tw`md:w-8 md:h-8 w-7 h-7`}>{props.svg}</View>
        <Text
          style={tw`${twCb(
            'xl:text-lg text-base font-medium text-gray-900',
            props.textStyle,
          )}`}>
          {props.children}
        </Text>
      </View>
    </View>
  );
};
