import React from 'react';
import { AppStackScreenProps } from '../../../routes';
import { useNavigation } from '@react-navigation/native';
import { StoreArea } from '../../../api/types/cash-register/store-area-types';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { useT } from '../../../utils/helper';

interface StoreAreaNavigationProps {
  storeAreas: StoreArea[];
  activeStoreAreaId: number | undefined;
}

export const StoreAreaNavigation = (props: StoreAreaNavigationProps) => {
  // Hook calls
  const navigation =
    useNavigation<AppStackScreenProps<'StoreArea'>['navigation']>();

  const t = useT('cashRegister');
  const onStoreAreaButtonPress = (storeArea: StoreArea) => {
    const options = {
      store_area_id: storeArea.id,
    };

    // If the store area is already active, do nothing
    if (props.activeStoreAreaId === storeArea.id) {
      return;
    }

    // don't push to stack if already on the store area screen
    if (navigation.canGoBack()) {
      navigation.replace('StoreArea', options);
    } else {
      navigation.navigate('StoreArea', options);
    }
  };

  return (
    <>
      <FooterBar>
        {/* Store Area List */}
        {props.storeAreas.map(storeArea => (
          <FooterBarItem
            key={storeArea.id}
            active={props.activeStoreAreaId === storeArea.id}
            onPress={() => onStoreAreaButtonPress(storeArea)}
            text={storeArea.virtual ? t('virtual') : storeArea.name}
          />
        ))}
        {/* Navigate button */}
        <FooterBarItem
          key={'online'}
          active={false}
          onPress={() => navigation.navigate('VendorDashboard')}
          text={t('online')}
        />
      </FooterBar>
    </>
  );
};
