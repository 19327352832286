import React, { PropsWithChildren, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { tw } from '../utils/tailwind';
import { ArrowSVG } from '../../assets/svg';

type AccordionItemProps = PropsWithChildren<{
  title: string;
  chidren?: React.ReactNode;
}>;

export const AccordionItem = (props: AccordionItemProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <View
      style={tw`pb-2 pt-2 border-0 border-b-[1px] border-solid border-gray-100`}>
      <TouchableOpacity
        style={tw`flex-row justify-between items-center py-2 w-full text-gray-600`}
        onPress={() => setExpanded(prev => !prev)}>
        <Text style={tw`text-base font-medium text-gray-900`}>
          {props.title}
        </Text>
        <ArrowSVG style={tw`w-4 h-4`} />
      </TouchableOpacity>
      {expanded && props.children}
    </View>
  );
};
