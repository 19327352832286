import common from './common.json';
import screens from './screens.json';
import forms from './forms.json';
import cashRegister from './cash-register.json';

export default {
  common,
  screens,
  forms,
  cashRegister,
};
