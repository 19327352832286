import {
  Product,
  ProductSize,
} from '../../api/types/cash-register/product-category-types';

type BundleSelection = {
  selection_id: number | string;
  bundle_id: number;
};
type IngredientSelection = {
  ingredient_id: number;
  quantity: number;
};

interface calculateLineItemTotalFnProps {
  product: Product;
  productSize: string | undefined;
  bundleSelections?: BundleSelection[];
  ingredientSelections?: IngredientSelection[];
}

export const calculateLineItemTotal = (
  props: calculateLineItemTotalFnProps,
) => {
  return (
    productPriceBySize(props.product, props.productSize) +
    calculateBundleTotal(
      props.product,
      props.bundleSelections || [],
      props.productSize,
    ) +
    calculateIngredientTotal(
      props.product,
      props.ingredientSelections || [],
      props.productSize,
    )
  );
};

const productPriceBySize = (product: Product, size?: string) => {
  return size
    ? product.sizes?.find((s: ProductSize) => s.size === size)?.price ||
        product.price
    : product.price;
};

const calculateBundleTotal = (
  product: Product,
  selectedBundles: BundleSelection[],
  size?: string,
) => {
  return selectedBundles.reduce((acc, bundle) => {
    const selectionPrices = product.bundles
      .find(b => b.id === bundle.bundle_id)
      ?.options.find(selecttion => selecttion[1] === bundle.selection_id)?.[2]
      .data.prices;

    if (selectionPrices) {
      const price = parseFloat(selectionPrices[size || 'single']);
      return acc + price;
    } else {
      return acc;
    }
  }, 0);
};

const calculateIngredientTotal = (
  product: Product,
  selectedIngredients: IngredientSelection[],
  size?: string,
) => {
  return selectedIngredients.reduce((acc, ingredient) => {
    const ingredientPrices = product.ingredients?.find(
      i => i.id === ingredient.ingredient_id,
    );

    if (ingredientPrices) {
      const price = (ingredientPrices[`${size}_price`] ||
        ingredientPrices.price) as number;
      return acc + price * ingredient.quantity;
    } else {
      return acc;
    }
  }, 0);
};
