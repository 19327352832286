import React from 'react';
import { OrderLineItem } from '../../../api/types/cash-register/order-types';
import { View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { formatCurrency } from '../../../utils/helper';

interface SummmaryCardItemProps {
  lineItem: OrderLineItem;
}

export const SummmaryCardItem = (props: SummmaryCardItemProps) => {
  const unCountedClassNames = tw`line-through`;
  // Renderer
  const renderIngredient = (text: string, counted?: boolean) => {
    return (
      <Text
        key={text}
        style={[
          tw`text-base leading-none text-gray-800`,
          counted ? null : unCountedClassNames,
        ]}>
        {text}
      </Text>
    );
  };

  return (
    <View style={tw`flex-row gap-x-3 justify-between`}>
      <View style={tw`gap-y-1.5 grow-1 shrink-1`}>
        <Text
          style={[
            tw`text-lg leading-none font-medium text-gray-800`,
            props.lineItem.counted ? null : unCountedClassNames,
          ]}>
          {props.lineItem.quantity}x - {props.lineItem.name}
        </Text>
        <View style={tw`ml-3 gap-y-1.5`}>
          {props.lineItem.excluded_ingredients.map(ingredient =>
            renderIngredient(`- ${ingredient}`, props.lineItem.counted),
          )}
          {props.lineItem.included_ingredients.map(ingredient =>
            renderIngredient(`+ ${ingredient}`, props.lineItem.counted),
          )}
        </View>
      </View>
      <Text
        style={[
          tw`text-lg leading-none font-medium text-gray-800 grow-0`,
          props.lineItem.counted ? null : unCountedClassNames,
        ]}>
        {formatCurrency(props.lineItem.total_price)}
      </Text>
    </View>
  );
};
