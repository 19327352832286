import React from 'react';
import { ScrollView, Text, View, useWindowDimensions } from 'react-native';
import { tw } from '../../utils/tailwind';
import { useProductsMutations } from '../../api/hooks/vendor/products/use-products-mutations';
import { useProductsQueries } from '../../api/hooks/vendor/products/use-products-queries';
import QuerySuspense from '../../api/query-suspense';
import { TouchableOpacity } from '../../components/forms';
import { ProductList } from './components/product-list';
import { useT } from '../../utils/helper';
import { useBackHandler } from '../../hooks/use-back-handler';
import { getContainerClass } from '../../utils/conditional-style';

export const ProductsScreen = () => {
  useBackHandler();

  const { fetchProducts } = useProductsQueries();
  const { setAllProductsAvailabile } = useProductsMutations();
  const { width } = useWindowDimensions();
  const t = useT();

  return (
    <View style={tw`flex-1 bg-white ${getContainerClass(width)} pt-6`}>
      <ScrollView>
        <View style={tw`md:flex-row md:justify-between gap-y-4 mb-6`}>
          <Text
            style={tw`md:text-[28px] md:leading-normal md:basis-1/2 text-xl leading-none font-bold text-gray-900`}>
            {t('products')}
          </Text>
          {/* Set all available button */}
          <TouchableOpacity
            label={t('setAllAvailable')}
            onPress={() => setAllProductsAvailabile.mutate()}
            theme="blue"
            extraStyle="py-3 px-6 rounded-[10px]"
            extraLabelStyle="text-base normal-case leading-normal"
          />
        </View>

        <View>
          {/* List of products grouped by category */}
          <QuerySuspense
            query={fetchProducts}
            onSuccess={data => (
              <ProductList productCategories={data.product_categories} />
            )}
          />
        </View>
      </ScrollView>
    </View>
  );
};
