import React from 'react';
import { HeaderBar as VendorHeaderBar } from '../components/header-bar';
import { HeaderBar as CashRegisterHeaderBar } from '../components/cash-register/header-bar/header-bar';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AppStackParamList } from '../routes';
import { DeliveredCartsScreen } from '../screens/carts/delivered-carts.screen';
import { VendorDashboardScreen } from '../screens/vendor/dashboard.screen';
import { ProductsScreen } from '../screens/products/products.screen';
import { useBackHandler } from '../hooks/use-back-handler';
import { useShortcutIcons } from '../hooks/use-shortcut-icons';
import { useMessageHandler } from '../hooks/webview/use-message-handler';
import { StoreAreaScreen } from '../screens/cash-register/store-area/store-area.screen';
import { CashierSwitchScreen } from '../screens/cash-register/cashier/cashier-switch.screen';
import { CartShowScreen } from '../screens/cash-register/cart/cart-show.screen';
import { useWindowDimensions } from 'react-native';
import { CartNewScreen } from '../screens/cash-register/cart/cart-new.screen';
import { useTenant } from '../contexs/tenant-context';
import { OrderHistoryScreen } from '../screens/cash-register/order/order-history.screen';
import { InvoiceListScreen } from '../screens/cash-register/invoices/invoice-list.screen';
import { useCashierSwitchScreenOptions } from './cashier-switch-stack-navigator';
import { OrderSessionEditScreen } from '../screens/cash-register/order-session/order-session-edit.screen';
import { OrderSessionPayScreen } from '../screens/cash-register/order-session/order-session-pay.screen';
import { CustomerIndexScreen } from '../screens/cash-register/customer/customer-index.screen';
import { InvoiceShowScreen } from '../screens/cash-register/invoices/invoice-show.screen';
import { InvoiceWorkflowPendingScreen } from '../screens/cash-register/invoices/invoice-workflow-pending.screen';
import { InvoiceShowQrScreen } from '../screens/cash-register/invoices/invoice-show-qr-screen';
import { CashbookScreen } from '../screens/cash-register/cashbook/cashbook.screen';
import useCashDrawerChannel from '../websocket/hooks/use-cash-drawer-channel';

const AppStack = createNativeStackNavigator<AppStackParamList>();
export const AppStackNavigator = () => {
  // Hooks
  const { width } = useWindowDimensions();
  const { tenant } = useTenant();
  const cashierSwitchScreenOptions = useCashierSwitchScreenOptions();
  // Other Constants
  const cashRegisterEnabled = tenant.plan?.cash_register_enabled;

  const baseOptions = {
    headerBackVisible: false,
    headerMode: 'screen',
    title: tenant?.name,
    header: () => null,
  };

  const vendorOptions = Object.assign({}, baseOptions, {
    header: () => <VendorHeaderBar />,
  });

  const cashRegisterOptions = Object.assign({}, baseOptions, {
    header: () => <CashRegisterHeaderBar />,
  });

  const cashierRegisterWithConditionalHeader = Object.assign(
    {},
    cashRegisterOptions,
    {
      headerShown: width >= 1440,
    },
  );

  // Web
  useBackHandler();
  useShortcutIcons(tenant.icons || null);
  useMessageHandler();
  useCashDrawerChannel();

  return (
    <AppStack.Navigator
      initialRouteName={cashRegisterEnabled ? 'Root' : 'VendorDashboard'}>
      {cashRegisterEnabled ? (
        <>
          <AppStack.Screen
            name={'Root'}
            component={StoreAreaScreen}
            options={cashRegisterOptions}
          />
          <AppStack.Screen
            name={'StoreArea'}
            component={StoreAreaScreen}
            getId={({ params }) => params?.store_area_id.toString()}
            options={cashRegisterOptions}
          />
          <AppStack.Screen
            name={'CashiersSwitch'}
            component={CashierSwitchScreen}
            options={cashierSwitchScreenOptions}
          />
          <AppStack.Screen
            name={'CartNew'}
            component={CartNewScreen}
            options={cashierRegisterWithConditionalHeader}
            getId={({ params }) => JSON.stringify(params)}
          />
          <AppStack.Screen
            name={'CartShow'}
            component={CartShowScreen}
            options={cashierRegisterWithConditionalHeader}
            getId={({ params }) => JSON.stringify(params)}
          />
          <AppStack.Screen
            name={'CartShowCategory'}
            component={CartShowScreen}
            options={cashierRegisterWithConditionalHeader}
            getId={({ params }) => params?.cart_id.toString()}
          />
          <AppStack.Screen
            name={'Orders'}
            component={OrderHistoryScreen}
            options={cashierRegisterWithConditionalHeader}
          />
          <AppStack.Screen
            name={'Invoices'}
            component={InvoiceListScreen}
            options={cashierRegisterWithConditionalHeader}
          />
          <AppStack.Screen
            name={'InvoiceShow'}
            component={InvoiceShowScreen}
            options={cashierRegisterWithConditionalHeader}
            getId={({ params }) => params?.id.toString()}
          />
          <AppStack.Screen
            name={'InvoiceShowQr'}
            component={InvoiceShowQrScreen}
            options={cashierRegisterWithConditionalHeader}
            getId={({ params }) => params?.id.toString()}
          />
          <AppStack.Screen
            name={'InvoiceWorkflowPending'}
            component={InvoiceWorkflowPendingScreen}
            options={cashierRegisterWithConditionalHeader}
          />
          <AppStack.Screen
            name={'Customers'}
            component={CustomerIndexScreen}
            options={cashierRegisterWithConditionalHeader}
          />
          <AppStack.Screen
            name={'Cashbook'}
            component={CashbookScreen}
            options={cashierRegisterWithConditionalHeader}
          />
          <AppStack.Screen
            name={'OrderSessionEdit'}
            component={OrderSessionEditScreen}
            options={cashierRegisterWithConditionalHeader}
            getId={({ params }) => params?.id.toString()}
          />
          <AppStack.Screen
            name={'OrderSessionPay'}
            component={OrderSessionPayScreen}
            options={cashierRegisterWithConditionalHeader}
            getId={({ params }) => params?.id.toString()}
          />
        </>
      ) : null}

      <AppStack.Screen
        name={'VendorDashboard'}
        component={VendorDashboardScreen}
        options={vendorOptions}
      />
      <AppStack.Screen
        name={'DeliveredCarts'}
        component={DeliveredCartsScreen}
        options={vendorOptions}
      />
      <AppStack.Screen
        name={'Products'}
        component={ProductsScreen}
        options={vendorOptions}
      />
    </AppStack.Navigator>
  );
};
