import { useEffect } from 'react';
import { NativeModules } from 'react-native';
import { Platform } from 'react-native';

export default function useOpeningStateObserver(openingStateName: string) {
  const { ScreenModule } = NativeModules;

  useEffect(() => {
    if (openingStateName !== 'closed' && Platform.OS === 'android') {
      ScreenModule.setScreenOn();
    }
  }, [openingStateName]);
}
