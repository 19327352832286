import { ProductCategory } from '../api/types/vendor/products-types';

// Function to filter products by name and remove empty categories
export const filterProducts = (
  categories: ProductCategory[],
  searchQuery: string,
) => {
  const filteredCategories = categories.reduce<ProductCategory[]>(
    (result, category) => {
      const filteredProducts = category.products.filter(product =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );

      if (filteredProducts.length > 0) {
        result.push({
          ...category,
          products: filteredProducts,
        });
      }

      return result;
    },
    [],
  );

  return filteredCategories;
};
