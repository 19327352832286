import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import { ProductCategoriesResponse } from '../../../types/cash-register/product-category-types';
import { CartType } from '../../../types/cash-register/cart-types';

export const useProductCategoryQueries = (cartType: CartType) => {
  const fetchProductCategories = useQuery(
    ['productCategories', cartType],
    async () =>
      request({
        method: 'get',
        url: '/store/product_categories.json',
        params: {
          cart_type: cartType,
        },
      }) as Promise<ProductCategoriesResponse>,
    {
      // This means that the next time the query is run, it will refetch
      // the data if it's more than 15 minutes old.
      staleTime: 1000 * 60 * 15,
    },
  );

  return { fetchProductCategories };
};
