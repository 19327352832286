import React from 'react';
import {
  Text,
  TouchableWithoutFeedback,
  View,
  useWindowDimensions,
} from 'react-native';
import Modal from 'react-native-modal';
import { tw } from '../../../utils/tailwind';
import { useT } from '../../../utils/helper';
import { TouchableOpacity as Button } from '../../forms';
import { getModalBaseClasses } from '../../../utils/conditional-style';

export interface ModalConfirmationProp {
  isVisible: boolean;
  content: string;
  confirmText?: string;
  hideCancel?: boolean;
  contentWrapperClassNames?: string;
  children?: React.ReactNode;
  onModalHide?: () => void;
  onConfirm?: () => void;
}

export const ModalConfirmation = (props: ModalConfirmationProp) => {
  const t = useT();
  const windowDimensions = useWindowDimensions();

  return (
    <Modal
      isVisible={props.isVisible}
      onBackdropPress={props.onModalHide}
      onBackButtonPress={props.onModalHide}
      hideModalContentWhileAnimating={true}
      backdropTransitionInTiming={1}
      backdropTransitionOutTiming={1}
      animationOutTiming={1}
      animationInTiming={1}
      deviceWidth={windowDimensions.width}
      deviceHeight={windowDimensions.height}>
      <TouchableWithoutFeedback touchSoundDisabled={true}>
        <View
          style={[
            tw`${getModalBaseClasses()} pt-6 px-6 w-[468px]`,
            tw`${props.contentWrapperClassNames || ''}`,
          ]}>
          <Text
            style={[
              tw`md:text-2xl text-xl font-bold text-gray-800 w-full leading-none`,
              props.children ? tw`mb-5` : tw`mb-6`,
            ]}>
            {props.content}
          </Text>

          {props.children ? props.children : null}

          <View style={tw`gap-3 w-full`}>
            {props.onConfirm ? (
              <Button
                label={props.confirmText || t('yes')}
                onPress={props.isVisible ? props.onConfirm : () => {}} // prevent double tap.
                extraStyle="py-5"
                extraLabelStyle="leading-none font-medium"
              />
            ) : null}

            {props.onModalHide && !(props.hideCancel || false) ? (
              <Button
                theme="white"
                label={t('interrupt')}
                onPress={props.onModalHide}
                extraStyle="border-0 py-5"
                extraLabelStyle="leading-none font-medium text-gray-800"
              />
            ) : null}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};
