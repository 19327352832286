import React from 'react';
import { AppStackNavigator } from './app-stack-navigator';
import { CashierSwitchStackNavigator } from './cashier-switch-stack-navigator';
import { useAuth } from '../contexs/auth-context';
import { TenantProvider } from '../providers/tenant-provider';
import { useTenantsQueries } from '../api/hooks/tenants/use-tenants-queries';
import QuerySuspense from '../api/query-suspense';
import SplashScreen from '../screens/splash/splash.screen';

export const ModuleNavigator = () => {
  // Hooks
  const { currentCashier } = useAuth();
  const { fetchTenantsDashboard } = useTenantsQueries();

  return (
    <QuerySuspense
      query={fetchTenantsDashboard}
      memo={currentCashier?.id || 'NO_CASHIER'}
      hideRefetchLoading={true}
      loadingComponent={<SplashScreen />}
      onSuccess={data =>
        data.tenant.plan?.cash_register_enabled && !currentCashier ? (
          <CashierSwitchStackNavigator />
        ) : (
          <TenantProvider
            tenant={Object.assign({}, data.tenant, {
              opening_state: data.opening_state,
            })}>
            <AppStackNavigator />
          </TenantProvider>
        )
      }
    />
  );
};
