import React from 'react';
import { TouchableOpacity, Text, ViewStyle } from 'react-native';
import { tw } from '../../../utils/tailwind';

interface NumericInputPadButtonProps {
  content: string | JSX.Element;
  style?: ViewStyle | ViewStyle[];
  textStyle?: ViewStyle | ViewStyle[];
  disabled: boolean;
  onPress: () => void;
}

export const NumericInputPadButton = (props: NumericInputPadButtonProps) => {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      disabled={props.disabled}
      style={[
        tw`justify-center items-center xs:min-w-[90px] xs:min-h-[90px]
           min-w-[80px] min-h-[80px] border border-solid border-gray-300 rounded-lg
           bg-white`,
        props.style,
        props.disabled ? tw`bg-gray-200` : tw``,
      ]}>
      <Text
        style={[
          tw`text-[42px] font-medium leading-none text-gray-800`,
          props.textStyle,
        ]}>
        {props.content}
      </Text>
    </TouchableOpacity>
  );
};

NumericInputPadButton.defaultProps = {
  disabled: false,
};
