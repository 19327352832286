import React, { Dispatch, SetStateAction, memo, useState } from 'react';
import { View, Text } from 'react-native';
import tw from 'twrnc';
import { ProductCategory } from '../../../api/types/vendor/products-types';
import { AccordionItem } from '../../../components/accordion-item';
import { TextInput } from '../../../components/forms';
import { ProductListItem } from '../../../components/product-list-item';
import { filterProducts } from '../../../utils/filter';
import { useT } from '../../../utils/helper';

// Memoize the search input to prevent rerender
const SearchInput = memo(
  (props: { onChangeText: Dispatch<SetStateAction<string>> }) => {
    const t = useT();

    return (
      <TextInput
        name="query"
        autoCapitalize="none"
        placeholder={t('searchByProducts').toString()}
        onChangeText={props.onChangeText}
        style={tw`mb-6 py-3 px-4 text-sm bg-gray-100 rounded-xl mt-0`}
      />
    );
  },
);

type ProductListProps = {
  productCategories: ProductCategory[];
};

export const ProductList = (props: ProductListProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const t = useT();

  const filteredProductCategories = filterProducts(
    props.productCategories,
    searchQuery,
  );

  return (
    <>
      {/* Search input */}
      <SearchInput onChangeText={setSearchQuery} />

      <View>
        {filteredProductCategories.map((category, index) => (
          <AccordionItem key={index} title={category.name}>
            <View style={tw`gap-y-3 pb-3`}>
              {/* Product header */}
              <View style={tw`flex-row justify-between items-center px-3`}>
                <Text style={tw`text-gray-500`}>{t('name')}</Text>
                <Text style={tw`text-gray-500`}>{t('soldOut')}</Text>
              </View>

              {category.products.map((product, productIdx) => (
                <ProductListItem
                  key={productIdx}
                  product={product}
                  onSoldOutToggle={() => (product.sold_out = !product.sold_out)}
                />
              ))}
            </View>
          </AccordionItem>
        ))}
      </View>
    </>
  );
};
