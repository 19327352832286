import { useFormik } from 'formik';
import * as yup from 'yup';

const useCartForm = (onSubmit: () => void) => {
  const cartFormSchema = yup.object().shape({
    email: yup.string(),
    processing_time: yup.number(),
  });

  type FormContents = yup.InferType<typeof cartFormSchema>;

  const form = useFormik({
    initialValues: {} as FormContents,
    initialTouched: {},
    validationSchema: cartFormSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit,
  });

  return form;
};

export { useCartForm };
