import React from 'react';
import { tw } from '../../utils/tailwind';
import { Text } from 'react-native';
import { useT } from '../../utils/helper';
import { formatCurrency } from '../../utils/helper';

interface TotalDepositProps {
  total_deposit: number;
}

export const TotalDeposit = (props: TotalDepositProps) => {
  const t = useT();
  const total_deposit = formatCurrency(props.total_deposit);

  return (
    <Text style={tw`tracking-wider xl:text-base text-sm text-gray-700`}>
      {t('totalDeposit', { total_deposit: total_deposit })}
    </Text>
  );
};
