import React from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../utils/tailwind';

type CartCommentProps = {
  title: string;
  content: string;
};

export const CartComment = (props: CartCommentProps) => {
  return (
    <View
      style={tw`md:mb-2,5 md:pt-3 md:pb-5 mb-0 px-3 pb-2.5 border-0 border-b-2 border-solid border-gray-100`}>
      <Text style={tw`xl:text-lg mb-2,5 text-base font-medium text-gray-900`}>
        {props.title}
      </Text>
      <Text style={tw`tracking-wider xl:text-base text-sm text-gray-700`}>
        {props.content}
      </Text>
    </View>
  );
};
