import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { tw } from '../utils/tailwind';
import { CheckSVG, CloseSVG, MenuAltSVG } from '../../assets/svg';
import { TenantsDashboardResponse } from '../api/types/tenants-types';
import { getHeaderBarColorTheme } from '../utils/conditional-style';
import { setLineBreaks, twCb } from '../utils/helper';
import useOpeningStateObserver from '../hooks/use-opening-state-observer';
import { getOpeningState } from '../utils/tenant-helper';

interface StoreControlButtonProps {
  dashboard: TenantsDashboardResponse;
  onPress: () => void;
}

export const StoreControlButton = (props: StoreControlButtonProps) => {
  const state = getOpeningState(props.dashboard.opening_state);
  const storeClosed = ['paused', 'closed'].includes(state);
  const openingStateName = setLineBreaks(
    props.dashboard.opening_state.name,
    10,
  );

  useOpeningStateObserver(state);

  // Set SVG icon based on opening state
  const renderSvg = () => {
    const svgProps = {
      stroke: getHeaderBarColorTheme(state),
      style: tw`w-5 h-5`,
    };

    if (storeClosed) {
      return <CloseSVG {...svgProps} />;
    } else if (['delivery_paused', 'pickup_paused'].includes(state)) {
      return <MenuAltSVG {...svgProps} />;
    } else {
      return <CheckSVG {...svgProps} />;
    }
  };

  return (
    <View>
      <TouchableOpacity
        onPress={() => props.onPress()}
        style={tw`flex-row gap-x-2`}>
        <View style={tw`p-3 justify-center items-center rounded-lg bg-white`}>
          {renderSvg()}
        </View>
        <Text
          style={tw`${twCb(
            'md:text-2xl text-base font-bold text-white m-auto',
            openingStateName.includes('\n') ? 'leading-none' : '',
          )}`}>
          {openingStateName}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
