import React from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../utils/tailwind';
import { SportsSVG, RidingSVG, TableSVG } from '../../../assets/svg';

interface CartHeaderProps {
  orderNumber: number;
  formattedOrderedAt: string;
  cartTypeTranslated: string;
  cartType: string;
}

function getCartTypeSetting(cartType: string) {
  let bgColor = '';
  let cartTypeSvg;
  const cartTypeSvgStyle = tw.style('w-6 h-6');

  switch (cartType) {
    case 'delivery':
      bgColor = 'bg-green-600';
      cartTypeSvg = <RidingSVG style={cartTypeSvgStyle} />;
      break;
    case 'pickup':
      bgColor = 'bg-gray-700';
      cartTypeSvg = <SportsSVG style={cartTypeSvgStyle} />;
      break;
    case 'table_order':
      bgColor = 'bg-blue-600';
      cartTypeSvg = <TableSVG style={cartTypeSvgStyle} />;
  }

  return { bgColor, cartTypeSvg };
}

export const CartHeader = (props: CartHeaderProps) => {
  const { bgColor, cartTypeSvg } = getCartTypeSetting(props.cartType);

  return (
    <View
      style={tw`flex-row justify-between mb-2.5 p-3 ${bgColor} rounded-t-lg`}>
      <View style={tw`gap-x-2 flex-row`}>
        <Text style={tw`xl:text-lg text-base font-bold text-white`}>
          # {props.orderNumber}
        </Text>
        <Text style={tw`xl:text-lg text-base text-white`}>-</Text>
        <Text style={tw`xl:text-lg text-base text-white`}>
          {props.formattedOrderedAt}
        </Text>
      </View>
      <View style={tw`flex-row gap-x-2.5 items-center`}>
        {cartTypeSvg}
        <Text style={tw`xl:text-lg text-base font-bold text-white`}>
          {props.cartTypeTranslated}
        </Text>
      </View>
    </View>
  );
};
