import {
  LineItemBundleSelection,
  LineItemIngredient,
} from '../../api/types/cash-register/cart-types';
import { LineItemAttributes } from '../../api/types/cash-register/line-item-types';
import {
  Ingredient,
  Product,
} from '../../api/types/cash-register/product-category-types';
import { calculateLineItemTotal } from './line-item-total.helper';

export const buildLineItemFromParams = (
  lineItemParams: LineItemAttributes,
  product: Product,
) => {
  const name = getLineItemName(product, lineItemParams.product_size);

  const line_item_bundle_selections =
    (lineItemParams.line_item_bundle_selections_attributes ||
      []) as LineItemBundleSelection[];

  const line_item_ingredients =
    (lineItemParams.line_item_ingredients_attributes ||
      []) as LineItemIngredient[];

  const itemPrice = calculateLineItemTotal({
    product: product,
    productSize: lineItemParams.product_size,
    bundleSelections:
      lineItemParams.line_item_bundle_selections_attributes?.map(selection => ({
        selection_id: selection.selection_id,
        bundle_id: selection.bundle_id,
      })),
    ingredientSelections: lineItemParams.line_item_ingredients_attributes?.map(
      ingredient => ({
        ingredient_id: ingredient.ingredient_id,
        quantity: ingredient.quantity,
      }),
    ),
  });

  const ingredientFromBundles = line_item_bundle_selections.reduce(
    (acc, selection) => {
      const bundle = product.bundles?.find(b => b.id === selection.bundle_id);

      if (!bundle) {
        return acc;
      }

      const bundleSelection = bundle.options?.find(
        option => option[1] === selection.selection_id,
      );

      if (!bundleSelection) {
        return acc;
      }

      return [...acc, bundleSelection[0]];
    },
    [] as string[],
  );

  const { included_ingredients, excluded_ingredients } = getSelectedIngredients(
    {
      ingredients: product.included_ingredients || [],
      selectedIngredientIds: lineItemParams.included_ingredient_ids,
      selelectedAddtionalIngredients: line_item_ingredients,
    },
  );

  return {
    ...lineItemParams,
    persisted: false,
    name,
    included_ingredient_ids: undefined,
    single_price: itemPrice,
    total_price: itemPrice * lineItemParams.quantity,
    line_item_included_ingredients: [],
    included_ingredients: ingredientFromBundles.concat(included_ingredients),
    excluded_ingredients,
    line_item_bundle_selections,
    line_item_ingredients,
  };
};

interface GetSelectedIngredientsFnProps {
  ingredients: Ingredient[];
  selectedIngredientIds?: string[] | number[];
  selelectedAddtionalIngredients: LineItemIngredient[];
}

const getSelectedIngredients = (props: GetSelectedIngredientsFnProps) => {
  if (!props.ingredients || !props.selectedIngredientIds) {
    return {
      included_ingredients: [],
      excluded_ingredients: [],
    };
  }

  // due to API condition selectedIngredientIds may have this format ['']
  // when user selects no ingredients
  const filteredSelectedIngredientIds = props.selectedIngredientIds.filter(
    id => typeof id === 'number',
  );

  const included_ingredients = props.selelectedAddtionalIngredients.map(
    ingredient => {
      const ingredientName =
        props.ingredients.find(i => i.id === ingredient.ingredient_id)?.name ||
        'unknown ingredient';

      return ingredient.quantity > 1
        ? `${ingredient.quantity}x ${ingredientName}`
        : ingredientName;
    },
  );

  const excluded_ingredients = props.ingredients
    .filter(
      ingredient => !filteredSelectedIngredientIds.includes(ingredient.id),
    )
    .map(ingredient => ingredient.name);

  return {
    included_ingredients,
    excluded_ingredients,
  };
};

const getLineItemName = (product: Product, productSize?: string) => {
  if (!productSize) {
    return product.name;
  }

  const productSizeName = product.sizes?.find(
    size => size.size === productSize,
  );

  return `${product.name} (${productSizeName?.name_short})`;
};
