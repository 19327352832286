import { Cart } from '../types/vendor/carts-types';

export const compareCartsFunction = (a: Cart, b: Cart) => {
  // Sort by 'estimated_at' (null values first)
  if (a.estimated_at === null && b.estimated_at !== null) {
    return -1;
  }
  if (a.estimated_at !== null && b.estimated_at === null) {
    return 1;
  }

  // Sort by 'ordered_at' in descending order
  if (a.estimated_at === null && b.estimated_at === null) {
    if (a.ordered_at > b.ordered_at) {
      return -1;
    }
    if (a.ordered_at < b.ordered_at) {
      return 1;
    }
  }

  // Sort by 'ordered_at' in descending order for non-null 'estimated_at'
  if (a.estimated_at !== null && b.estimated_at !== null) {
    if (a.ordered_at > b.ordered_at) {
      return -1;
    }
    if (a.ordered_at < b.ordered_at) {
      return 1;
    }
  }

  return 0; // If both 'estimated_at' are null or not null, maintain their original order
};
