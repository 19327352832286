import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { MinusSvg, PlusSvg } from '../../../assets/svg';
import { tw } from '../../utils/tailwind';
import { twCb } from '../../utils/helper';

interface QuantityInputProps {
  onChange: (quantity: number) => void;
  displayMax?: boolean;
  initialValue?: number;
  min?: number;
  max?: number;
  value?: number;
  // when button +/- is disabled, instead of triggering the button (SVG)
  // it will trigger the parent element (possibly TouchableOpacity)
  preventButtonFromDisabled?: boolean;
  size?: 'md' | 'sm' | 'xs';
}

export const QuantityInput = (props: QuantityInputProps) => {
  // Hooks
  const [locQuantity, setLocQuantity] = React.useState<number>(
    props.initialValue === undefined ? 0 : props.initialValue,
  );
  // Other Constants
  const quantity = props.value ?? locQuantity;
  // Callbacks
  const shouldDisableMinusButton = () => {
    // If min is not defined, return false
    return props.min === undefined ? false : quantity <= props.min;
  };
  const shouldDisablePlusButton = () => {
    // If max is not defined, return false
    return props.max === undefined ? false : quantity >= props.max;
  };
  const onQuantityChange = (newQuantity: number) => {
    if (
      (newQuantity < quantity && shouldDisableMinusButton()) ||
      (newQuantity > quantity && shouldDisablePlusButton())
    ) {
      return;
    }

    setLocQuantity(newQuantity);
    props.onChange(newQuantity);
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => onQuantityChange(quantity - 1)}
        disabled={
          !props.preventButtonFromDisabled && shouldDisableMinusButton()
        }
        style={tw`justify-center items-center p-2 bg-gray-100`}>
        <MinusSvg
          style={tw`${Styles.svg[props.size || 'md']}`}
          stroke={tw.color('gray-800')}
          strokeWidth={2.2}
        />
      </TouchableOpacity>
      <Text
        style={tw`${twCb(
          'text-gray-800 leading-none text-center m-auto',
          Styles.text[props.size || 'md'],
        )}`}>
        {quantity} {props.displayMax && props.max ? `/${props.max}` : null}
      </Text>
      <TouchableOpacity
        onPress={() => onQuantityChange(quantity + 1)}
        disabled={!props.preventButtonFromDisabled && shouldDisablePlusButton()}
        style={tw`justify-center items-center p-2 bg-gray-100`}>
        <PlusSvg
          style={tw`${Styles.svg[props.size || 'md']}`}
          stroke={tw.color('gray-800')}
        />
      </TouchableOpacity>
    </>
  );
};

const Styles = {
  text: {
    md: 'mx-2 min-w-12 text-2xl font-semibold',
    sm: 'min-w-6 text-lg font-medium',
    xs: 'min-w-4 text-base font-medium',
  },
  svg: {
    md: 'w-5 h-5 p-1.5',
    sm: 'w-4 h-4 p-0.5',
    xs: 'w-3 h-3 p-0.5',
  },
};
