import React, { useState } from 'react';
import { tw } from '../../../utils/tailwind';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { View } from 'react-native';
import {
  FilterableAtttributes,
  TransactionFilterForm,
} from '../../../components/cash-register/transaction/transaction-filter-form';
import { useAuth } from '../../../contexs/auth-context';
import { AppStackParamList } from '../../../routes';
import { RouteProp } from '@react-navigation/native';
import { OrderList } from './order-list';
import { useT } from '../../../utils/helper';

interface OrderHistoryScreenProps {
  route: RouteProp<AppStackParamList, 'Orders'>;
}

export const OrderHistoryScreen = (props: OrderHistoryScreenProps) => {
  const paramStoreTableId = props.route.params?.store_table_id;
  // Standard Hooks
  const { currentCashier } = useAuth();
  const t = useT('screens', { keyPrefix: 'Orders' });
  // States
  const [filterBy, setFilterBy] = useState<FilterableAtttributes>({
    store_table_id_eq: paramStoreTableId,
    cashier_id_eq: currentCashier?.id,
  });
  // Rest of Constants
  const { date, ...q } = filterBy;

  return (
    <ScreenContainer title={t('pageTitle')} scrollable={false}>
      <View style={tw`flex-1 flex-col`}>
        <TransactionFilterForm
          includeStoreTableFilter={!paramStoreTableId}
          initialValues={filterBy}
          documentType="Order"
          onChange={setFilterBy}
        />

        <OrderList q={{ ordered_at_on_date: date, ...q }} />
      </View>
    </ScreenContainer>
  );
};
