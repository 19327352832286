import { useQuery } from '@tanstack/react-query';
import request from '../../../request';
import {
  StoreTableParams,
  StoreTablesResponse,
} from '../../../types/cash-register/store-table-types';

export const useStoreTableQueries = (params?: StoreTableParams) => {
  const fetchStoreTables = useQuery(
    ['storeTables'],
    async () =>
      request({
        method: 'get',
        url: '/store/store_tables.json',
        params: params,
      }) as Promise<StoreTablesResponse>,
    {
      initialData: [],
    },
  );

  return { fetchStoreTables };
};
