import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useT } from '../../../utils/helper';
import request from '../../request';
import { TenantsDashboardResponse } from '../../types/tenants-types';

export const useTenantsMutations = () => {
  const queryClient = useQueryClient();
  const t = useT();

  const setTurnOff = useMutation(
    async (url: string) =>
      request({
        method: 'put',
        url,
      }) as Promise<null>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenantsDashboard']);
      },
    },
  );

  const setDisconnected = () => {
    queryClient.setQueryData(
      ['tenantsDashboard'],
      (prev: TenantsDashboardResponse | undefined) => {
        if (prev) {
          return {
            ...prev,
            opening_state: {
              ...prev.opening_state,
              name: t('disconnected'),
              disconnected: true,
            },
          };
        }
        return prev;
      },
    );
  };

  return { setTurnOff, setDisconnected };
};
