import React from 'react';
import { Dimensions, Image, ScrollView, View } from 'react-native';
import tw from 'twrnc';
import Logo from '../../../assets/logo.png';
import { SignInForm } from './components/signin-form';

const SCREENWIDTH = Dimensions.get('window').width;

const SignInScreen = () => {
  return (
    <ScrollView style={tw`bg-white h-100`}>
      <View style={tw`p-5`}>
        <Image
          source={Logo}
          alt="logo.png"
          style={tw`max-w-[150px] h-[50px] w-full m-auto`}
        />
      </View>
      <View
        style={tw`m-auto w-full max-w-[${
          SCREENWIDTH - 50
        }px] min-h-[500px] mt-10`}>
        <SignInForm />
      </View>
    </ScrollView>
  );
};

export default SignInScreen;
