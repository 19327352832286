import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthDataType } from '../../contexs/auth-context';

const authDataStorageKey = 'AuthData';

export async function fetchAuthData(): Promise<AuthDataType | null> {
  const authDataSerialized = await AsyncStorage.getItem(authDataStorageKey);

  if (!authDataSerialized) {
    return null;
  }

  return JSON.parse(authDataSerialized);
}

export async function storeAuthData(data: AuthDataType): Promise<void> {
  return AsyncStorage.setItem(authDataStorageKey, JSON.stringify(data));
}
