import React, { useState } from 'react';
import { PrintSVG } from '../../../assets/svg';
import { useCartsMutations } from '../../api/hooks/vendor/carts/use-carts-mutations';
import { SVGButton } from '../forms/svg-button';
import { processPOSCommand } from '../../utils/print-module';
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import { tw } from '../../utils/tailwind';
import { twCb, useT } from '../../utils/helper';
import { useAlert } from '../../contexs/alert-context';
import { useTenant } from '../../contexs/tenant-context';

interface PrintCartReceiptButtonProps {
  receiptUrl: string;
  receiptOnlyUrl?: string;
  prepTicketOnlyUrl?: string;
  hospitalityReceiptOnlyUrl?: string;
}

export const PrintCartReceiptButton = (props: PrintCartReceiptButtonProps) => {
  // Hooks
  const { printReceipt } = useCartsMutations();
  const [isPrintOptionsVisible, setPrintOptionsVisible] = useState(false);
  const { tenant } = useTenant();
  const t = useT();
  const alert = useAlert();

  // Other constants
  const canPrintReceipt = props.receiptOnlyUrl && tenant.has_receipt_printer;
  const canPrintPrepTicket =
    props.prepTicketOnlyUrl && tenant.has_prep_ticket_printer;
  const longPressEnabled = canPrintReceipt && canPrintPrepTicket;

  const print = (url?: string) => {
    if (url) {
      printReceipt.mutateAsync(url).then(data => {
        if (data.print_jobs.length) {
          processPOSCommand(data.print_jobs);
        }

        if (data.detail) {
          alert.setModalOptions({ contentWrapperClassNames: 'w-[600px]' });
          alert.show(data.detail);
        }
      });
    }

    if (isPrintOptionsVisible) {
      setPrintOptionsVisible(false);
    }
  };

  return (
    <Menu
      opened={isPrintOptionsVisible}
      renderer={renderers.Popover}
      rendererProps={{ preferredPlacement: 'left' }}
      onBackdropPress={() => setPrintOptionsVisible(false)}>
      <MenuTrigger>
        <SVGButton
          svg={<PrintSVG />}
          onPress={() => print(props.receiptUrl)}
          onLongPress={() => longPressEnabled && setPrintOptionsVisible(true)}
          style={tw`${twCb(
            tenant?.has_receipt_printer || tenant?.has_prep_ticket_printer
              ? ''
              : 'opacity-20',
          )}`}
        />
      </MenuTrigger>

      <MenuOptions
        customStyles={{
          optionsContainer: tw.style('rounded-lg p-2 bg-white min-w-[200px]'),
          optionsWrapper: tw.style('gap-y-2 content-start'),
          optionWrapper: tw.style(
            'justify-center items-center text-center py-3 bg-gray-100',
          ),
        }}>
        {props.prepTicketOnlyUrl ? (
          <MenuOption
            onSelect={() => print(props.prepTicketOnlyUrl)}
            text={t('printPrepTicket').toString()}
          />
        ) : null}

        {props.receiptOnlyUrl ? (
          <MenuOption
            onSelect={() => print(props.receiptOnlyUrl)}
            text={t('printReceipt').toString()}
          />
        ) : null}

        {props.hospitalityReceiptOnlyUrl ? (
          <MenuOption
            onSelect={() => print(props.hospitalityReceiptOnlyUrl)}
            text={t('printHospitalityReceipt').toString()}
          />
        ) : null}
      </MenuOptions>
    </Menu>
  );
};
