import React from 'react';
import { TextProps, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { escapeRegExp } from '../../../utils/helper';

interface TextWithHighlightProps extends TextProps {
  highlight: string;
  children: string;
}

export const TextWithHighlight = (props: TextWithHighlightProps) => {
  const parts = props.children.split(
    new RegExp(`(${escapeRegExp(props.highlight)})`, 'gi'),
  );

  return (
    <Text>
      {parts.map((part, index) =>
        // Render matching parts in bold
        part.toLowerCase() === props.highlight.toLowerCase() ? (
          <Text key={index} style={[props.style, tw`font-bold`]}>
            {part}
          </Text>
        ) : (
          <Text key={index} style={[props.style]}>
            {part}
          </Text>
        ),
      )}
    </Text>
  );
};
