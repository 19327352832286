import { LineItem } from '../../api/types/cash-register/cart-types';
import {
  LineItemBundleSelection,
  LineItemIngredient,
} from '../../api/types/cash-register/line-item-types';
import {
  BundleOption,
  BundleOptionData,
  Ingredient,
  Product,
} from '../../api/types/cash-register/product-category-types';

export type IngredientWithQuantity = Ingredient & { quantity: number };
export type FormattedBundleOptionType = {
  selection_id: number;
  name: string;
  prices: BundleOptionData;
  bundle_id: number;
};

export const formatBundleOption = (
  bundle_id: number,
  bundleOptions: BundleOption[],
) => {
  return bundleOptions.map(bundleOption => ({
    selection_id: bundleOption[1],
    name: bundleOption[0],
    prices: bundleOption[2].data.prices,
    bundle_id,
  }));
};

export const getLineItemInitialValues = (
  product: Product,
  lineItem?: LineItem,
) => {
  if (!lineItem) {
    return {
      cart_id: null,
      product_id: product.id,
      product_size: product.sizes?.find(size => !size.disabled),
      included_ingredients: product.included_ingredients,
    };
  }

  const productSize = product.sizes?.find(
    sizeOption => sizeOption.size === lineItem.product_size,
  );

  const includedIngredients = product.included_ingredients?.filter(
    includedIngredient =>
      lineItem.line_item_included_ingredients.find(
        lineItemIncludedIngredient =>
          lineItemIncludedIngredient.ingredient_id === includedIngredient.id,
      ),
  );

  return {
    id: lineItem.id,
    cart_id: lineItem.cart_id,
    product_id: lineItem.product_id,
    quantity: lineItem.quantity,
    comment: lineItem.comment,
    product_size: productSize,
    included_ingredients: includedIngredients,
    line_item_bundle_selections_attributes:
      lineItem.line_item_bundle_selections,
    line_item_ingredients_attributes: lineItem.line_item_ingredients,
  };
};

export const buildLineItemBundleSelectionAttributes = (
  newValues: FormattedBundleOptionType[],
  previousValues?: LineItemBundleSelection[],
) => {
  let attributes: LineItemBundleSelection[] = [];
  let remainingPrevValues = [...(previousValues || [])];

  newValues.forEach(selection => {
    const previousValueId = remainingPrevValues?.find(
      previousSelection => previousSelection.bundle_id === selection.bundle_id,
    )?.id;

    if (previousValueId) {
      remainingPrevValues = remainingPrevValues.filter(
        previousSelection => previousSelection.id !== previousValueId,
      );
    }

    attributes.push({
      id: previousValueId,
      bundle_id: selection.bundle_id,
      selection_id: selection.selection_id,
      _destroy: false,
    });
  });

  // Set previous values that no longer selected to _destroy: true
  remainingPrevValues.forEach(previousValue => {
    attributes.push({
      id: previousValue.id,
      bundle_id: previousValue.bundle_id,
      selection_id: '',
      _destroy: true,
    });
  });

  return attributes;
};

export const buildLineItemIngredientAttributes = (
  newValues: IngredientWithQuantity[],
  previousValues?: LineItemIngredient[],
) => {
  let attributes: LineItemIngredient[] = [];
  let remainingPrevValues = [...(previousValues || [])];

  newValues.forEach(ingredient => {
    const ingredientId = (ingredient.ingredient_id as number) || ingredient.id;
    const previousValueId = remainingPrevValues?.find(
      previousIngredient => previousIngredient.ingredient_id === ingredientId,
    )?.id;

    if (previousValueId) {
      remainingPrevValues = remainingPrevValues.filter(
        previousIngredient => previousIngredient.id !== previousValueId,
      );
    }

    attributes.push({
      id: previousValueId,
      ingredient_id: ingredientId,
      quantity: ingredient.quantity,
      _destroy: false,
    });
  });

  // Set previous values that no longer selected to _destroy: true
  remainingPrevValues.forEach(previousValue => {
    attributes.push({
      id: previousValue.id,
      ingredient_id: previousValue.ingredient_id,
      quantity: 0,
      _destroy: true,
    });
  });

  return attributes;
};

export const validateBundleSelections = (
  product: Product,
  selections: LineItemBundleSelection[],
) => {
  const errors = [];
  // Iterate through each entry in the array
  for (let i = 0; i < product.bundles.length; i++) {
    const bundle = product.bundles[i];
    const selection = selections.find(s => s.bundle_id === bundle.id);
    const dependentBundle = bundle?.dependent_bundle_id
      ? selections.find(s => s.bundle_id === bundle?.dependent_bundle_id)
      : null;
    // Check if selection is required
    const selectionRequired =
      !bundle?.allow_blank &&
      (!bundle?.dependent_bundle_id ||
        (dependentBundle && dependentBundle.selection_id));
    // If the selection is required and no selection is made, return false
    if (selectionRequired && !selection?.selection_id) {
      errors.push({
        path: `line_item_bundle_selections_attributes[${bundle.id}]`,
        message: `Please select a ${bundle.title}`,
      });
    }
  }

  return errors; // Validation passes
};
