import React from 'react';

import { AlertContext } from '../contexs/alert-context';
import {
  ModalConfirmation,
  ModalConfirmationProp,
} from '../components/shared/modals/modal-confirmation';

interface AlertProviderProps {
  children: React.ReactNode;
}

export const AlertProvider = (props: AlertProviderProps) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [modalOptions, setModalOptions] = React.useState<
    Partial<ModalConfirmationProp>
  >({});
  const show = (message: string) => {
    setContent(message);
    setIsVisible(true);
  };

  const hide = () => {
    setContent('');
    setIsVisible(false);
  };

  return (
    <AlertContext.Provider value={{ isVisible, show, hide, setModalOptions }}>
      {props.children}

      {/* React native modal zIndex can't be manipulated, last to be rendered on the top */}
      {isVisible ? (
        <ModalConfirmation
          {...modalOptions}
          isVisible={isVisible}
          content={content}
          onConfirm={() => {
            modalOptions.onConfirm?.();
            hide();
          }}>
          {modalOptions.children ? modalOptions.children : null}
        </ModalConfirmation>
      ) : null}
    </AlertContext.Provider>
  );
};
