import React from 'react';
import {
  ScrollView,
  View,
  useWindowDimensions,
  Text,
  ViewStyle,
} from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { TouchableOpacity as Button } from '../../../components/forms';
import { useT } from '../../../utils/helper';

interface ScreenContainerProps {
  title: string;
  children?: React.ReactNode;
  scrollable?: boolean;
  containerStyle?: ViewStyle;
  headerWrapperStyle?: ViewStyle;
  headerButtons?: React.ReactNode;
  onBackPress?: () => void;
}

export const ScreenContainer = (props: ScreenContainerProps) => {
  // Standard Hooks
  const t = useT();
  const { width, height } = useWindowDimensions();
  const navigation =
    useNavigation<AppStackScreenProps<'CartShow'>['navigation']>();

  const paddingLg = width / 2 - 1272 / 2;
  // if not defined, default to true
  const Container = props.scrollable === false ? View : ScrollView;

  const goBack = () =>
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Root');

  return (
    <Container
      style={[
        tw`xl:pl-[${paddingLg}px] xl:pr-[${paddingLg}px] md:px-10
            xs:px-6 px-3 md:pt-10 pt-4 md:py-10 py-4 bg-white`,
        !props.scrollable && tw`max-h-[${height}px] min-h-full`,
        props.containerStyle,
      ]}
      contentContainerStyle={props.containerStyle}>
      {/* Header */}
      <View
        style={[
          tw`flex-row justify-between items-center mb-4`,
          props.headerWrapperStyle,
        ]}>
        <Text
          style={tw`md:text-[28px] text-xl leading-none font-bold text-gray-800`}>
          {props.title}
        </Text>
        {props.headerButtons ? (
          props.headerButtons
        ) : (
          <Button
            label={t('back')}
            onPress={() => (props.onBackPress ? props.onBackPress() : goBack())}
            extraStyle="md:w-[180px] bg-white border border-solid md:py-4 py-3
                      px-3 border-gray-800 shadow-sm rounded-lg w-[140px]"
            extraLabelStyle="text-gray-800 font-medium"
          />
        )}
      </View>

      {props.children ? props.children : null}
    </Container>
  );
};
