import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { NumericInputPadButton } from '../../../components/cash-register/shared/numeric-input-pad-button';
import { tw } from '../../../utils/tailwind';

const SPLIT_OPTIONS = [1, 2, 3, 4, 5];

interface InvoiceSplitPaymentButtonsProps {
  onClick: (spltBy: number) => void;
}

export const InvoiceSplitPaymentButtons = (
  props: InvoiceSplitPaymentButtonsProps,
) => {
  const [selectedSplitBy, setSelectedSplitBy] = useState<number>(1);

  useEffect(() => {
    props.onClick(selectedSplitBy);
  }, [selectedSplitBy]);

  return (
    <View style={tw`flex-row justify-center gap-2`}>
      {SPLIT_OPTIONS.map(splitBy => (
        <NumericInputPadButton
          key={splitBy}
          content={`÷${splitBy}`}
          style={[
            tw`min-h-[60px] min-w-[60px]`,
            selectedSplitBy === splitBy
              ? tw`bg-success border-white`
              : tw`bg-gray-50 border-gray-300`,
          ]}
          textStyle={[
            tw`text-xl`,
            selectedSplitBy === splitBy ? tw`text-white` : tw`text-gray-800`,
          ]}
          onPress={() => setSelectedSplitBy(splitBy)}
        />
      ))}
    </View>
  );
};
