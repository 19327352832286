import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { NativeModules, TouchableOpacity, Text } from 'react-native';
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import { tw } from '../../utils/tailwind';
import { CloseSVG, MenuSVG } from '../../../assets/svg';
import { useAuthMutations } from '../../api/hooks/auth/use-auth-mutations';
import { useCartsMutations } from '../../api/hooks/vendor/carts/use-carts-mutations';
import { useAuth } from '../../contexs/auth-context';
import { AppStackScreenProps } from '../../routes';
import { getHeaderBarColorTheme } from '../../utils/conditional-style';
import { HEADER_HEIGHT, navigateToAdmin, useT } from '../../utils/helper';
import { ModalConfirmation } from '../shared/modals/modal-confirmation';
import { ModalPrintReceipt } from '../modals/modal-print-receipt';
import { MenuOptionContent } from './menu-option-content';
import { Platform } from 'react-native';
import env from '../../env';
import { useTerminalSetting } from '../../contexs/terminal-setting-context';

enum ConfirmingState {
  NONE = 'none',
  COMPLETE_ALL = 'completeAll',
  LOGOUT = 'logout',
}

interface MenuHeaderBarProps {
  openingStateName: string;
}

export const MenuHeaderBar = (props: MenuHeaderBarProps) => {
  // Hooks
  const { performLogout } = useAuthMutations();
  const { setAllDone } = useCartsMutations();
  const { store_app_type } = useTerminalSetting();
  const { signOut } = useAuth();
  const navigation =
    useNavigation<AppStackScreenProps<'VendorDashboard'>['navigation']>();
  const t = useT();

  // States
  const [isModalPrintReceiptVisible, setModalPrintReceiptVisible] =
    useState(false);
  const [confirming, setConfirming] = useState<ConfirmingState>(
    ConfirmingState.NONE,
  );
  const [isOpen, setIsOpen] = useState(false);

  const { NotAnimatedContextMenu } = renderers;
  const { WifiModule } = NativeModules;

  const confirmingText = {
    [ConfirmingState.COMPLETE_ALL]: t('markAsAllCompleted?'),
    [ConfirmingState.LOGOUT]: t('areYouSureWantToLogOut'),
  };

  const onConfirm = async () => {
    switch (confirming) {
      case ConfirmingState.LOGOUT:
        performLogout.mutateAsync().then(signOut);
        break;
      case ConfirmingState.COMPLETE_ALL:
        setAllDone.mutate();
        break;
    }

    setConfirming(ConfirmingState.NONE);
  };

  return (
    <Menu
      renderer={NotAnimatedContextMenu}
      style={tw`gap-x-3 items-center`}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}>
      <ModalPrintReceipt
        isVisible={isModalPrintReceiptVisible}
        onModalHide={() => setModalPrintReceiptVisible(false)}
      />

      {/* There's a brief delay before modal hidden,
          add render condition so user can't see `none` content */}
      {confirming !== ConfirmingState.NONE ? (
        <ModalConfirmation
          isVisible={!!confirming}
          onModalHide={() => setConfirming(ConfirmingState.NONE)}
          content={confirmingText[confirming]}
          onConfirm={onConfirm}
        />
      ) : null}

      {/* Right Button */}
      <MenuTrigger
        style={tw`flex-row md:py-3 md:pl-6 md:pr-4 md:gap-x-2 p-3 justify-center items-center rounded-lg bg-white`}
        customStyles={{ TriggerTouchableComponent: TouchableOpacity }}>
        <Text style={tw`md:flex hidden text-xl leading-none`}>Menu</Text>
        {isOpen ? (
          <CloseSVG
            stroke={getHeaderBarColorTheme(props.openingStateName)}
            style={tw`w-5 h-5`}
          />
        ) : (
          <MenuSVG
            stroke={getHeaderBarColorTheme(props.openingStateName)}
            style={tw`w-5 h-5`}
          />
        )}
      </MenuTrigger>

      {/* Menu */}
      <MenuOptions
        customStyles={{
          optionsContainer: tw.style(
            `md:w-[376px] md:p-6 md:rounded-lg md:h-auto mt-[${HEADER_HEIGHT}px] p-4 bg-white w-full h-full`,
          ),
          optionsWrapper: tw.style('gap-y-3 content-start'),
          optionWrapper: tw.style(
            'justify-center items-center text-center py-5 px-2 w-full bg-gray-100 rounded-lg',
          ),
        }}>
        {env.APP_URL ? (
          <MenuOption onSelect={() => navigateToAdmin(store_app_type)}>
            <MenuOptionContent text={t('administration')} />
          </MenuOption>
        ) : null}
        <MenuOption onSelect={() => navigation.navigate('VendorDashboard')}>
          <MenuOptionContent text={t('CurrentOrders')} />
        </MenuOption>
        <MenuOption onSelect={() => navigation.navigate('Products')}>
          <MenuOptionContent text={t('Products')} />
        </MenuOption>
        <MenuOption onSelect={() => setModalPrintReceiptVisible(true)}>
          <MenuOptionContent text={t('PrintDaily')} />
        </MenuOption>
        <MenuOption onSelect={() => navigation.navigate('DeliveredCarts')}>
          <MenuOptionContent text={t('CompletedOrders')} />
        </MenuOption>
        <MenuOption
          onSelect={() => setConfirming(ConfirmingState.COMPLETE_ALL)}>
          <MenuOptionContent text={t('AllDone')} />
        </MenuOption>
        {Platform.OS !== 'web' ? (
          <MenuOption onSelect={() => WifiModule.openWifiSetting()}>
            <MenuOptionContent text={t('wifiSettings')} />
          </MenuOption>
        ) : null}
        <MenuOption
          onSelect={() => setConfirming(ConfirmingState.LOGOUT)}
          style={tw`md:mt-5 mt-1`}>
          <MenuOptionContent theme="danger" text={t('DeRegister')} />
        </MenuOption>
      </MenuOptions>
    </Menu>
  );
};
