import request from '../../../request';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  InvoicesParams,
  InvoicesResponse,
} from '../../../types/cash-register/invoice.types';

export const useInvoicesQueries = (params: InvoicesParams) => {
  const fetchInvoices = useInfiniteQuery(
    ['invoices', params],
    async ({ pageParam }) =>
      request({
        method: 'get',
        url: '/store/invoices.json',
        params: { ...params, page: pageParam },
      }) as Promise<InvoicesResponse>,
    {
      getNextPageParam: (lastPage, _pages) => {
        // Return the next page number if there are more pages
        return lastPage.next_page || false;
      },
    },
  );

  return { fetchInvoices };
};
