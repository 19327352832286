import React from 'react';
import SignInScreen from '../screens/signin/signin.screen';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const AuthStack = createNativeStackNavigator();

export const AuthStackNavigator = () => {
  return (
    <AuthStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={'SignIn'}>
      <AuthStack.Screen name={'SignIn'} component={SignInScreen} />
    </AuthStack.Navigator>
  );
};
