import React from 'react';
import QuerySuspense from '../../../api/query-suspense';
import { View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { formatCurrency, useT } from '../../../utils/helper';
import { LineItemList } from './line-item-list';
import { useCartQueries } from '../../../api/hooks/cash-register/carts/use-cart-queries';
import { useQueryClient } from '@tanstack/react-query';

interface CartContentProps {
  cartId: number | null;
}

export const CartContent = (props: CartContentProps) => {
  // Hooks
  const queryClient = useQueryClient();
  const { getCart } = useCartQueries(props.cartId);
  const storeAreaId = getCart.data?.store_area_id;
  const t = useT();
  // Callbacks
  const onLineItemUpdated = () => {
    // Refetch store tables
    if (storeAreaId) {
      queryClient.refetchQueries({
        queryKey: ['storeAreas', storeAreaId, 'storeTables'],
      });
    }

    // Refetch cart
    getCart.refetch();
  };

  return (
    <>
      <Text
        style={tw`lg:text-xl lg:leading-none md:flex hidden text-lg leading-none font-semibold text-gray-800`}>
        {t('newOrder')}
      </Text>
      <QuerySuspense
        query={getCart}
        hideRefetchLoading={true}
        onSuccess={cart => (
          <View
            style={tw`gap-y-6 p-4 pr-0 rounded-2xl border
            border-solid border-gray-400`}>
            <LineItemList
              cartType={cart.cart_type}
              lineItems={cart.line_items.filter(lineItem => !lineItem._destroy)}
              onLineItemUpdated={onLineItemUpdated}
            />

            <View style={tw`flex-row justify-between items-center pr-[18px]`}>
              <Text
                style={tw`text-lg leading-none font-semibold text-gray-800`}>
                {t('total')}
              </Text>
              <Text
                style={tw`text-lg leading-none font-semibold text-gray-800`}>
                {formatCurrency(cart.total)}
              </Text>
            </View>
          </View>
        )}
      />
    </>
  );
};
