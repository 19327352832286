import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAuth } from '../contexs/auth-context';
import { useT } from '../utils/helper';
import { CreateCashbookEntryParams } from '../api/types/cash-register/cashbook-entry.types';

const useCashbookEntryForm = (
  onSubmit: (params: CreateCashbookEntryParams) => void,
) => {
  const { currentCashier } = useAuth();
  const t = useT('forms');

  const cashbookFormSchema = yup.object().shape({
    cashier_id: yup.number().required(),
    entry_type: yup.string().required(),
    cashbook_entry_category_id: yup
      .number()
      .required(t('requiredField').toString()),
    gross_amount: yup.number().required(t('requiredField').toString()),
    reference_number: yup.string(),
    delivery_note_number: yup.string(),
    description: yup.string(),
  });

  type FormContents = yup.InferType<typeof cashbookFormSchema>;

  const form = useFormik({
    initialValues: {
      cashier_id: currentCashier?.id,
    } as FormContents,
    initialTouched: {},
    validationSchema: cashbookFormSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit,
  });

  return form;
};

export { useCashbookEntryForm };
