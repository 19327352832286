import React, { useState } from 'react';
import { ModalFullBase } from '../../modals/modal-full-base';
import { View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { twCb, useT } from '../../../utils/helper';
import { Cashier } from '../../../api/types/cash-register/cashier-types';
import { useCashierMutations } from '../../../api/hooks/cash-register/cashiers/use-cashier-mutations';
import { NumericInputPad } from '../shared/numeric-input-pad';
import { ScreenContainer } from '../shared/screen-container';

const PIN_CODE_LENGTH = 4;

interface ModalUnlockCashierProps {
  isVisible: boolean;
  onModalHide: () => void;
  onBackdropPress: () => void;
  cashier: Cashier;
  onUnlockSuccess: (cashier: Cashier) => void;
}

export const ModalUnlockCashier: React.FC<ModalUnlockCashierProps> = (
  props: ModalUnlockCashierProps,
) => {
  // Hooks
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [pin, setPin] = useState<string>('');
  const t = useT();
  const { cashier } = props;
  const { unlockCashier } = useCashierMutations(cashier.id);
  // Functions
  const setPinAndSubmit = (input: string) => {
    setPin(input);
    // Reset error message
    setErrorMessage(null);
    // Submit
    if (input.length === PIN_CODE_LENGTH) {
      unlockCashier
        .mutateAsync({ pin: input })
        .then(() => {
          props.onUnlockSuccess(cashier);
        })
        .catch(res => {
          setErrorMessage(res.data.message);
          setPin('');
        });
    }
  };

  return (
    <ModalFullBase
      isVisible={props.isVisible}
      onBackButtonPress={props.onModalHide}
      onBackdropPress={props.onBackdropPress}
      style="md:justify-start justify-start gap-x-10 m-0 lg:px-10 xs:px-6 px-3 md:py-10 py-3"
      type="simple">
      <ScreenContainer
        title=""
        scrollable={true}
        headerWrapperStyle={tw`max-w-[540px] self-center w-full`}
        onBackPress={props.onModalHide}>
        {/* Content Header */}
        <View style={tw`gap-y-1 justify-center xl:mb-16 md:mb-10 mb-4`}>
          <Text
            style={tw`xs:text-xl text-base xs:leading-none leading-none text-gray-600 text-center`}>
            {t('cashier')}
          </Text>
          <Text
            style={tw`xs:text-2xl text-xl xs:leading-[31px] leading-[18px] font-medium text-gray-800 text-center`}>
            {props.cashier.name}
          </Text>
        </View>
        {/* Content Body */}
        <View style={tw`xs:gap-y-10 gap-y-6`}>
          {/* PIN Code Display */}
          <View style={tw`xs:gap-y-7 gap-y-4 justify-center`}>
            <Text
              style={tw`text-center xs:text-[28px] text-lg leading-none font-bold text-gray-800`}>
              {t('enterPinCode')}
            </Text>
            <View style={tw`gap-y-3`}>
              <Text
                style={tw`text-lg leading-none font-medium text-error text-center`}>
                {errorMessage}
              </Text>
              <View style={tw`flex-row gap-x-3 justify-center`}>
                {/* PIN Code Display */}
                {Array(PIN_CODE_LENGTH)
                  .fill('')
                  .map((_, index) => (
                    <View
                      key={index}
                      style={tw`${twCb(
                        'w-6 h-6 rounded-full',
                        pin.length > index ? 'bg-gray-800' : 'bg-gray-300',
                      )}`}
                    />
                  ))}
              </View>
            </View>
          </View>
          {/* PIN Code Input */}
          <NumericInputPad onChange={setPinAndSubmit} numbers={pin} />
        </View>
      </ScreenContainer>
    </ModalFullBase>
  );
};
