import { useFormik } from 'formik';
import { useT } from '../utils/helper';
import * as yup from 'yup';
import { LineItemAttributes } from '../api/types/cash-register/line-item-types';
import {
  Ingredient,
  Product,
  ProductSize,
} from '../api/types/cash-register/product-category-types';
import {
  LineItemBundleSelection,
  LineItemIngredient,
} from '../api/types/cash-register/cart-types';
import {
  FormattedBundleOptionType,
  IngredientWithQuantity,
  buildLineItemBundleSelectionAttributes,
  buildLineItemIngredientAttributes,
  validateBundleSelections,
} from '../utils/cash-register/line-item-form.helper';

export interface LineItemFormInitialValuesType {
  id?: number;
  cart_id: number | null;
  product_id?: number;
  quantity?: number;
  comment?: string;
  product_size?: ProductSize;
  included_ingredients?: Ingredient[];
  line_item_bundle_selections_attributes?: LineItemBundleSelection[];
  line_item_ingredients_attributes?: LineItemIngredient[];
}

interface UseLineItemFormParams {
  product: Product;
  initialValues?: LineItemFormInitialValuesType;
  onSubmit: (values: LineItemAttributes) => void;
}

const useLineItemForm = (params: UseLineItemFormParams) => {
  const t = useT('forms');

  const productSizeSchema = yup.object().shape({
    size: yup.string(),
  });

  const includedIngredientsSchema = yup.object().shape({
    id: yup.number().required(),
    quantity: yup.number(),
  });

  const bundleSelectionsSchema = yup.object().shape({
    selection_id: yup.mixed().required(),
    bundle_id: yup.number().required(),
  });

  const addtionalIngredientSchema = yup.object().shape({
    id: yup.number().required(),
    quantity: yup.number().required(),
    ingredient_id: yup.number(),
  });

  const lineItemSchema = yup.object().shape({
    id: yup.number(),
    cart_id: yup.number().nullable().default(null),
    product_id: yup.number().required(t('requiredField').toString()),
    product_size: productSizeSchema,
    quantity: yup.number().min(1).required(t('requiredField').toString()),
    comment: yup.string(),
    included_ingredients: yup
      .array()
      .of(includedIngredientsSchema)
      .required()
      .default([]),
    line_item_bundle_selections_attributes: yup
      .array()
      .of(bundleSelectionsSchema)
      .required()
      .default([])
      .test(
        'validate-allow-blank',
        '', // Will be just a highlight on the field
        (value, context) => {
          const errors = validateBundleSelections(
            params.product,
            value as LineItemBundleSelection[],
          );

          // If errors exist, throw an error with detailed information
          if (errors.length > 0) {
            throw new yup.ValidationError(
              errors.map(error => context.createError(error)),
            );
          } else {
            return true;
          }
        },
      ),
    line_item_ingredients_attributes: yup
      .array()
      .of(addtionalIngredientSchema)
      .required()
      .default([]),
  });

  type FormContents = yup.InferType<typeof lineItemSchema>;

  const form = useFormik({
    initialValues: {
      id: params.initialValues?.id,
      cart_id: params.initialValues?.cart_id,
      product_id: params.initialValues?.product_id,
      product_size: params.initialValues?.product_size,
      quantity: params.initialValues?.quantity || 1,
      comment: params.initialValues?.comment || undefined,
      included_ingredients: params.initialValues?.included_ingredients || [],
      line_item_bundle_selections_attributes:
        params.initialValues?.line_item_bundle_selections_attributes || [],
      line_item_ingredients_attributes:
        params.initialValues?.line_item_ingredients_attributes || [],
    } as FormContents,
    initialTouched: {},
    validationSchema: lineItemSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: values => {
      // format form data to match api
      const formatedFormData = {
        id: values.id,
        cart_id: values.cart_id,
        product_id: values.product_id,
        product_size: values.product_size?.size,
        quantity: values.quantity,
        comment: values.comment,
        included_ingredient_ids: values.included_ingredients.length
          ? values.included_ingredients.map(i => i.id)
          : [''],
        line_item_bundle_selections_attributes:
          buildLineItemBundleSelectionAttributes(
            values.line_item_bundle_selections_attributes as FormattedBundleOptionType[],
            params.initialValues?.line_item_bundle_selections_attributes,
          ),
        line_item_ingredients_attributes: buildLineItemIngredientAttributes(
          values.line_item_ingredients_attributes as IngredientWithQuantity[],
          params.initialValues?.line_item_ingredients_attributes,
        ),
      };

      params.onSubmit(formatedFormData);
    },
  });

  return form;
};

export { useLineItemForm };
