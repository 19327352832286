import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AppStackParamList } from '../routes';
import { CashierSwitchScreen } from '../screens/cash-register/cashier/cashier-switch.screen';
import { useT } from '../utils/helper';

const Stack = createNativeStackNavigator<AppStackParamList>();

export const useCashierSwitchScreenOptions = () => {
  const t = useT('screens');

  return {
    headerBackVisible: false,
    headerMode: 'screen',
    headerShown: false,
    title: t('CashiersSwitch.pageTitle'),
  };
};

export const CashierSwitchStackNavigator = () => {
  const options = useCashierSwitchScreenOptions();

  return (
    <Stack.Navigator initialRouteName={'CashiersSwitch'}>
      {/* Each stack navigator require a root */}
      <Stack.Screen
        name={'Root'}
        component={CashierSwitchScreen}
        options={options}
      />

      <Stack.Screen
        name={'CashiersSwitch'}
        component={CashierSwitchScreen}
        options={options}
      />
    </Stack.Navigator>
  );
};
