import React from 'react';
import { View, Text, ViewStyle } from 'react-native';
import { tw } from '../../../utils/tailwind';

interface FormGroupProps {
  label: string;
  isError?: boolean;
  children: React.ReactNode;
  viewStyle?: ViewStyle;
}

export const FormGroup = (props: FormGroupProps) => {
  return (
    <View style={[tw`gap-y-1.5 col-span-2`, props.viewStyle]}>
      <Text
        style={[
          tw`text-base leading-none`,
          props.isError ? tw`text-error` : tw`text-gray-600`,
        ]}>
        {props.label}
      </Text>
      {props.children}
    </View>
  );
};
