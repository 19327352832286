import { useQuery } from '@tanstack/react-query';
import request from '../../../request';
import { StoreTablesResponse } from '../../../types/cash-register/store-table-types';

export const useStoreAreaTableQueries = (storeAreaId: number | undefined) => {
  const fetchStoreTables = useQuery(
    ['storeAreas', storeAreaId, 'storeTables'],
    async () =>
      request({
        method: 'get',
        url: '/store/store_tables.json',
        params: {
          q: {
            store_area_id_eq: storeAreaId,
          },
        },
      }) as Promise<StoreTablesResponse>,
    {
      initialData: [],
    },
  );

  return { fetchStoreTables };
};
