import { PrintJob } from '../api/types/vendor/carts-types';
import printJS from 'print-js';
import { EpsonPrinter } from './printer/epson-printer';

export const processPOSCommand = (printJobs: PrintJob[]) => {
  printJobs.forEach(printJob => {
    switch (printJob.printer_communication_type) {
      case 'jsbridge':
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'print',
            payload: printJob,
          }),
        );
        break;
      case 'epos':
        const printer = new EpsonPrinter(
          printJob.printer.network_url,
          printJob.printer.network_name,
        );

        if (printJob.bon_logo_url) {
          printer.addLogo(printJob.bon_logo_url);
        }

        printer.sendRawPosCommand(printJob.pos_command);
        printer.print(printJob.printer.number_of_bons);

        break;
      case 'pdf':
        printJS({
          printable: printJob.pos_command,
          type: 'pdf',
          base64: true,
        });
        break;
    }
  });
};
