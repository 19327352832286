import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { EditSvg } from '../../../../assets/svg';
import { tw } from '../../../utils/tailwind';
import { Customer } from '../../../api/types/cash-register/customer-types';
import { CustomerListItem } from '../../../components/cash-register/customer/customer-list-item';
import { useCustomerQueries } from '../../../api/hooks/cash-register/customers/use-customer-queries';
import { FlatListPagination } from '../../../components/flat-list-pagination';

interface CustomerListProps {
  query?: string;
  onEditCustomer: (customer: Customer) => void;
}

export const CustomerList = (props: CustomerListProps) => {
  // Queries
  const { fetchCustomers } = useCustomerQueries({
    q: {
      name_or_phone_or_street_or_street_number_or_zipcode_or_city_i_cont:
        props.query,
    },
  });

  return (
    <FlatListPagination
      query={fetchCustomers}
      refreshing={fetchCustomers.isRefetching}
      collectionName="customers"
      contentContainerStyle={tw`gap-y-1.5`}
      keyExtractor={(item, _index) => item.id.toString()}
      renderItem={({ item }) => (
        <View
          key={item.id}
          style={tw`flex-row gap-x-4 justify-between items-center border-b-[1px] border-gray-200 pb-1.5`}>
          {/* Customer details */}
          <CustomerListItem customer={item} highlight={props.query} />
          {/* Edit button */}
          <TouchableOpacity
            onPress={() => props.onEditCustomer(item)}
            style={tw`md:p-6 bg-gray-100 justify-center items-center p-4 rounded-lg shadow-sm`}>
            <EditSvg style={tw`w-7 h-7`} stroke={tw.color('gray-800')} />
          </TouchableOpacity>
        </View>
      )}
    />
  );
};
