import { useQuery } from '@tanstack/react-query';
import request from '../../../request';
import { StoreAreasResponse } from '../../../types/cash-register/store-area-types';

export const useStoreAreaQueries = () => {
  const fetchStoreAreas = useQuery(
    ['storeAreas'],
    async () =>
      request({
        method: 'get',
        url: '/store/store_areas.json',
      }) as Promise<StoreAreasResponse>,
    {
      // This means that the next time the query is run, it will refetch
      // the data if it's more than 15 minutes old.
      staleTime: 1000 * 60 * 15,
    },
  );

  return { fetchStoreAreas };
};
