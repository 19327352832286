import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useT } from '../../../utils/helper';
import { MinusSvg, PlusSvg } from '../../../../assets/svg';

type EntryType = 'deposit' | 'withdraw';

interface EntryTypeSwitchProps {
  defaultType: EntryType;
  onTypeChange: (type: EntryType) => void;
}

export const EntryTypeSwitch = (props: EntryTypeSwitchProps) => {
  const t = useT();
  const [entryType, setEntryType] = useState<EntryType>(props.defaultType);

  useEffect(() => {
    props.onTypeChange(entryType);
  }, [entryType]);

  const renderButton = (type: EntryType) => {
    const fontColor = type === entryType ? 'white' : 'gray-800';
    const buttonColor = type === entryType ? 'success' : 'gray-100';
    const Svg = type === 'deposit' ? PlusSvg : MinusSvg;

    return (
      <TouchableOpacity
        style={[Style.button, tw`bg-${buttonColor}`]}
        onPress={() => setEntryType(type)}>
        <Svg
          style={tw`h-4 w-4`}
          stroke={tw.color(fontColor)}
          strokeWidth={1.5}
        />
        <Text style={[Style.buttonText, tw`text-lg text-${fontColor}`]}>
          {t(type === 'withdraw' ? 'withdrawal' : type)}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <View style={tw`flex-row p-1 rounded-xl bg-gray-100`}>
      {renderButton('deposit')}
      {renderButton('withdraw')}
    </View>
  );
};

EntryTypeSwitch.defaultProps = {
  defaultType: 'deposit',
};

const Style = {
  button: tw`flex-row basis-1/2 items-center gap-x-2 justify-center place-items-center py-3 px-4 rounded-lg`,
  buttonText: tw`leading-none`,
};
