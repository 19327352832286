import React from 'react';
import { Text } from 'react-native';
import { tw } from '../../utils/tailwind';
import { twCb } from '../../utils/helper';

export type MenuOptionContentTheme = 'danger';

interface MenuOptionContentProps {
  text: string;
  theme?: MenuOptionContentTheme;
}

export const MenuOptionContent = (props: MenuOptionContentProps) => {
  return (
    <Text
      style={tw`${twCb(
        'text-lg leading-none font-medium',
        props.theme === 'danger' ? 'text-red-600' : 'text-gray-800',
      )}`}>
      {props.text}
    </Text>
  );
};
