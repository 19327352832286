import React, { useState } from 'react';
import { TouchableOpacity as Button } from '../../../components/forms';
import { tw } from '../../../utils/tailwind';
import { PlusSvg } from '../../../../assets/svg';
import { ModalAddCashbookEntry } from '../../../components/cash-register/modals/modal-add-cashbook-entry';
import { useT } from '../../../utils/helper';

enum ModalVisible {
  AddCashbook = 'addCashbook',
  None = 'none',
}

interface CashbookNewEntryButtonProps {
  responsive: boolean;
}

export const CashbookNewEntryButton = (props: CashbookNewEntryButtonProps) => {
  const t = useT('screens', { keyPrefix: 'Cashbook' });
  // States
  const [modalVisible, setModalVisible] = useState<ModalVisible>(
    ModalVisible.None,
  );

  const styles = props.responsive ? Styles.responsive : Styles.static;

  return (
    <>
      <Button
        label={t('newEntry').toString()}
        extraStyle={styles.button}
        extraLabelStyle={styles.label}
        onPress={() => setModalVisible(ModalVisible.AddCashbook)}>
        {props.responsive ? (
          <PlusSvg style={tw`sm:hidden h-4 w-4`} stroke={tw.color('white')} />
        ) : null}
      </Button>

      {/* Modal */}
      <ModalAddCashbookEntry
        isVisible={modalVisible === ModalVisible.AddCashbook}
        onModalHide={() => {
          setModalVisible(ModalVisible.None);
        }}
      />
    </>
  );
};

const Styles = {
  responsive: {
    button: 'sm:w-[180px] px-3 py-3',
    label: 'sm:flex hidden text-lg leading-none',
  },
  static: {
    button: 'w-[180px] px-3 py-3',
    label: 'text-lg leading-none',
  },
};
