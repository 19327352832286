import request from '../../../request';
import { CartsResponse } from '../../../types/vendor/carts-types';
import { useQuery } from '@tanstack/react-query';

export const useDeliveredCartsQueries = (date: string | null) => {
  const fetchDeliveredCarts = useQuery(
    ['deliveredCarts', date],
    async () =>
      request({
        method: 'get',
        url: '/store/vendor/carts.json',
        params: { delivered_carts: true, date },
      }) as Promise<CartsResponse>,
  );
  return { fetchDeliveredCarts };
};
