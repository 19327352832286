import request from '../../request';
import { AuthResponse, AuthRequestPayload } from '../../types/auth-types';
import { useMutation } from '@tanstack/react-query';

export const useAuthMutations = () => {
  const performLogin = useMutation(
    (data: AuthRequestPayload) =>
      request({
        method: 'post',
        url: '/store/auth.json',
        data,
      }) as Promise<AuthResponse>,
  );

  const performLogout = useMutation(
    async () =>
      request({
        method: 'delete',
        url: '/store/auth.json',
      }) as Promise<null>,
  );

  return {
    performLogin,
    performLogout,
  };
};
