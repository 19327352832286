import React, { PropsWithChildren } from 'react';
import { Switch, Text, View, TouchableOpacity } from 'react-native';
import { tw } from '../utils/tailwind';
import { useProductsMutations } from '../api/hooks/vendor/products/use-products-mutations';
import { Product } from '../api/types/vendor/products-types';
import { twCb } from '../utils/helper';

type ProductListItemProps = PropsWithChildren<{
  product: Product;
  onSoldOutToggle: () => void;
}>;

export const ProductListItem = (props: ProductListItemProps) => {
  const { setProductAvailability } = useProductsMutations();

  const changeAvailability = () => {
    // Optimistic update, prevent rerender.
    // rerender will uncollapse all the accordion items.
    // (uncollapse is the initial state)
    props.onSoldOutToggle();
    setProductAvailability
      .mutateAsync({
        url: props.product.path,
        isSoldOut: props.product.sold_out,
      })
      .catch(() => props.onSoldOutToggle()); // toggle back if error
  };

  return (
    <View style={tw`gap-y-2.5 items-start`}>
      <TouchableOpacity onPress={changeAvailability} style={tw`w-full`}>
        <View
          style={tw`${twCb(
            props.product.sold_out ? 'bg-gray-100' : 'bg-green-100',
            'flex-row justify-between items-center py-2.5 px-3 rounded-[10px] w-full',
          )}`}>
          <Text>{props.product.name}</Text>
          <Switch
            trackColor={{
              false: tw.color('green-600'),
              true: tw.color('gray-300'),
            }}
            thumbColor={tw.color('white')}
            value={props.product.sold_out}
            disabled={setProductAvailability.isLoading}
            onChange={changeAvailability}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};
