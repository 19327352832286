const plugin = require('tailwindcss/plugin');

module.exports = {
  theme: {
    screens: {
      xs: '450px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1440px',
    },
  },
  plugins: [
    plugin(function ({ addUtilities }) {
      addUtilities({
        '.bg-success': {
          backgroundColor: '#16A34A',
        },
        '.bg-error': {
          backgroundColor: '#DC2626',
        },
        '.bg-warning': {
          backgroundColor: '#DB5509',
        },
        '.text-success': {
          color: '#16A34A',
        },
        '.text-error': {
          color: '#DC2626',
        },
        '.text-caption': {
          color: 'rgb(152, 162, 179)',
        },
        '.border-error': {
          borderColor: '#DC2626',
        },
      });
    }),
  ],
};
