import { useEffect } from 'react';
import { useTerminalSettingQueries } from '../api/hooks/terminals/use-terminal-setting-queries';
import { NativeModules } from 'react-native';
import { decryptSignInToken } from '../utils/decrypt-sign-in-token';
import { useAuthMutations } from '../api/hooks/auth/use-auth-mutations';
import { AuthResponse } from '../api/types/auth-types';
import { useAuth } from '../contexs/auth-context';

export default function useTerminalSetting(serialNumber: string) {
  const { fetchTerminalSetting } = useTerminalSettingQueries(serialNumber);
  const { performLogin } = useAuthMutations();
  const { signIn } = useAuth();

  useEffect(() => {
    if (fetchTerminalSetting.data) {
      const { wake_after, sign_in_token, opened } = fetchTerminalSetting.data;
      const { ScreenModule } = NativeModules;

      if (wake_after && !!ScreenModule) {
        // Immediately wake up the screen if the wake_after is 0
        ScreenModule.wakeOrSleep(opened ? 0 : wake_after);
      }

      // if sign_in_token.token is null, it means the auto login is disabled
      if (sign_in_token && sign_in_token.token && sign_in_token.iv) {
        // decrypt the sign in token and perform login
        decryptSignInToken(sign_in_token.token, sign_in_token.iv).then(
          signInToken =>
            performLogin
              .mutateAsync({ sign_in_token: signInToken })
              .then((data: AuthResponse) => signIn(data)),
        );
      }
    }
  }, [fetchTerminalSetting.data]);
}
