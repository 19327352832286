import request from '../../../request';
import { CartsResponse } from '../../../types/vendor/carts-types';
import { useQuery } from '@tanstack/react-query';

export const useCartsQueries = () => {
  const fetchCarts = useQuery(
    ['carts'],
    async () =>
      request({
        method: 'get',
        url: '/store/vendor/carts.json',
      }) as Promise<CartsResponse>,
  );

  return { fetchCarts };
};
