import { useQuery } from '@tanstack/react-query';
import request from '../../../request';
import { CashbookEntryCategoriesResponse } from '../../../types/cash-register/cashbook-entry-category.types';

export const useCashbookEntryCategoriesQueries = () => {
  const fetchCashbookEntryCategories = useQuery(
    ['cashbookEntryCategories'],
    async () =>
      request({
        method: 'get',
        url: '/store/cashbook_entry_categories.json',
        params: {
          sort_by: 'position asc',
          limit: 20,
        },
      }) as Promise<CashbookEntryCategoriesResponse>,
    {
      // This means that the next time the query is run, it will refetch
      // the data if it's more than 15 minutes old.
      staleTime: 1000 * 60 * 15,
    },
  );

  return { fetchCashbookEntryCategories };
};
