import { T0TouchableOpacity as Button } from '../../../forms/overrides';
import React from 'react';

interface ButtonModalOptionProps {
  label: string;
  onPress: () => void;
  extraStyle?: string;
}

export const ButtonModalOption = (props: ButtonModalOptionProps) => {
  return (
    <Button
      label={props.label}
      onPress={props.onPress}
      theme="gray"
      extraStyle={props.extraStyle || ''}
      extraLabelStyle={'md:text-lg text-base normal-case'}
    />
  );
};
