import React, { useState } from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { formatCurrency, useT } from '../../../utils/helper';
import { tw } from '../../../utils/tailwind';
import { InvoiceInfoRow } from './invoice-info-row';
import { EditSvg } from '../../../../assets/svg';
import { useFiscalTransactionPaymentsQueries } from '../../../api/hooks/cash-register/fiscal-transaction-payments/use-fiscal-transaction-payments-queries';
import QuerySuspense from '../../../api/query-suspense';
import { ModalEditPayment } from '../../../components/cash-register/modals/modal-edit-payment';
import { Invoice } from '../../../api/types/cash-register/invoice.types';

enum ModalVisible {
  EditPayment = 'editPayment',
  None = 'none',
}
interface InvoicePaymentSummaryProps {
  lineItems: Invoice['line_items'];
  invoiceId: number;
  total: number;
  editable: boolean;
}

export const InvoicePaymentSummary = (props: InvoicePaymentSummaryProps) => {
  // Standard Hooks
  const t = useT();
  // Queries
  const { fetchFiscalTransactionPayments } =
    useFiscalTransactionPaymentsQueries(props.invoiceId);
  // States
  const [modalVisible, setModalVisible] = useState<ModalVisible>();

  return (
    <View style={tw`bg-gray-50 p-4 rounded-md gap-y-7`}>
      {/* Invoice Line Items Info */}
      {props.lineItems
        .filter(lineItem => lineItem.counted)
        .map(lineItem => (
          <InvoiceInfoRow
            key={lineItem.id}
            label={[lineItem.quantity, lineItem.name].join('x - ')}
            value={formatCurrency(lineItem.total_price)}
          />
        ))}
      {/* Total */}
      <InvoiceInfoRow label={t('total')} value={formatCurrency(props.total)} />

      <QuerySuspense
        query={fetchFiscalTransactionPayments}
        memo={modalVisible}
        onSuccess={data => (
          <>
            {data.fiscal_transaction_payments.length > 0 ? (
              <>
                {/* Payment List */}
                <View style={tw`gap-y-5`}>
                  {data.fiscal_transaction_payments.map((payment, index) => (
                    <InvoiceInfoRow
                      key={payment.id}
                      label={`${index + 1}. ${t(payment.payment_method)}`}
                      value={formatCurrency(payment.amount)}
                      size="sm"
                      theme="secondary"
                      style={payment.sync ? tw`opacity-50` : tw`opacity-100`}
                    />
                  ))}
                </View>

                {/* Display Edit button only if there is a payment */}
                {props.editable ? (
                  <TouchableOpacity
                    style={tw`self-end`}
                    onPress={() => setModalVisible(ModalVisible.EditPayment)}>
                    <View style={tw`flex-row gap-x-1`}>
                      <EditSvg
                        width={20}
                        height={20}
                        stroke={tw.color('sky-600')}
                      />
                      <Text style={tw`text-xl leading-none text-sky-600`}>
                        {t('edit')}
                      </Text>
                    </View>
                  </TouchableOpacity>
                ) : null}
              </>
            ) : null}
          </>
        )}
      />

      {/* Modals */}
      <ModalEditPayment
        invoiceId={props.invoiceId}
        isVisible={modalVisible === ModalVisible.EditPayment}
        onModalHide={() => setModalVisible(ModalVisible.None)}
      />
    </View>
  );
};
