import request from '../../../request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreateFiscalTransactionPaymentParams,
  CreateFiscalTransactionPaymentResponse,
  DestroyFiscalTransactionPaymentParams,
  DestroyFiscalTransactionPaymentResponse,
  FiscalTransactionPaymentsResponse,
} from '../../../types/cash-register/fiscal-transaction-payment.types';

export const useFiscalTransactionPaymentMutations = (invoiceId?: number) => {
  const queryClient = useQueryClient();

  const updateQueryData = (id: number) => {
    return queryClient.setQueryData(
      ['invoices', invoiceId, 'fiscalTransactionPayments'],
      (prevData: FiscalTransactionPaymentsResponse | undefined) => {
        if (!prevData) {
          return prevData;
        }

        Object.assign(prevData, {
          fiscal_transaction_payments: prevData.fiscal_transaction_payments.map(
            payment =>
              payment.id === id ? { ...payment, sync: true } : payment,
          ),
        });
      },
    );
  };

  const createFiscalPaymentTransaction = useMutation(
    async (params: CreateFiscalTransactionPaymentParams) =>
      request({
        method: 'post',
        url: `/store/invoices/${invoiceId}/fiscal_transaction_payments.json`,
        data: { fiscal_transaction_payment: params },
      }) as Promise<CreateFiscalTransactionPaymentResponse>,
  );

  const destroyFiscalPaymentTransaction = useMutation(
    async (params: DestroyFiscalTransactionPaymentParams) =>
      request({
        method: 'delete',
        url: `/store/invoices/${invoiceId}/fiscal_transaction_payments/${params.id}.json`,
        params,
      }) as Promise<DestroyFiscalTransactionPaymentResponse>,
    {
      onSuccess: (_data, variables) => {
        updateQueryData(variables.id);
      },
    },
  );

  return { createFiscalPaymentTransaction, destroyFiscalPaymentTransaction };
};
