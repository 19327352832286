import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { tw } from '../../../../utils/tailwind';
import { MinusSvg } from '../../../../../assets/svg';
import { twCb } from '../../../../utils/helper';

interface LineItemOptionButtonProps {
  active: boolean;
  disabled: boolean;
  canInputQuantity: boolean;
  label: string;
  isError?: boolean;
  onPress: (quantity: number) => void;
  initialQuantity?: number;
}

export const LineItemOptionButton = (props: LineItemOptionButtonProps) => {
  // Hooks
  const [quantity, setQuantity] = React.useState<number>(
    props.initialQuantity || 0,
  );
  // Callbacks
  const onButtonPress = (newQuantity: number) => {
    if (props.canInputQuantity) {
      setQuantity(newQuantity);
    }

    props.onPress(newQuantity);
  };

  return (
    <TouchableOpacity
      onPress={() => onButtonPress(quantity + 1)}
      disabled={props.disabled}
      style={tw`${twCb(
        'xl:basis-[24.3%] md:basis-[32.9%] basis-[48.7%] justify-center items-center px-3 rounded-lg border border-solid flex-row bg-success',
        props.active ? 'bg-success' : 'bg-gray-100',
        props.canInputQuantity ? 'py-2 min-h-[50px]' : 'py-4',
        props.disabled ? 'opacity-50' : '',
        props.isError ? 'border-error' : 'border-gray-300',
      )}`}>
      <Text
        style={tw`${twCb(
          'grow text-center text-xl leading-none',
          props.active
            ? 'text-white'
            : props.isError
            ? 'text-error'
            : 'text-gray-800',
        )}`}>
        {quantity > 0 ? `${quantity}x` : ''} {props.label}
      </Text>
      {props.canInputQuantity && quantity > 0 ? (
        <TouchableOpacity
          onPress={() => onButtonPress(quantity - 1)}
          style={tw`justify-center items-center p-1.5 rounded-full bg-[rgba(255,255,255,0.25)] z-10`}>
          <MinusSvg style={tw`w-5 h-5`} stroke={tw.color('white')} />
        </TouchableOpacity>
      ) : null}
    </TouchableOpacity>
  );
};
