import { useQuery } from '@tanstack/react-query';
import request from '../../../request';
import { FiscalTransactionPaymentsResponse } from '../../../types/cash-register/fiscal-transaction-payment.types';

export const useFiscalTransactionPaymentsQueries = (invoiceId: number) => {
  const fetchFiscalTransactionPayments = useQuery(
    ['invoices', invoiceId, 'fiscalTransactionPayments'],
    async () =>
      request({
        method: 'get',
        url: `/store/invoices/${invoiceId}/fiscal_transaction_payments.json`,
      }) as Promise<FiscalTransactionPaymentsResponse>,
    {
      staleTime: 1000 * 60 * 5,
    },
  );

  return { fetchFiscalTransactionPayments };
};
