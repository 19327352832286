export const normalizeStringNumber = (str: string) => {
  if (!str.length) {
    // If the string is empty, return 0
    return '0';
  } else if (str.startsWith('0') && str.length > 1 && !str.includes(',')) {
    // Remove leading zeros
    return str.replace(/^0+/, '');
  } else {
    return str;
  }
};

// The idea is to format 1000 to 1.000
// does not use the built-in toLocaleString
// since it need to be able to display 1000,
// this happen after user after press ',' but haven't input any decimal number
export const formatThousand = (str: string) => {
  const [int, dec] = str.split(',');
  const decimalSeparator = str.includes(',') ? ',' : '';

  const format = (num: number) => {
    return num.toLocaleString('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return format(parseFloat(int)) + decimalSeparator + (dec || '');
};

export const calculatorHandler = (inputType: string, currentVal: string) => {
  let newValue: string = '';

  if (inputType === 'C') {
    newValue = '0';
  } else if (inputType === 'backspace') {
    // Remove the last character from the givenCash
    newValue = currentVal.slice(0, -1);
  } else if (inputType === ',') {
    // Check if there's already a decimal point in the currentVal
    if (currentVal.includes(',')) {
      return currentVal;
    }
    // Append a decimal point to the currentVal
    newValue = currentVal + ',';
  } else {
    // inputType can be a number or a +10, +20, +50
    const val = inputType.startsWith('+') ? inputType.slice(1) : inputType;
    // Max 2 decimal points
    if (currentVal.includes(',') && currentVal.split(',')[1].length >= 2) {
      return currentVal;
    }

    if (currentVal === '0' && inputType === '0') {
      return currentVal;
    }

    newValue = currentVal + val;
  }

  return newValue;
};
