import request from '../../request';
import { useQuery } from '@tanstack/react-query';
import {
  TerminalSettingResponse,
  StoreAppTypeResponse,
} from '../../types/terminal-types';

export const useTerminalSettingQueries = (terminalSerialNumber: string) => {
  const fetchStoreAppType = useQuery(
    ['storeAppType', terminalSerialNumber],
    async () =>
      request({
        method: 'get',
        url: '/store/tenant_device_settings/store_app_type.json',
        params: { serial_number: terminalSerialNumber },
      }) as Promise<StoreAppTypeResponse>,
  );

  const fetchTerminalSetting = useQuery(
    ['terminalsSetting', terminalSerialNumber],
    async () =>
      request({
        method: 'get',
        url: '/api/v2/terminals/settings.json',
        params: { serial_number: terminalSerialNumber },
      }) as Promise<TerminalSettingResponse>,
  );

  return { fetchTerminalSetting, fetchStoreAppType };
};
