import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList, AppStackScreenProps } from '../../../routes';
import { CartForm } from './cart-form';
import { LineItemAttributes } from '../../../api/types/cash-register/line-item-types';
import { useLineItemMutations } from '../../../api/hooks/cash-register/line-items/use-line-item-mutations';
import { Product } from '../../../api/types/cash-register/product-category-types';
import { buildLineItemFromParams } from '../../../utils/cash-register/line-item.helper';
import { useCartQueries } from '../../../api/hooks/cash-register/carts/use-cart-queries';
import useCategoryPath from '../../../hooks/cash-register/use-category-path';

interface CartShowScreenProps {
  route: RouteProp<AppStackParamList, 'CartShow' | 'CartShowCategory'>;
  navigation: AppStackScreenProps<
    'CartShow' | 'CartShowCategory'
  >['navigation'];
}

export const CartShowScreen = (props: CartShowScreenProps) => {
  // Constants
  const cartId = props.route.params.cart_id;
  // Hooks
  const { createLineItem, updateLineItemQueryData } = useLineItemMutations();
  const { getCart } = useCartQueries(cartId);
  const { setCategoryPath } = useCategoryPath(cartId);
  // Callbacks
  const onFormSubmit = (attributes: LineItemAttributes, product: Product) => {
    // Optimistic update
    updateLineItemQueryData(buildLineItemFromParams(attributes, product));
    // Create line item
    createLineItem.mutateAsync(attributes).then(_data => {
      setCategoryPath(null); // reset category selection
    });
  };
  const cartType = getCart.data?.cart_type;

  return (
    <CartForm
      cartId={cartId}
      cartType={cartType}
      productCategoryId={props.route.params.product_category_id}
      onFormSubmit={onFormSubmit}
    />
  );
};
