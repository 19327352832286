import { createContext, useContext } from 'react';
import { Invoice } from '../api/types/cash-register/invoice.types';

export type InvoiceContextType = {
  invoice?: Invoice;
  setInvoice: (invoice: Invoice) => void;
};

export const InvoiceContext = createContext<InvoiceContextType>({
  setInvoice: () => {},
});

export const useInvoice = (): InvoiceContextType => {
  const context = useContext(InvoiceContext);

  if (!context) {
    throw new Error('useInvoice must be used within an InvoiceProvider');
  }

  return context;
};
