import { useQuery } from '@tanstack/react-query';
import request from '../../../request';
import { CashiersResponse } from '../../../types/cash-register/cashier-types';

export const useCashiersQueries = () => {
  const fetchCashiers = useQuery(
    ['cashiers'],
    async () =>
      request({
        method: 'get',
        url: '/store/cashiers.json',
      }) as Promise<CashiersResponse>,
    {
      // This means that the next time the query is run, it will refetch
      // the data if it's more than 15 minutes old.
      staleTime: 1000 * 60 * 15,
    },
  );

  return { fetchCashiers };
};
