import React from 'react';
import { View, ViewStyle } from 'react-native';
import { tw } from '../../../utils/tailwind';

interface FooterBarProps {
  style?: ViewStyle;
  children: React.ReactNode;
}

export const FooterBar = (props: FooterBarProps) => {
  return (
    <View style={[tw`flex-row flex-wrap gap-[1px]`, props.style]}>
      {props.children}
    </View>
  );
};
