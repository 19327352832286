import React from 'react';
import { TouchableOpacity } from 'react-native';
import { tw } from '../../../../utils/tailwind';
import { CustomerListItem } from '../../customer/customer-list-item';
import { Customer } from '../../../../api/types/cash-register/customer-types';

interface CustomerSearchInputOptionProps {
  option: Customer;
  highlight?: string;
  onSelected: (option: Customer) => void;
}

export const CustomerSearchInputOption = (
  props: CustomerSearchInputOptionProps,
) => {
  return (
    <TouchableOpacity
      id={`select-list-item-${props.option.id}`}
      onPress={() => props.onSelected(props.option)}
      style={tw`px-5 py-2 overflow-hidden border-b-[1px] border-gray-200`}>
      <CustomerListItem customer={props.option} highlight={props.highlight} />
    </TouchableOpacity>
  );
};
