import React, { useState } from 'react';
import { View } from 'react-native';
import { tw } from '../../utils/tailwind';
import { CheckSVG, ClockSVG, CloseSVG } from '../../../assets/svg';
import { useCartsMutations } from '../../api/hooks/vendor/carts/use-carts-mutations';
import { Actions } from '../../api/types/vendor/carts-types';
import { SVGButton } from '../forms/svg-button';
import { ModalConfirmation } from '../shared/modals/modal-confirmation';
import { useT } from '../../utils/helper';
import { PrintCartReceiptButton } from './print-cart-receipt-button';
import {
  ModalCartActionMode,
  ModalCartActionModes,
} from '../modals/modal-cart-action';

interface CartActionButtonProps {
  actions: Actions;
  onShowModalCartAction?: (action: ModalCartActionModes) => void;
}

export const CartActionButtons = (props: CartActionButtonProps) => {
  const { updateCart } = useCartsMutations();
  const [isModalConfirmationVisible, setModalConfirmationVisible] =
    useState(false);
  const t = useT();

  const performUpdateCart = (url: string) => {
    setModalConfirmationVisible(false);

    updateCart.mutate(url);
  };

  // Render cancel, set done, set processing time, set delay time,
  // and print receipt buttons
  const renderActionButtons = (
    onShowModalCartAction: (action: ModalCartActionModes) => void,
  ) => {
    return (
      <View style={tw`flex-row justify-between items-center p-3`}>
        <ModalConfirmation
          isVisible={isModalConfirmationVisible}
          onModalHide={() => setModalConfirmationVisible(false)}
          content={t('doYouWantToMarkThisAsCompleted').toString()}
          onConfirm={() => performUpdateCart(props.actions.set_done_path)}
        />

        {/* Cancell button */}
        {props.actions.cancellation_options ? (
          <SVGButton
            svg={<CloseSVG />}
            onPress={() =>
              onShowModalCartAction(ModalCartActionMode.CANCELLATION)
            }
          />
        ) : null}

        <View style={tw`flex-row gap-x-3`}>
          {/* Set done button */}
          {props.actions.set_done_path ? (
            <SVGButton
              svg={<CheckSVG />}
              isLoading={updateCart.isLoading}
              onPress={() => setModalConfirmationVisible(true)}
              theme="primary"
            />
          ) : null}

          {/* Set processing time button */}
          {props.actions.set_processing_time_options ? (
            <SVGButton
              svg={<ClockSVG stroke={tw.color('white')} />}
              onPress={() =>
                onShowModalCartAction(ModalCartActionMode.PROCESSING_TIME)
              }
              theme="danger"
            />
          ) : null}

          {/* Set delay time button */}
          {props.actions.set_delay_options ? (
            <SVGButton
              svg={<ClockSVG stroke={tw.color('gray-500')} />}
              onPress={() =>
                onShowModalCartAction(ModalCartActionMode.DELAY_TIME)
              }
            />
          ) : null}

          {/* Print receipt button */}
          {renderPrintCartReceiptButton()}
        </View>
      </View>
    );
  };

  const renderPrintCartReceiptButton = () => {
    return props.actions.print_receipt_path ? (
      <PrintCartReceiptButton
        receiptUrl={props.actions.print_receipt_path}
        receiptOnlyUrl={props.actions.print_receipt_only_path}
        prepTicketOnlyUrl={props.actions.print_prep_ticket_only_path}
        hospitalityReceiptOnlyUrl={
          props.actions.print_hospitality_receipt_only_path
        }
      />
    ) : null;
  };

  // If there's a show modal action callback, then render the action buttons
  if (props.onShowModalCartAction) {
    return renderActionButtons(props.onShowModalCartAction);
  }

  // otherwise, just render the print receipt button
  return (
    <View style={tw`flex-row-reverse justify-between items-center p-3 `}>
      {renderPrintCartReceiptButton()}
    </View>
  );
};
