import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { tw } from '../../../../utils/tailwind';
import { useT } from '../../../../utils/helper';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../../routes';
import { StoreTable } from '../../../../api/types/cash-register/store-table-types';

interface OrderSessionNavigationProps {
  storeTable: StoreTable;
}

export const OrderSessionNavigation = (props: OrderSessionNavigationProps) => {
  // Hooks
  const t = useT();
  const navigation = useNavigation<AppStackScreenProps<'Root'>['navigation']>();

  const handlePayButtonPress = () => {
    if (!props.storeTable.order_session) {
      return;
    }

    if (props.storeTable.order_session.pending_invoice?.id) {
      navigation.navigate('InvoiceShow', {
        id: props.storeTable.order_session.pending_invoice.id,
      });
    } else {
      navigation.navigate('OrderSessionPay', {
        id: props.storeTable.order_session.id,
      });
    }
  };

  const onNewOrderButtonPress = () => {
    if (props.storeTable.cart?.id) {
      navigation.navigate('CartShow', {
        cart_id: props.storeTable.cart?.id,
      });
    } else {
      navigation.navigate('CartNew', {
        store_table_id: props.storeTable.id,
        cart_type: 'table_order',
      });
    }
  };

  return (
    <View style={tw`gap-y-3`}>
      {/* Pay Button */}
      {props.storeTable.order_session ? (
        <TouchableOpacity
          onPress={() => handlePayButtonPress()}
          style={tw`justify-center items-center px-3 py-5 bg-success rounded-lg`}>
          <Text style={tw`text-xl leading-none font-medium text-white`}>
            {t('pay')}
          </Text>
        </TouchableOpacity>
      ) : null}
      {/* New Order Button */}
      <TouchableOpacity
        onPress={() => onNewOrderButtonPress()}
        style={tw`justify-center items-center px-3 py-5 bg-gray-800 rounded-lg`}>
        <Text style={tw`text-xl leading-none font-medium text-white`}>
          {t('newOrder')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
