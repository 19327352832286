import useTerminalSetting from '../hooks/use-terminal-setting';
import useTenantDeviceChannel from '../websocket/hooks/use-tenant-device-channel';

export type TenantDeviceSettingProps = {
  encryptedSerialNumber: string;
  serialNumber: string;
};

export default function TenantDeviceSetting(props: TenantDeviceSettingProps) {
  useTenantDeviceChannel(props.encryptedSerialNumber);
  useTerminalSetting(props.serialNumber);

  return null;
}
