import React from 'react';
import { T0TouchableOpacity as Button } from '../../../components/forms/overrides';
import { useCartsMutations } from '../../../api/hooks/vendor/carts/use-carts-mutations';
import { processPOSCommand } from '../../../utils/print-module';
import { useT } from '../../../utils/helper';

interface OrderSummaryActionButtonsProps {
  print_prep_ticket_path?: string;
}

export const OrderSummaryActionButtons = (
  props: OrderSummaryActionButtonsProps,
) => {
  const t = useT('cashRegister');
  const { printReceipt } = useCartsMutations();

  return props.print_prep_ticket_path ? (
    <Button
      onPress={() =>
        printReceipt
          .mutateAsync(props.print_prep_ticket_path as string) // This is can't be undefined since it's checked in the if statement
          .then(data => processPOSCommand(data.print_jobs))
      }
      label={t('printPrepTicket')}
      extraStyle="py-5 px-3"
    />
  ) : null;
};
