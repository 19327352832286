import React, { useCallback } from 'react';
import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { AppStackParamList } from '../../../routes';
import { useOrderSessionQueries } from '../../../api/hooks/cash-register/order-sessions/use-order-session-queries';
import QuerySuspense from '../../../api/query-suspense';
import { PaymentItemSelection } from './payment/payment-item-selection';
import { PaymentProvider } from '../../../providers/payment-provider';

interface OrderSessionPayScreenProps {
  route: RouteProp<AppStackParamList, 'OrderSessionPay'>;
}

export const OrderSessionPayScreen = (props: OrderSessionPayScreenProps) => {
  // Queries
  const id = props.route.params.id;
  const { fetchOrderSession } = useOrderSessionQueries({
    id,
  });

  useFocusEffect(
    useCallback(() => {
      // After successful payment, cashier will be redirected to here
      fetchOrderSession.refetch();
      return () => {};
    }, []),
  );

  return (
    <PaymentProvider>
      <QuerySuspense
        query={fetchOrderSession}
        onSuccess={orderSession => (
          <PaymentItemSelection orderSession={orderSession} />
        )}
      />
    </PaymentProvider>
  );
};
