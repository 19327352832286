import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as TSQueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';
import { useAuth } from '../contexs/auth-context';

const QueryClientProvider = ({ children }: { children: React.ReactNode }) => {
  const { signOut } = useAuth();

  const onQueryOrMutationError = (error: any) => {
    if ([401, 403].includes(error.status)) {
      signOut();
    }
  };

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: onQueryOrMutationError,
    }),
    mutationCache: new MutationCache({
      onError: onQueryOrMutationError,
    }),
  });

  return (
    <TSQueryClientProvider client={queryClient}>
      {children}
    </TSQueryClientProvider>
  );
};

export default QueryClientProvider;
