import React, { useState } from 'react';
import { tw } from '../../../utils/tailwind';
import { View, Text, TouchableOpacity, TextInput } from 'react-native';
import { CartCommentInput } from '../../../components/cash-register/cart/cart-comment-input';
import { ProductInput } from '../../../components/cash-register/cart/product-input';
import {
  ModalProductInput,
  ProductInputModalMode,
} from '../../../components/cash-register/modals/modal-product-input';
import { ProductCategoryInput } from '../../../components/cash-register/cart/product-category-input';
import { ModalLineItemForm } from '../../../components/cash-register/modals/modal-line-item-form';
import { Product } from '../../../api/types/cash-register/product-category-types';
import { CartContent } from '../../../components/cash-register/cart/cart-content-';
import { LineItemAttributes } from '../../../api/types/cash-register/line-item-types';
import { getLineItemInitialValues } from '../../../utils/cash-register/line-item-form.helper';
import { CartType } from '../../../api/types/cash-register/cart-types';
import { useProductFilter } from '../../../hooks/use-product-filter.hook';
import { CartFormActionBar } from './cart-form-action-bar';
import { useCartForm } from '../../../forms/use-cart-form.hook';
import { useT } from '../../../utils/helper';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import useCategoryPath from '../../../hooks/cash-register/use-category-path';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { useOrderMutations } from '../../../api/hooks/cash-register/orders/use-order-mutations';
import { useAuth } from '../../../contexs/auth-context';
import { useWindowSize } from '../../../hooks/use-window-size';

interface CartFormProps {
  cartType: CartType;
  cartId: number | null;
  productCategoryId: number | null; // default to null
  onFormSubmit: (params: LineItemAttributes, product: Product) => void;
}

export const CartForm = (props: CartFormProps) => {
  const t = useT();
  const { isLg } = useWindowSize();
  // Queries
  const { createOrder } = useOrderMutations();
  // Hooks
  const navigation = useNavigation<AppStackScreenProps<'Root'>['navigation']>();
  // Custom Hooks
  const [productInputModalMode, setProductInputModalMode] =
    useState<ProductInputModalMode>(ProductInputModalMode.HIDDEN);
  const [isModalLineItemFormVisible, setIsModalLineItemFormVisible] =
    useState<boolean>(false);
  const [categoryId, _setCategoryId] = useState<number | null>(
    props.productCategoryId,
  );
  const [product, setProduct] = useState<Product | null>(null);
  const [pluSearchQuery, setPluSearchQuery] = useState<string>('');
  const { filter } = useProductFilter(props.cartType);
  const { currentCashier } = useAuth();
  const filteredProducts = filter({
    productNumber: pluSearchQuery,
    categoryId: categoryId,
  });
  const form = useCartForm(() => {
    if (props.cartId && currentCashier) {
      createOrder
        .mutateAsync({
          cart_id: props.cartId,
          cashier_id: currentCashier.id,
          processing_time: form.values.processing_time,
        })
        .then(data => {
          navigation.navigate('StoreArea', {
            store_area_id: data.store_area_id,
          });
        });
    }
  });

  // Callbacks
  const onProductSelected = (selectedProduct: Product) => {
    setProduct(selectedProduct);
    setIsModalLineItemFormVisible(true);
  };
  const onFormSubmit = (
    params: LineItemAttributes,
    lineItemProduct: Product,
  ) => {
    props.onFormSubmit(params, lineItemProduct);
    setIsModalLineItemFormVisible(false);
    setProduct(null);
    setProductInputModalMode(ProductInputModalMode.HIDDEN);
  };

  const { setCategoryPath } = useCategoryPath(props.cartId);

  return (
    <>
      {/* Modal product input for small screen */}
      <ModalProductInput
        cartType={props.cartType}
        isVisible={productInputModalMode !== ProductInputModalMode.HIDDEN}
        mode={productInputModalMode}
        onModalHide={() =>
          setProductInputModalMode(ProductInputModalMode.HIDDEN)
        }
        onCategorySelect={selectedCategoryId =>
          setCategoryPath(selectedCategoryId)
        }
        onProductSelect={onProductSelected}
      />

      {product ? (
        <ModalLineItemForm
          isVisible={isModalLineItemFormVisible}
          onModalHide={() => setIsModalLineItemFormVisible(false)}
          product={product}
          initialValues={{
            ...getLineItemInitialValues(product),
            cart_id: props.cartId,
          }}
          onFormSubmit={onFormSubmit}
        />
      ) : null}

      <ScreenContainer
        title={t(isLg ? 'createNewOrder' : 'newOrder')}
        scrollable={true}
        onBackPress={
          categoryId
            ? () => setCategoryPath(null)
            : undefined /* use super / original onBackPress */
        }>
        {/* Order input */}
        <View
          style={tw`flex-row justify-center items-start content-start w-full`}>
          <View style={tw`xl:flex xl:pr-4 xl:basis-1/2 hidden gap-y-4`}>
            {/* <ProductCategoryInput /> */}
            {!categoryId && !pluSearchQuery ? (
              <ProductCategoryInput
                cartType={props.cartType}
                onSelect={selectedCategory =>
                  setCategoryPath(selectedCategory.id)
                }
              />
            ) : (
              <ProductInput
                products={filteredProducts}
                onSelect={onProductSelected}
              />
            )}
            <CartCommentInput />
          </View>
          <View style={tw`xl:basis-1/2 xl:pl-4 w-full`}>
            <View style={tw`gap-y-4`}>
              <CartContent cartId={props.cartId} />

              {/* Hidden on small screen */}
              <View style={tw`flex-row xl:hidden mb-3 gap-x-4`}>
                <TouchableOpacity
                  onPress={() =>
                    setProductInputModalMode(
                      ProductInputModalMode.CATEGORY_SELECT,
                    )
                  }
                  style={tw`basis-[49%] px-3 py-5 bg-success rounded-lg`}>
                  <Text
                    style={tw`text-xl leading-none text-center font-medium text-white`}>
                    {t('products')}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() =>
                    setProductInputModalMode(ProductInputModalMode.PLU_SEARCH)
                  }
                  style={tw`basis-[49%] px-3 py-5 bg-success rounded-lg`}>
                  <Text
                    style={tw`text-xl leading-none text-center font-medium text-white`}>
                    {t('pluSearch')}
                  </Text>
                </TouchableOpacity>
              </View>

              {/* PLU Search Input */}
              <TextInput
                onChangeText={setPluSearchQuery}
                value={pluSearchQuery}
                placeholder={t('pluSearch').toString()}
                multiline={false}
                placeholderTextColor={tw.color('gray-400')}
                style={tw`xl:flex hidden xl:py-3.5 py-3 px-4 text-xl font-medium leading-none text-gray-800 rounded-lg border-[1.5px] border-solid border-gray-400 shadow-sm`}
              />

              <CartCommentInput
                className="xl:hidden"
                onChange={form.handleChange('comment')}
              />
            </View>
          </View>
        </View>
      </ScreenContainer>

      <CartFormActionBar
        cartId={props.cartId}
        onChangeEstimation={estimation =>
          form.setValues({ processing_time: estimation })
        }
        onSubmit={() => form.submitForm()}
      />
    </>
  );
};

CartForm.defaultProps = {
  cartId: null,
  productCategoryId: null,
};
