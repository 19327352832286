import React from 'react';
import { View, Text } from 'react-native';
import { FooterBar } from '../../footer-bar/footer-bar';
import { FooterBarItem } from '../../footer-bar/footer-bar-item';
import { navigateToAdmin, useT } from '../../../../utils/helper';
import { tw } from '../../../../utils/tailwind';
import { useTerminalSetting } from '../../../../contexs/terminal-setting-context';
import { useTenant } from '../../../../contexs/tenant-context';

export const NoCashierView = () => {
  // Hooks
  const { store_app_type } = useTerminalSetting();
  const { adminTenantPath } = useTenant();
  const t = useT();

  return (
    <View style={tw`flex-1 content-center`}>
      <Text
        style={tw`text-[28px] leading-[130%] font-bold text-gray-800 m-auto max-w-[500px] text-center`}>
        {t('noCashier')}
      </Text>
      <FooterBar>
        <FooterBarItem
          text={t('goToAdmin')}
          active={false}
          onPress={() => {
            navigateToAdmin(store_app_type, adminTenantPath('cashiers'));
          }}
        />
      </FooterBar>
    </View>
  );
};
