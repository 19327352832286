import React from 'react';
import { FlatList, View } from 'react-native';
import { tw } from '../utils/tailwind';
import { MemoizedCart } from './cart';
import { Cart as CartInterface } from '../api/types/vendor/carts-types';
import { ModalCartActionModes } from './modals/modal-cart-action';

type ModalData = {
  mode: ModalCartActionModes;
  cart: CartInterface;
};

type CartListProps = {
  carts: CartInterface[];
  refreshing: boolean;
  classNames?: string;
  onRefresh: () => void;
  onShowModalCartAction?: (modalDaa: ModalData) => void;
};

export const CartList = (props: CartListProps) => {
  return (
    <FlatList
      onRefresh={props.onRefresh}
      refreshing={props.refreshing}
      data={props.carts}
      keyExtractor={({ id }) => id.toString()}
      renderItem={({ item }) => (
        <MemoizedCart
          cart={item}
          onShowModalCartAction={props.onShowModalCartAction}
        />
      )}
      removeClippedSubviews={true}
      initialNumToRender={3}
      ItemSeparatorComponent={() => <View style={tw`h-6`} />}
      contentContainerStyle={tw.style('pb-6')}
      maxToRenderPerBatch={3}
      style={tw`${props.classNames || ''}`}
    />
  );
};
