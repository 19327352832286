import React from 'react';
import {
  ActivityIndicator,
  ActivityIndicatorProps,
  View,
  ViewStyle,
} from 'react-native';
import { tw } from '../utils/tailwind';

interface LoadingAnimationProps extends ActivityIndicatorProps {
  contentWrapperStyle?: ViewStyle;
}

export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  contentWrapperStyle,
  ...props
}) => {
  return (
    <View
      style={[tw`flex-1 justify-center content-center`, contentWrapperStyle]}>
      <ActivityIndicator {...props} />
    </View>
  );
};
