export const calculateMinutesDifferenceFromNow = (time: string) => {
  const [hours, minutes] = time.split(':');

  // Create a new Date object for the current time
  const currentDate = new Date();

  // Set the hours and minutes based on user input
  const targetDate = new Date();
  targetDate.setHours(Number(hours), Number(minutes), 0); // Set seconds to 0 for precision

  // Calculate the time difference in milliseconds
  const timeDifferenceInMilliseconds =
    targetDate.getTime() - currentDate.getTime();

  // Convert milliseconds to minutes and return
  return timeDifferenceInMilliseconds / (1000 * 60);
};
