import { useEffect } from 'react';
import { ShortcutIcons } from '../api/types/tenants-types';

export const useShortcutIcons = (icons: ShortcutIcons | null) => {
  const { favicon_url, apple_icon_url, android_icon_url, ms_icon_url } =
    icons || {};

  useEffect(() => {
    if (favicon_url) {
      changeHref('link[rel="icon"]', favicon_url);
    }

    if (apple_icon_url) {
      changeHref('link[rel="apple-touch-icon"]', apple_icon_url);
    }

    if (android_icon_url) {
      changeHref('link[rel="shortcut icon"]', android_icon_url);
    }
    if (ms_icon_url) {
      changeMsIcon(ms_icon_url);
    }
  }, [favicon_url, apple_icon_url, android_icon_url, ms_icon_url]);
};

const changeHref = (selector: string, href: string) => {
  const element = document.querySelector(selector) as HTMLLinkElement;

  if (element) {
    element.href = href;
  }
};

const changeMsIcon = (url: string) => {
  const element = document.querySelector(
    "meta[name='msapplication-TileImage']",
  ) as HTMLMetaElement;

  if (element) {
    element.content = url;
  }
};
