import React from 'react';
import { Text, View } from 'react-native';
import { tw } from '../utils/tailwind';

type ErrorViewProps = {
  message: string;
  textStyle?: string;
};

export const ErrorView: React.FC<ErrorViewProps> = props => (
  <View style={tw`flex-1 justify-center content-center`}>
    <Text style={tw`text-center m-3 ${props.textStyle || ''}`}>
      {props.message}
    </Text>
  </View>
);
