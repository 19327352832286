import React from 'react';
import {
  TouchableWithoutFeedback,
  View,
  useWindowDimensions,
} from 'react-native';
import Modal from 'react-native-modal';
import { tw } from '../../utils/tailwind';
import { getModalBaseClasses } from '../../utils/conditional-style';
import { TouchableOpacity as Button } from '../forms';
import { useT } from '../../utils/helper';

interface ModalFullBaseProps {
  backdropOpacity?: number;
  backdropColor?: string;
  children: React.ReactNode;
  coverScreen?: boolean;
  isVisible: boolean;
  style?: string;
  type?: 'simple';
  footerContent?: React.ReactNode;
  onBackButtonPress: () => void;
  onBackdropPress: () => void;
  onModalHide?: () => void;
}

export const ModalFullBase = (props: ModalFullBaseProps) => {
  const t = useT();
  const windowDimensions = useWindowDimensions();
  const type = props.type || 'default';

  return (
    <Modal
      isVisible={props.isVisible}
      backdropColor={tw.style('bg-white').backgroundColor.toString()}
      backdropOpacity={1}
      onBackdropPress={props.onBackdropPress || props.onModalHide}
      onBackButtonPress={props.onBackButtonPress || props.onModalHide}
      hideModalContentWhileAnimating={true}
      backdropTransitionInTiming={1}
      backdropTransitionOutTiming={1}
      animationOutTiming={1}
      animationInTiming={1}
      deviceWidth={windowDimensions.width}
      deviceHeight={windowDimensions.height}
      style={tw`md:justify-center justify-start m-4 ${props.style || ''}`}>
      <TouchableWithoutFeedback touchSoundDisabled={true}>
        <>
          {/** Default modal */}
          {type === 'default' ? (
            <View style={tw`${getModalBaseClasses()} shadow-none p-0`}>
              {/* Close modal button */}
              <View style={tw`flex-row-reverse w-full`}>
                {props.onModalHide ? (
                  <Button
                    label={t('back')}
                    onPress={props.onModalHide}
                    theme="white"
                    extraStyle="w-full border-gray-800 mb-6"
                    extraLabelStyle="text-gray-800 font-medium"
                  />
                ) : null}
              </View>
              {props.children}
            </View>
          ) : null}

          {/** Simple modal */}
          {type === 'simple' && props.children}
          {/** Footer content */}
          {props.footerContent ? props.footerContent : null}
        </>
      </TouchableWithoutFeedback>
    </Modal>
  );
};
