import { useProductCategoryQueries } from '../api/hooks/cash-register/products/use-product-category-queries';
import { CartType } from '../api/types/cash-register/cart-types';

// Define the parameters for product filtering
interface ProductFilterParams {
  query?: string;
  categoryId?: number | null;
  productId?: number;
  productNumber?: string;
}

// Custom hook for product filtering based on specified parameters
export const useProductFilter = (cartType: CartType) => {
  const { fetchProductCategories } = useProductCategoryQueries(cartType);

  // Main filter function that applies all filtering criteria
  const filter = (params: ProductFilterParams) => {
    // Retrieve product data from the cache based on cart type
    const productCategories = fetchProductCategories.data;

    // Extract all products from the product categories data
    const products =
      productCategories?.flatMap(category => category.products) || [];

    // Apply filtering criteria conditionally based on filter parameters
    const filteredProducts = products.filter(product => {
      // if query is *, return all products
      if (params.query === '*') {
        return true;
      }

      // Check if product matches the query string (if provided)
      // Only support name-based search for now
      if (
        params.query &&
        !product.name.toLowerCase().startsWith(params.query.toLowerCase())
      ) {
        return false;
      }

      // Check if product matches the category ID (if provided)
      if (
        params.categoryId &&
        product.product_category_id !== params.categoryId
      ) {
        return false;
      }

      // Check if product matches the product ID (if provided)
      if (params.productId && product.id !== params.productId) {
        return false;
      }

      if (
        params.productNumber &&
        !(product.product_number || '').startsWith(params.productNumber)
      ) {
        return false;
      }
      // If product matches all filtering criteria, include it in the filtered list
      return true;
    });

    // Return the final filtered product list
    return filteredProducts;
  };

  // Expose the filter function for external use
  return { filter };
};
