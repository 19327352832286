import React, { useState } from 'react';
import { tw } from '../../../utils/tailwind';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { useT } from '../../../utils/helper';
import { useInvoiceMutations } from '../../../api/hooks/cash-register/invoices/use-invoice-mutation';
import { PRINTABLE_DOCUMENT } from '../../../api/types/printable-document.types';
import { processPOSCommand } from '../../../utils/print-module';
import { useAlert } from '../../../contexs/alert-context';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { usePayment } from '../../../contexs/payment-context';

interface InvoicePrintButtonsProps {
  invoiceId?: number;
}
export const InvoicePrintButtons = (props: InvoicePrintButtonsProps) => {
  // Standard Hooks
  const t = useT();
  const alert = useAlert();
  const payment = usePayment();
  const navigation =
    useNavigation<AppStackScreenProps<'InvoiceShowQr'>['navigation']>();
  // Queries
  const { printInvoice } = useInvoiceMutations();
  // States
  const [printing, setPrinting] = useState<PRINTABLE_DOCUMENT | null>(null);
  // Other Constants
  const shouldPrintButtonBeDisabled = !props.invoiceId || !!printing;

  // callbacks
  const handlePrint = (documentType: PRINTABLE_DOCUMENT) => {
    if (!props.invoiceId || shouldPrintButtonBeDisabled) {
      return;
    }

    setPrinting(documentType);

    printInvoice
      .mutateAsync({ id: props.invoiceId, document: documentType })
      .then(data => {
        if (data.print_jobs.length) {
          processPOSCommand(data.print_jobs);
          navigation.navigate('Root');
        } else if (data.detail) {
          alert.setModalOptions({ contentWrapperClassNames: 'w-[600px]' });
          alert.show(data.detail);
        }
      })
      .finally(() => {
        // add 1 second delay since we don't have a proper way to know
        // when exactly the print is done
        setTimeout(() => {
          setPrinting(null);
        }, 1000);
      });
  };

  const renderPrintButton = (
    text: string,
    documentType: PRINTABLE_DOCUMENT,
  ) => {
    const printingDoc = !!(printing === documentType);

    return (
      <FooterBarItem
        text={text}
        active={false}
        disabled={!props.invoiceId || printingDoc}
        loading={printingDoc}
        loadingColor={tw.color('black')}
        onPress={() => handlePrint(documentType)}
        buttonStyles={styles.button}
        textStyles={styles.buttonText}
      />
    );
  };

  const styles = {
    button: tw`md:basis-1/3 basis-1/1 bg-gray-100 border-0 border-b border-r border-solid border-gray-300`,
    buttonText: tw`text-2xl leading-none text-gray-800`,
  };

  if (payment.step !== 'PRINT') {
    return null;
  }

  return (
    <FooterBar style={tw`gap-0 flex-wrap`}>
      <FooterBarItem
        text={t('qrReceipt')}
        active={false}
        disabled={!props.invoiceId}
        onPress={() =>
          navigation.navigate('InvoiceShowQr', { id: props.invoiceId! })
        }
        buttonStyles={styles.button}
        textStyles={styles.buttonText}
      />
      {/* Print Hospitality Receipt Button */}
      {renderPrintButton(t('printHospitalityReceipt'), 'HOSPITALITY_RECEIPT')}
      {/* Print Receipt Button */}
      {renderPrintButton(t('printReceipt'), 'RECEIPT')}
    </FooterBar>
  );
};
