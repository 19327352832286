import request from '../../../request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SetProductAvailabilityParams } from '../../../types/vendor/products-types';

export const useProductsMutations = () => {
  const queryClient = useQueryClient();

  const setAllProductsAvailabile = useMutation(
    async () =>
      request({
        method: 'put',
        url: '/store/vendor/products/set_all_available.json',
      }) as Promise<null>,
    { onSuccess: () => queryClient.invalidateQueries(['products']) },
  );

  const setProductAvailability = useMutation(
    async ({ url, isSoldOut }: SetProductAvailabilityParams) =>
      request({
        method: 'put',
        url,
        params: { sold_out: isSoldOut },
      }) as Promise<null>,
  );

  return { setAllProductsAvailabile, setProductAvailability };
};
