import { OpeningState } from '../api/types/tenants-types';

export const getOpeningState = (openingState: OpeningState) => {
  const {
    delivery_paused,
    pickup_paused,
    opened_for_delivery,
    opened_for_pickup,
    disconnected,
  } = openingState;

  if (disconnected) {
    return 'disconnected';
  }

  if (delivery_paused && pickup_paused) {
    return 'paused';
  } else if (delivery_paused) {
    return 'delivery_paused';
  } else if (pickup_paused) {
    return 'pickup_paused';
  } else if (opened_for_delivery || opened_for_pickup) {
    return 'open';
  } else {
    return 'closed';
  }
};
