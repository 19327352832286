import request from '../../../request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  OrderCreateParams,
  OrderCreateResponse,
} from '../../../types/cash-register/order-types';

export const useOrderMutations = () => {
  const queryClient = useQueryClient();

  const createOrder = useMutation(
    async (params: OrderCreateParams) =>
      request({
        method: 'post',
        url: '/store/orders.json',
        data: { order: params },
      }) as Promise<OrderCreateResponse>,
    {
      onSuccess: data => {
        onOrderMutationSuccess(data.order_session_id);
      },
    },
  );

  // Callbacks
  const onOrderMutationSuccess = (order_session_id: number) => {
    queryClient.invalidateQueries(['storeAreas']);
    queryClient.invalidateQueries(['orderSessions', order_session_id]);
  };

  return { createOrder, onOrderMutationSuccess };
};
