import { createContext, useContext } from 'react';
import { Cashier } from '../api/types/cash-register/cashier-types';

export type AuthContextStatusType = 'IDLE' | 'NOT_SIGNED_IN' | 'SIGNED_IN';
export type AuthDataType = {
  token: string;
};
type AuthContextType = {
  status: AuthContextStatusType;
  authData?: AuthDataType | null;
  currentCashier: Cashier | null;
  setCurrentCashier(cashier: Cashier | null): void;
  signIn(data: AuthDataType): void;
  signOut(): void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

function useAuth(): AuthContextType {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthContext, useAuth };
