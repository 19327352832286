// Request wrapper
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import env from '../env';
import { fetchAuthData } from './services/auth-data';
import { AuthDataType } from '../contexs/auth-context';
import { Platform } from 'react-native';

async function request(
  options: AxiosRequestConfig,
  returnAxiosResponse?: boolean,
) {
  const requestOptions = options;
  // if no method in options - then try 'get' by default
  if (options.method === undefined) {
    requestOptions.method = 'get';
  }

  const onSuccess = (response: AxiosResponse) =>
    returnAxiosResponse ? response : response.data;

  const onError = (error: AxiosError) => {
    if (error.response) {
      if (error.response && error.response.status.toString().startsWith('4')) {
        // TODO: HANDLE CLIENT SIDE ERROR
        // @Radiet here you can handle the cases for 401 and 403 and logout the user
        if (
          Platform.OS === 'web' &&
          env.ENABLE_SINGLE_SIGN_ON &&
          [401, 403].includes(error.response.status)
        ) {
          // redirect to login page
          window.location.href = env.APP_URL;
        }
      } else if (
        error.response &&
        error.response.status.toString().startsWith('5')
      ) {
        // TODO: HANDLE SERVER SIDE ERROR
      } else {
        // TODO: HANDLE OTHER ERROR
      }
    } else {
      // Something else happened while setting up the request
    }
    return Promise.reject(error.response || error.message);
  };

  const client = axios.create({
    withCredentials: env.ENABLE_SINGLE_SIGN_ON,
    baseURL: `${env.API_URL}${
      requestOptions.url?.startsWith('/api') ? '' : '/api/v1'
    }`,
    headers: {
      'Content-type': 'application/json',
    },
    params: requestOptions.params,
    validateStatus: status => {
      const isValidStatus = status >= 200 && status < 300; // Default

      return isValidStatus || status === 304; // Treat 304 (Not Modified) as a success
    },
  });

  client.interceptors.request.use(async req => {
    // if request is for sign in - then return it without token
    if (req.url === '/store/auth' && req.method === 'post') {
      return req;
    }

    req.headers.Authorization = `Bearer ${await fetchAuthData().then(
      (data: AuthDataType | null) => data?.token,
    )}`;

    return req;
  });

  return client(requestOptions).then(onSuccess).catch(onError);
}

export default request;
