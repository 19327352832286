import React, { useMemo } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Keyboard,
} from 'react-native';
import { tw } from '../../utils/tailwind';

import { SelectListProps } from './select-list-props';
import { twCb } from '../../utils/helper';
import { SelectListOption } from './select-list-option';
import QuerySuspense from '../../api/query-suspense';

export type L1Keys = { key?: any; value?: any; disabled?: boolean | undefined };

export const SelectList = <T extends any>(props: SelectListProps<T>) => {
  const {
    setSelected,
    placeholder,
    boxStyles,
    inputStyles,
    dropdownStyles,
    dropdownItemStyles,
    dropdownTextStyles,
    maxHeight,
    search = true,
    searchPlaceholder = 'Search',
    notFoundText = 'No data found',
    disabledItemStyles,
    disabledTextStyles,
    onSelect = () => {},
    dropdownShown = false,
    fontFamily,
    label,
  } = props;

  const formatData = (data: any[]) => {
    const labelKey = props.labelKey ?? 'value';
    const formattedData = data.map(datum => {
      return {
        ...datum,
        key: datum[props.valueKey ?? 'key'],
        value: datum[labelKey],
      };
    });

    return props.optionSelectAlloption
      ? [
          { key: undefined, value: props.optionSelectAlloption },
          ...formattedData,
        ]
      : formattedData;
  };
  const data = useMemo(() => formatData(props.data), [props.data]);

  const defaultOption = data.find(datum => datum.key === props.defaultOption);
  const inputRef = React.useRef<TextInput>(null);
  const oldOption = React.useRef(null);
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [dropdown, setDropdown] = React.useState<boolean>(dropdownShown);
  const [selectedval, setSelectedVal] = React.useState<any>('');
  const [height, setHeight] = React.useState<number>(200);
  const [filteredData, setFilteredData] = React.useState(data);

  const slidedown = () => {
    setDropdown(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };
  const slideup = () => {
    setDropdown(false);
  };
  const onBlur = (e: any) => {
    if (e.relatedTarget && e.relatedTarget.id.includes('select-list-item')) {
      return;
    }

    slideup();
  };

  React.useEffect(() => {
    if (maxHeight) {
      setHeight(maxHeight);
    }
  }, [maxHeight]);

  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  React.useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    onSelect();
  }, [selectedval]);

  React.useEffect(() => {
    if (
      !firstRender &&
      defaultOption &&
      oldOption.current !== defaultOption.key
    ) {
      // oldOption.current != null
      oldOption.current = defaultOption.key;
      setSelected(defaultOption);
      setSelectedVal(defaultOption.value);
    }
    if (defaultOption && firstRender && defaultOption.key !== undefined) {
      oldOption.current = defaultOption.key;
      setSelected(defaultOption);
      setSelectedVal(defaultOption.value);
    }
  }, [defaultOption]);

  const onChangeQuery = (query: string) => {
    if (props.onChangeQuery) {
      props.onChangeQuery(query);
    } else {
      const result = data.filter((item: L1Keys) => {
        const row = item.value.toLowerCase();

        return row.search(query.toLowerCase()) > -1;
      });
      setFilteredData(result);
    }
  };

  // We add any here, because it can be an api response
  const renderOptions = (options: L1Keys[] | any) => {
    return options.length >= 1 ? (
      options.map(renderOption)
    ) : (
      <TouchableOpacity
        style={[styles.option, dropdownItemStyles]}
        onPress={() => {
          setSelected(undefined);
          setSelectedVal('');
          slideup();
          setTimeout(() => setFilteredData(data), 800);
        }}>
        <Text style={[{ fontFamily }, dropdownTextStyles, tw`text-lg`]}>
          {notFoundText}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderOption = (item: L1Keys, index: number) => {
    const value = item.value ?? item;
    const disabled = item.disabled ?? false;
    const onItemSelected = () => {
      setSelected(item);
      setSelectedVal(value);
      slideup();
      setTimeout(() => {
        setFilteredData(data);
      }, 800);
    };

    if (disabled) {
      return (
        <TouchableOpacity
          style={[styles.disabledoption, disabledItemStyles]}
          key={index}
          onPress={() => {}}>
          <Text
            style={[
              { fontFamily },
              disabledTextStyles,
              tw`text-lg text-gray-400`,
            ]}>
            {value}
          </Text>
        </TouchableOpacity>
      );
    } else {
      return props.optionRenderer ? (
        props.optionRenderer(item, onItemSelected)
      ) : (
        <SelectListOption
          key={index}
          option={item}
          style={[styles.option, dropdownItemStyles]}
          onPress={onItemSelected}
        />
      );
    }
  };

  const rightIconSection = () => {
    return props.rightIcon ? (
      <View style={tw`absolute top-[20px] right-[15px]`}>
        {props.rightIcon}
      </View>
    ) : null;
  };

  return (
    <View style={tw`z-5`}>
      {label ? (
        <Text
          style={tw`md:text-lg md:leading-none text-base leading-none text-gray-600 mb-1`}>
          {label}
        </Text>
      ) : null}
      {dropdown && search ? (
        <View style={[styles.wrapper, boxStyles]}>
          <View style={tw`flex flex-row items-center flex-1`}>
            <TextInput
              ref={inputRef}
              placeholder={searchPlaceholder}
              onBlur={onBlur}
              onChangeText={onChangeQuery}
              style={[{ fontFamily }, styles.input, inputStyles]}
            />
            {rightIconSection()}
          </View>
        </View>
      ) : (
        <TouchableOpacity
          style={[
            styles.wrapper,
            boxStyles,
            props.errorMessage ? tw`border-error` : tw`border-gray-400`,
          ]}
          onPress={() => {
            if (!dropdown) {
              Keyboard.dismiss();
              slidedown();
            } else {
              slideup();
            }
          }}>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={[
              { fontFamily },
              inputStyles,
              tw`${twCb(
                'px-4 py-[13px] text-lg',
                selectedval ? '' : 'text-gray-500',
              )}`,
            ]}>
            {selectedval === '' ? placeholder || 'Select option' : selectedval}
          </Text>
          {rightIconSection()}
        </TouchableOpacity>
      )}

      {dropdown ? (
        <View
          style={[tw`max-h-[${height}px]`, styles.dropdown, dropdownStyles]}>
          <ScrollView
            contentContainerStyle={tw`py-2.5 overflow-hidden`}
            nestedScrollEnabled={true}>
            {props.query ? (
              <QuerySuspense
                query={props.query}
                flattenPages="customers"
                onSuccess={
                  results => renderOptions(formatData(results as any[])) // cast to any[] because it can be any api response, but it should be an array
                }
              />
            ) : (
              renderOptions(filteredData)
            )}
          </ScrollView>
        </View>
      ) : null}
      <Text style={tw`pl-1 mt-2 text-error`}>{props.errorMessage}</Text>
    </View>
  );
};

// Style used multiple times in the component
const styles = {
  wrapper: tw`border border-gray-400 rounded-lg flex-row justify-between`,
  dropdown: tw`border border-gray-400 rounded-lg mt-2.5 overflow-hidden absolute top-[75px] w-full bg-white`,
  option: tw`px-5 py-2 overflow-hidden`,
  disabledoption: tw`px-5 py-2 flex-row items-center bg-gray-400 opacity-90`,
  input: tw`pl-4 pr-10 py-3.5 w-full rounded-lg placeholder-gray-500 text-lg`,
};
