import React from 'react';
import { TouchableOpacity, TouchableOpacityProps, View } from 'react-native';
import { tw } from '../../utils/tailwind';
import { LoadingAnimation } from '../loading-animation';
import { twCb } from '../../utils/helper';

interface SVGButtonProps extends TouchableOpacityProps {
  svg: React.ReactNode;
  theme?: string;
  isLoading?: boolean;
}

export const SVGButton = ({ svg, theme, ...props }: SVGButtonProps) => {
  let buttonStyle = '';

  switch (theme) {
    case 'primary':
      buttonStyle = 'bg-green-500 border-green-500';
      break;
    case 'danger':
      buttonStyle = 'bg-pink-600 border-pink-600';
      break;
    default:
      buttonStyle = 'border-gray-500';
      break;
  }

  return (
    <TouchableOpacity
      {...props}
      disabled={props.isLoading}
      style={[
        tw`${twCb(
          'justify-center items-center p-[11px] rounded-full border-[1px] border-solid',
          buttonStyle,
        )}`,
        props.style,
      ]}>
      <View style={tw`w-8 h-8`}>
        {props.isLoading ? <LoadingAnimation /> : svg}
      </View>
    </TouchableOpacity>
  );
};
