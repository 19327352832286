import { CommonActions, useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';

export const useNavigationHelper = () => {
  const navigation = useNavigation();

  const removeLastRoute = () => {
    navigation.dispatch(state => {
      // Remove the last route from the stack
      const routes = state.routes.slice(0, -1);

      // Removing last routes will trigger popstate event
      // Since this is not a real popstate event, we need to set a flag
      if (Platform.OS === 'web') {
        window.removingRoute = true;
      }

      // Return the new navigation state
      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1,
      });
    });
  };

  return {
    removeLastRoute,
  };
};
