import React from 'react';
import { tw } from '../../../utils/tailwind';
import { View, Text } from 'react-native';
import {
  STANDARD_DATE_TIME_FORMAT,
  formatCurrency,
  useT,
} from '../../../utils/helper';
import { Order } from '../../../api/types/cash-register/order-types';
import { format } from 'date-fns';
import { Invoice } from '../../../api/types/cash-register/invoice.types';
import { transactionDocumentHelper } from '../../../utils/helpers/transaction-document.helper';
import { SummaryCardItemGroup } from './summary-card-item-group';

interface TransactionSummaryCardProps {
  document: Order | Invoice;
  actionButton: () => React.ReactNode;
}

export const TransactionSummaryCard = (props: TransactionSummaryCardProps) => {
  const t = useT();
  const { transactionDocument } = transactionDocumentHelper(props.document);

  return (
    <View style={tw`gap-y-[18px] content-start p-6 bg-gray-50 rounded-2xl`}>
      <View style={tw`gap-y-[18px] content-start`}>
        <View style={tw`gap-y-2`}>
          <View style={tw`flex-row justify-between items-center`}>
            <Text
              style={[
                tw`text-xl leading-none font-semibold`,
                transactionDocument.type === 'cancellation'
                  ? tw`text-error`
                  : tw`text-success`,
              ]}>
              {transactionDocument.name}
            </Text>

            {transactionDocument.store_table ? (
              <Text
                style={tw`text-xl leading-none font-semibold text-gray-800`}>
                {transactionDocument.store_table.name}
              </Text>
            ) : null}
          </View>
          <Text style={tw`text-base leading-none font-medium text-gray-400`}>
            {format(transactionDocument.date, STANDARD_DATE_TIME_FORMAT)}
          </Text>
        </View>
        <View style={tw`gap-y-5`}>
          {/* Order items */}
          <SummaryCardItemGroup
            status={'active'}
            lineItems={transactionDocument.line_items}
          />
          <SummaryCardItemGroup
            headline={t('cancellation').toString()}
            status={'removed'}
            lineItems={transactionDocument.line_items}
          />
          <SummaryCardItemGroup
            headline={t('returned').toString()}
            status={'returned'}
            lineItems={transactionDocument.line_items}
          />
        </View>

        <View style={tw`flex-row justify-between items-center`}>
          <Text style={tw`text-xl leading-none font-semibold text-gray-800`}>
            {t('total')}
          </Text>
          <Text style={tw`text-xl leading-none font-semibold text-gray-800`}>
            {formatCurrency(transactionDocument.total)}
          </Text>
        </View>
      </View>

      {props.actionButton ? props.actionButton() : null}
    </View>
  );
};
