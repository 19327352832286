import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

export const useWindowSize = () => {
  const { width } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState<string>(getWindowSize(width));

  useEffect(() => {
    setWindowSize(getWindowSize(width));
  }, [width]);

  return {
    windowSize,
    width,
    isLg: ['lg', 'xl'].includes(windowSize),
  };
};

const getWindowSize = (width: number) => {
  if (width < 450) {
    return 'xs';
  } else if (width < 640) {
    return 'sm';
  } else if (width < 768) {
    return 'md';
  } else if (width < 1024) {
    return 'lg';
  } else {
    return 'xl';
  }
};
