import React from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../utils/tailwind';
import { useT } from '../../utils/helper';
import { Discount as DiscountInterface } from '../../api/types/carts-types';
import { Voucher as VoucherInterface } from '../../api/types/carts-types';

interface CartDetailProps {
  difference_to_min_value: string;
  delivery_charge: string;
  discount: DiscountInterface;
  vouchers: VoucherInterface[];
}

export const CartDetail = (props: CartDetailProps) => {
  const t = useT();

  return (
    <>
      {props.difference_to_min_value ? (
        <View style={tw`gap-y-[10px]`}>
          <View style={tw`flex-row justify-between`}>
            <Text style={tw`xl:text-lg text-base font-medium text-gray-900`}>
              {t('difference_to_min_value')}
            </Text>
            <Text
              style={tw`xl:text-lg xl:pr-0 text-base font-medium text-right text-gray-900 grow-0 font-bold`}>
              {props.difference_to_min_value}
            </Text>
          </View>
        </View>
      ) : null}

      {props.delivery_charge ? (
        <View style={tw`gap-y-[10px]`}>
          <View style={tw`flex-row justify-between`}>
            <Text style={tw`xl:text-lg text-base font-medium text-gray-900`}>
              {t('delivery_charge')}
            </Text>
            <Text
              style={tw`xl:text-lg xl:pr-0 text-base font-medium text-right text-gray-900 grow-0 font-bold`}>
              {props.delivery_charge}
            </Text>
          </View>
        </View>
      ) : null}

      {props.discount ? (
        <View style={tw`gap-y-[10px]`}>
          <View style={tw`flex-row justify-between`}>
            <Text style={tw`xl:text-lg text-base font-medium text-gray-900`}>
              {props.discount.name}
            </Text>
            <Text
              style={tw`xl:text-lg xl:pr-0 text-base font-medium text-right text-gray-900 grow-0 font-bold`}>
              {props.discount.discount}
            </Text>
          </View>
        </View>
      ) : null}

      {props.vouchers ? (
        props.vouchers.map((voucher) => (
          <View style={tw`gap-y-[10px]`}>
            <View style={tw`flex-row justify-between`}>
              <Text style={tw`xl:text-lg text-base font-medium text-gray-900`}>
                {voucher.name}
              </Text>
              <Text
                style={tw`xl:text-lg xl:pr-0 text-base font-medium text-right text-gray-900 grow-0 font-bold`}>
                {voucher.discount}
              </Text>
            </View>
          </View>
        ))
      ) : null}
    </>
  );
};
