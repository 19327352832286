import { useFormik } from 'formik';
import * as yup from 'yup';
import { CustomerParams } from '../api/types/cash-register/customer-types';
import { useT } from '../utils/helper';

export const initialValues = {
  name: '',
  is_permanent_address: false,
};

const createCustomerFormSchema = (
  isAddressRequired: boolean,
  t: (key: string) => string,
) =>
  yup.object().shape({
    id: yup.number(),
    name: yup.string().required(t('requiredField').toString()),
    phone: yup.string().nullable().default(null),
    street: isAddressRequired
      ? yup.string().required(t('requiredField').toString()).default(null)
      : yup.string().nullable().default(null),
    street_number: isAddressRequired
      ? yup.string().required(t('requiredField').toString()).default(null)
      : yup.string().nullable().default(null),
    city: isAddressRequired
      ? yup.string().required(t('requiredField').toString()).default(null)
      : yup.string().nullable().default(null),
    is_permanent_address: yup.boolean().required(t('requiredField').toString()),
  });

const useCustomerForm = (
  isAddressRequired: boolean,
  onSubmit: (values: CustomerParams) => void,
) => {
  const t = useT('forms');

  const customerFormSchema = createCustomerFormSchema(isAddressRequired, t);

  const form = useFormik({
    initialValues: initialValues as CustomerParams,
    validationSchema: customerFormSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit,
  });

  return form;
};

export { useCustomerForm };
