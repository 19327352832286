import React, { useEffect, useState } from 'react';
import { tw } from '../../../utils/tailwind';
import { ModalFullBase } from '../../modals/modal-full-base';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  TextInput,
} from 'react-native';
import { ProductCategoryInput } from '../cart/product-category-input';
import { ProductInput } from '../cart/product-input';
import { twCb, useT } from '../../../utils/helper';
import {
  Product,
  ProductCategory,
} from '../../../api/types/cash-register/product-category-types';
import { CartType } from '../../../api/types/cash-register/cart-types';
import { useProductFilter } from '../../../hooks/use-product-filter.hook';
import { NumericInputPad } from '../shared/numeric-input-pad';

export enum ProductInputModalMode {
  CATEGORY_SELECT = 'CATEGORY_SELECT',
  PLU_SEARCH = 'PLU_SEARCH',
  HIDDEN = 'HIDDEN',
}
interface ModalProductInputProps {
  cartType: CartType;
  isVisible: boolean;
  mode: ProductInputModalMode;
  initialCategory: ProductCategory | null;
  onModalHide: () => void;
  onProductSelect: (product: Product) => void;
  onCategorySelect?: (categoryId: number | null) => void;
}

export const ModalProductInput = (props: ModalProductInputProps) => {
  // Hooks
  const t = useT();
  const [mode, setMode] = useState<ProductInputModalMode>(props.mode);
  const [productNumber, setProductNumber] = useState<string>();
  const [category, setCategory] = useState<ProductCategory | null>(
    props.initialCategory,
  );
  const { filter } = useProductFilter(props.cartType);

  const categoryId = category?.id || null;
  const filteredProducts = filter(
    mode === ProductInputModalMode.PLU_SEARCH && !productNumber
      ? { query: '*' }
      : { productNumber, categoryId: categoryId },
  );

  // Functions
  const onFooterButtonPress = () => {
    setCategory(null);
    props.onModalHide();
  };

  const onHeaderButtonPress = () => {
    if (categoryId) {
      setCategory(null);
    } else {
      props.onModalHide();
    }
  };

  // // Effects
  useEffect(() => {
    // Reset category when modal is hidden
    if (props.isVisible) {
      setCategory(null);
    }
  }, [props.isVisible]);

  useEffect(() => {
    setMode(props.mode);
  }, [props.mode]);

  useEffect(() => {
    // On bigger screen, we don't show the modal and
    // won't call onCategorySelect callback
    if (props.onCategorySelect && props.isVisible) {
      props.onCategorySelect(categoryId);
    }
  }, [categoryId]);

  return (
    <ModalFullBase
      isVisible={props.isVisible}
      onModalHide={props.onModalHide}
      onBackButtonPress={props.onModalHide}
      onBackdropPress={props.onModalHide}
      type="simple"
      style="m-0">
      <ScrollView
        contentContainerStyle={tw`md:py-[24px] md:px-4 gap-y-4 mx-6 my-4`}>
        {/* Header */}
        <View style={tw`flex-row justify-between items-center gap-x-4 mb-2`}>
          <Text
            style={tw`md:text-[28px] text-xl leading-none font-bold text-gray-800`}>
            {categoryId ? category?.name : t('products')}
          </Text>

          {/* Header Button */}
          <TouchableOpacity
            onPress={onHeaderButtonPress}
            style={tw`${twCb(
              'justify-center items-center md:py-4 py-3 px-3 rounded-lg border border-solid w-full w-[140px] border-gray-800',
            )}`}>
            <Text
              style={tw`${twCb(
                'text-lg leading-none font-medium text-gray-800',
              )}`}>
              {t('back')}
            </Text>
          </TouchableOpacity>
        </View>
        {/* Body */}
        {mode !== ProductInputModalMode.PLU_SEARCH && categoryId === null ? (
          <>
            <ProductCategoryInput
              cartType={props.cartType}
              mode="embed"
              onSelect={selectedCategory => setCategory(selectedCategory)}
            />
            {mode === ProductInputModalMode.CATEGORY_SELECT ? (
              <TouchableOpacity
                onPress={() => setMode(ProductInputModalMode.PLU_SEARCH)}
                style={tw`px-3 py-5 bg-success rounded-lg`}>
                <Text
                  style={tw`text-xl leading-none text-center font-medium text-white`}>
                  {t('pluSearch')}
                </Text>
              </TouchableOpacity>
            ) : null}
          </>
        ) : (
          <ProductInput
            products={filteredProducts}
            mode="embed"
            scrollClassName="max-h-full"
            onSelect={selectedProduct => props.onProductSelect(selectedProduct)}
          />
        )}
      </ScrollView>
      {/* Footer */}
      {mode === ProductInputModalMode.PLU_SEARCH ? (
        <View style={tw`mx-6 my-4`}>
          <TextInput
            autoFocus={true}
            value={productNumber}
            placeholder={t('plu').toString()}
            multiline={false}
            onChange={e => setProductNumber(e.nativeEvent.text)}
            placeholderTextColor={tw.color('gray-400')}
            style={tw`py-4 px-4 text-xl font-medium leading-none text-gray-800
            rounded-lg border-[1.5px] border-solid border-gray-400 shadow-sm mb-4`}
          />
          <NumericInputPad
            numbers={productNumber || ''}
            onChange={input => setProductNumber(input)}
          />
        </View>
      ) : null}
      <TouchableOpacity
        onPress={onFooterButtonPress}
        style={tw`items-center px-3 py-6 w-full bg-gray-800`}>
        <Text style={tw`text-2xl leading-none font-medium text-white`}>
          {t('addToCart')}
        </Text>
      </TouchableOpacity>
    </ModalFullBase>
  );
};

ModalProductInput.defaultProps = {
  initialCategory: null,
};
