import React from 'react';
import {
  TouchableOpacity,
  Text,
  StyleProp,
  TextStyle,
  ViewStyle,
  ActivityIndicator,
} from 'react-native';
import { tw } from '../../../utils/tailwind';
import { twCb } from '../../../utils/helper';

interface FooterBarItemProps {
  active: boolean;
  text: string;
  loading: boolean;
  onPress: () => void;
  buttonStyles?: StyleProp<ViewStyle>;
  disabled?: boolean;
  textStyles?: StyleProp<TextStyle>;
  loadingColor?: string;
}

export const FooterBarItem = (props: FooterBarItemProps) => {
  return (
    <TouchableOpacity
      onPress={() => props.onPress()}
      activeOpacity={1}
      disabled={props.disabled}
      style={[
        tw`${twCb(
          'md:basis-[19%] basis-[32%] grow justify-center items-center px-3 py-6',
          props.active ? 'bg-success' : 'bg-gray-800',
          props.disabled ? 'opacity-80' : '',
        )}`,
        props.buttonStyles,
      ]}>
      {/* Store Area Name */}
      {props.loading ? (
        <ActivityIndicator size="small" color={props.loadingColor || '#fff'} />
      ) : (
        <Text
          style={[
            tw`text-lg leading-none font-medium text-white text-center`,
            props.textStyles,
          ]}>
          {props.text}
        </Text>
      )}
    </TouchableOpacity>
  );
};

FooterBarItem.defaultProps = {
  loading: false,
};
