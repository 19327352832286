import { UseInfiniteQueryResult } from '@tanstack/react-query';
import React from 'react';
import { FlatList, FlatListProps } from 'react-native';

interface FlatListPaginationProps
  extends Omit<
    FlatListProps<any>,
    'data' | 'onEndReached' | 'onEndReachedThreshold'
  > {
  query: UseInfiniteQueryResult<any, any>;
  collectionName: string;
}

export const FlatListPagination = (props: FlatListPaginationProps) => {
  const { hasNextPage, data } = props.query;

  const collection = data?.pages
    ? data.pages.flatMap(page => page[props.collectionName])
    : [];

  const loadMore = () => {
    if (hasNextPage && !props.query.isFetchingNextPage) {
      props.query.fetchNextPage();
    }
  };

  return (
    <FlatList
      data={collection}
      onEndReached={loadMore}
      onEndReachedThreshold={0.5}
      {...props}
    />
  );
};
