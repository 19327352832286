import { createContext, useContext } from 'react';
import { OpeningState, Tenant } from '../api/types/tenants-types';

export type TenantWithOpeningState = Tenant & { opening_state: OpeningState };

type TenantContextType = {
  tenant: TenantWithOpeningState;
  adminTenantPath: (path: string) => string;
};

const TenantContext = createContext<TenantContextType>({} as TenantContextType);

const useTenant = (): TenantContextType => {
  const context = useContext(TenantContext);

  if (!context) {
    throw new Error('useTenantContext must be used within an TenantProvider');
  }

  return context;
};

export { TenantContext, useTenant };
