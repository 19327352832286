import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  AuthContext,
  AuthContextStatusType,
  AuthDataType,
} from '../contexs/auth-context';
import { Cashier } from '../api/types/cash-register/cashier-types';
import { storeAuthData, fetchAuthData } from '../api/services/auth-data';
import { Platform } from 'react-native';
import env from '../env';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [status, setStatus] = useState<AuthContextStatusType>('IDLE');
  const [authData, setAuthData] = useState<AuthDataType>();
  const [cashier, setCashier] = useState<Cashier | null>(null);

  useEffect(() => {
    if (Platform.OS === 'web' && env.ENABLE_SINGLE_SIGN_ON) {
      // assume that the user is logged in, if the response
      // is 401 / 403, the user will be redirected to the login page
      setStatus('SIGNED_IN');
    } else {
      //Every time the App is opened, this provider is rendered
      //and call the loadStorage function.
      loadStorageData();
    }

    if (!cashier) {
      loadCurrentCashier();
    }
  }, []);

  async function loadStorageData(): Promise<void> {
    try {
      //Try get the data from Async Storage
      const storedAuthData = await fetchAuthData();

      if (storedAuthData) {
        //If there are data, it's converted to an Object and the state is updated.
        setAuthData(storedAuthData);
        setStatus('SIGNED_IN');
      } else {
        setStatus('NOT_SIGNED_IN');
        setAuthData(undefined);
      }
    } catch (error) {
      setStatus('NOT_SIGNED_IN');
    }
  }

  const signIn = (data: AuthDataType) => {
    //Set the data in the context, so the App can be notified
    //and send the user to the AuthStack
    setAuthData(data);

    //Persist the data in the Async Storage
    //to be recovered in the next user session.
    storeAuthData(data);
    setStatus('SIGNED_IN');
  };

  const signOut = async () => {
    setCurrentCashier(null);

    if (Platform.OS === 'web' && env.ENABLE_SINGLE_SIGN_ON) {
      window.location.href = env.APP_URL;
      return;
    }

    //and send the user to the AuthStack
    setAuthData(undefined);
    setStatus('NOT_SIGNED_IN');

    //Remove the data from Async Storage
    //to NOT be recoverede in next session.
    await AsyncStorage.removeItem('AuthData');
  };

  const setCurrentCashier = (currentCashier: Cashier | null) => {
    if (currentCashier) {
      AsyncStorage.setItem('currentCashier', JSON.stringify(currentCashier));
    } else {
      AsyncStorage.removeItem('currentCashier');
    }

    setCashier(currentCashier);
  };

  const loadCurrentCashier = async () => {
    const currentCahier = await AsyncStorage.getItem('currentCashier');

    if (currentCahier) {
      setCashier(JSON.parse(currentCahier));
    }
  };

  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <AuthContext.Provider
      value={{
        authData,
        status,
        currentCashier: cashier,
        setCurrentCashier,
        signIn,
        signOut,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
