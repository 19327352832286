import React, { forwardRef, useEffect, useRef } from 'react';
import AirDatepicker, { AirDatepickerOptions } from 'air-datepicker';
import localeEn from 'air-datepicker/locale/de';
import tw from 'twrnc';

export type AirDatepickerInstance = {
  $datepicker: HTMLElement;
  show: () => void;
  hide: () => void;
};

export const WebDatePicker = forwardRef(
  (props: AirDatepickerOptions, ref: any) => {
    const $input = useRef<HTMLInputElement>(null);
    const dp = ref;

    // Start init
    useEffect(() => {
      // Save instance for the further update
      if ($input.current) {
        dp.current = new AirDatepicker($input.current, {
          locale: localeEn,
          buttons: ['clear'],
          onShow: (isFinished: boolean) => {
            if (!isFinished) {
              // react-native-modal has 9999 z-index
              dp.current.$datepicker.style.zIndex = 10000;
            }
          },
          ...props,
        });
      }
    }, []);

    useEffect(() => {
      // Update if props are changed
      // @ts-ignore
      dp.current.update({ ...props });
    }, [props]);

    return <input ref={$input} style={tw`hidden`} />;
  },
);
