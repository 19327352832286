import React from 'react';
import { tw } from '../../../utils/tailwind';
import { View, Text, ScrollView } from 'react-native';
import { twCb, useT } from '../../../utils/helper';
import { useProductCategoryQueries } from '../../../api/hooks/cash-register/products/use-product-category-queries';
import QuerySuspense from '../../../api/query-suspense';
import { ProductCategory } from '../../../api/types/cash-register/product-category-types';
import { CartType } from '../../../api/types/cash-register/cart-types';
import SplashScreen from '../../../screens/splash/splash.screen';
import { ProductTile } from './product-tile';

interface ProductCategoryInputProps {
  cartType: CartType;
  onSelect: (category: ProductCategory) => void;
  mode?: 'embed';
}

export const ProductCategoryInput = (props: ProductCategoryInputProps) => {
  // Hooks
  const t = useT();
  const { fetchProductCategories } = useProductCategoryQueries(props.cartType);
  // Other variables
  const mode = props.mode || 'default';

  return (
    <View style={tw`gap-y-4`}>
      {mode === 'default' ? (
        <Text
          style={tw`md:text-xl text-lg leading-none font-semibold text-gray-800`}>
          {t('productCategories')}
        </Text>
      ) : null}
      <ScrollView
        style={tw`${twCb(mode === 'embed' ? '' : 'h-[392px]')}`}
        contentContainerStyle={[
          tw`flex-row flex-wrap gap-4 items-start`,
          fetchProductCategories.isLoading ? tw`h-full` : tw``,
        ]}>
        <QuerySuspense
          query={fetchProductCategories}
          hideRefetchLoading={true}
          loadingComponent={<SplashScreen />}
          onSuccess={productCategories =>
            productCategories.map((category, i) => (
              <ProductTile
                key={i}
                text={category.name}
                mode={mode}
                onPress={() => props.onSelect(category)}
              />
            ))
          }
        />
      </ScrollView>
    </View>
  );
};
