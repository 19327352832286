import { useQuery } from '@tanstack/react-query';
import request from '../../request';
import { StoreMenuOptionsResponse } from '../../types/tenants-types';

export const useStoreMenuOptionQueries = () => {
  const fetchStoreMenuOptions = useQuery(
    ['storeMenuOptions'],
    async () =>
      request({
        method: 'get',
        url: '/store/tenants/store_menu_options.json',
      }) as Promise<StoreMenuOptionsResponse>,
    {
      // This means that the next time the query is run, it will refetch
      // the data if it's more than 15 minutes old.
      staleTime: 1000 * 60 * 15,
    },
  );

  return { fetchStoreMenuOptions };
};
