import React from 'react';
import { View, Text } from 'react-native';
import { tw } from '../../utils/tailwind';
import { useT } from '../../utils/helper';
import { Cart, Option } from '../../api/types/vendor/carts-types';
import { useCartsMutations } from '../../api/hooks/vendor/carts/use-carts-mutations';
import { chunkArray } from '../../utils/helper';
import { processPOSCommand } from '../../utils/print-module';
import { ModalFullBase } from './modal-full-base';
import { calculateOpacity } from '../../utils/style.helper';
import { ButtonModalOption } from '../shared/form/button/button-modal-option';

export enum ModalCartActionMode {
  'CANCELLATION' = 'cancellation',
  'PROCESSING_TIME' = 'setProcessingTime',
  'DELAY_TIME' = 'setDelayTime',
}

export type ModalCartActionModes =
  | 'off'
  | 'cancellation'
  | 'setProcessingTime'
  | 'setDelayTime';

interface ModalCartActionProps {
  mode: ModalCartActionModes;
  onModalHide: () => void;
  cart: Cart;
}

export const ModalCartAction = (props: ModalCartActionProps) => {
  const t = useT();
  const { updateCart, printReceipt } = useCartsMutations();

  const performUpdateCart = (url: string) => {
    // Hide modal
    props.onModalHide();

    updateCart.mutateAsync(url).then(data => {
      // Print receipt if there is any
      if (data?.print_receipt_path) {
        printReceipt
          .mutateAsync(data.print_receipt_path)
          .then(printData => processPOSCommand(printData.print_jobs));
      }
    });
  };

  const renderOptionButton = (
    option: Option,
    index: number | string,
    extraStyle?: string,
  ) => {
    return (
      <ButtonModalOption
        key={index}
        label={option.name}
        onPress={() => performUpdateCart(option.path)}
        extraStyle={extraStyle || ''}
      />
    );
  };

  const renderCancellationOptions = () => {
    return (
      <>
        <Text
          style={tw`mb-6 md:text-2xl text-xl text-center font-bold text-gray-800`}>
          {t('cancelOrder')}
        </Text>

        <Text style={tw`lg:text-base mb-6 text-center text-gray-500`}>
          {t('whyCancelOrder')}
        </Text>

        <View style={tw`gap-y-3 w-full`}>
          {props.cart.actions.cancellation_options.map((option, index) =>
            renderOptionButton(option, index),
          )}
        </View>
      </>
    );
  };

  const renderSetProcessingTimeOptions = () => {
    const processingTimeOptions =
      props.cart.actions.set_processing_time_options;
    // main option is either a pickup time or manual processing time
    // for table order. This option always have full width to make it
    // stand out.
    const mainOption =
      props.cart.pickup_time || props.cart.cart_type === 'table_order'
        ? processingTimeOptions[0]
        : null;
    // rest options will be rendered in 3 columns
    const restOptions = processingTimeOptions.slice(mainOption ? 1 : 0);

    return (
      <View style={tw`w-full`}>
        <Text
          style={tw`mb-6 md:text-2xl text-xl text-center font-bold text-gray-800`}>
          {t(
            props.cart.cart_type === 'delivery'
              ? 'whenWillTheOrderBeDelivered'
              : 'whenWillTheOrderBePickedUp',
          )}
        </Text>

        {/* Last option always have full width */}
        {mainOption
          ? renderOptionButton(mainOption, 'mainOption', 'bg-gray-900 mb-3')
          : null}

        {/* Render all options except the last one */}
        {chunkArray(restOptions, 3).map((options, index) => (
          <View key={index} style={tw`flex-row gap-3 mb-3 `}>
            {options.map((option, idx) =>
              renderOptionButton(
                option,
                idx,
                `flex-1 bg-gray-${calculateOpacity(index)}`,
              ),
            )}
          </View>
        ))}
      </View>
    );
  };

  const renderSetDelayTimeOptions = () => {
    const delayOptions = props.cart.actions.set_delay_options || [];

    return (
      <View style={tw`w-full`}>
        <Text
          style={tw`mb-6 md:text-2xl text-xl text-center font-bold text-gray-800`}>
          {t('howLongIsTheDelay')}
        </Text>

        {chunkArray(delayOptions, 2).map((options, index) => (
          <View key={index} style={tw`flex-row gap-3 mb-3 `}>
            {options.map((option, idx) =>
              renderOptionButton(
                option,
                idx,
                `flex-1 bg-gray-${calculateOpacity(index)}`,
              ),
            )}
          </View>
        ))}
      </View>
    );
  };

  return (
    <ModalFullBase
      isVisible={props.mode !== 'off'}
      backdropColor={tw.style('bg-white').backgroundColor.toString()}
      backdropOpacity={1}
      onModalHide={props.onModalHide}
      onBackdropPress={props.onModalHide}
      onBackButtonPress={props.onModalHide}>
      {props.mode === 'cancellation' && renderCancellationOptions()}
      {props.mode === 'setProcessingTime' && renderSetProcessingTimeOptions()}
      {props.mode === 'setDelayTime' && renderSetDelayTimeOptions()}
    </ModalFullBase>
  );
};
