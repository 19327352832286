import React from 'react';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { tw } from '../../../utils/tailwind';
import { useT } from '../../../utils/helper';
import { View, useWindowDimensions } from 'react-native';
import { CashbookSummary } from './cashbook-summary';
import { CashbookEntries } from './cashbook-entries';

export const CashbookScreen = () => {
  // Standard hooks
  const t = useT();
  const { height } = useWindowDimensions();

  return (
    <ScreenContainer
      title={t('cashbook').toString()}
      scrollable={false}
      headerWrapperStyle={tw`w-full m-auto max-w-[800px]`}
      containerStyle={tw`md:gap-y-6 h-full items-start content-start gap-y-5`}>
      <View
        style={tw`md:gap-y-6 gap-y-5 w-full mx-auto max-w-[800px] h-full max-h-[${
          height - 120
        }px]`}>
        <CashbookSummary />
        <CashbookEntries />
      </View>
    </ScreenContainer>
  );
};
