import { createContext, useContext } from 'react';
import { LineItem } from '../api/types/cash-register/order-session.types';

export type PaymentSteps =
  | 'ITEM_SELECTION'
  | 'INVOICE_CREATION'
  | 'PAYMENT'
  | 'PRINT';
export interface SelectedLineItem extends LineItem {
  selected: boolean;
  selectedQuantity: number;
}

export type PaymentContextType = {
  step: PaymentSteps;
  setStep: (step: PaymentSteps) => void;
};

export const PaymentContext = createContext<PaymentContextType>({
  step: 'ITEM_SELECTION',
  setStep: () => {},
});

export const usePayment = (): PaymentContextType => {
  const context = useContext(PaymentContext);

  if (!context) {
    throw new Error('usePayment must be used within an PaymentProvider');
  }

  return context;
};
