import { useEffect } from 'react';
import { useCartsMutations } from '../../api/hooks/vendor/carts/use-carts-mutations';
import { useAuth } from '../../contexs/auth-context';
import env from '../../env';
import { NewOrderChannelPayload } from '../types';
import useActionCable from './use-action-cable';
import useChannel from './use-channel';
import { useCartsQueries } from '../../api/hooks/vendor/carts/use-carts-queries';

export default function useNewOrdersChannel() {
  const { authData } = useAuth();
  const { actionCable } = useActionCable(
    `${env.WEBSOCKET_URL}?token=${authData?.token}`,
  );
  const { subscribe, unsubscribe } = useChannel(actionCable);
  const { updateQueryData } = useCartsMutations();
  const { fetchCarts } = useCartsQueries();

  useEffect(() => {
    subscribe(
      { channel: 'NewOrdersChannel' },
      {
        received: (payload: NewOrderChannelPayload) => {
          // New cart created, fetch and append it to query cache.
          updateQueryData(payload.cart);
        },
        connected: () => {
          // refetching when internet connected is handled by react-query.
          // this is to handle when server is restarted.
          if (
            !fetchCarts.isFetching &&
            Date.now() - fetchCarts.dataUpdatedAt > 1000 * 60 // 1 minutes
          ) {
            fetchCarts.refetch();
          }
        },
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);
}
