import { useInfiniteQuery } from '@tanstack/react-query';
import request from '../../../request';
import {
  CustomersResponse,
  CustomersParams,
} from '../../../types/cash-register/customer-types';

export const useCustomerQueries = (params?: CustomersParams) => {
  const fetchCustomers = useInfiniteQuery(
    ['customers', params],
    async ({ pageParam }) =>
      request({
        method: 'get',
        url: '/store/customers.json',
        params: { sort_by: 'name_lower', ...params, page: pageParam },
      }) as Promise<CustomersResponse>,
    {
      getNextPageParam: (lastPage, _pages) => {
        // Return the next page number if there are more pages
        return lastPage.next_page || false;
      },
    },
  );

  return { fetchCustomers };
};
