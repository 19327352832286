import React, { useState } from 'react';
import {
  TouchableOpacity,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import { tw } from '../../../utils/tailwind';
import { CloseSVG, MenuSVG } from '../../../../assets/svg';
import { getHeaderBarColorTheme } from '../../../utils/conditional-style';
import { HEADER_HEIGHT, navigateToAdmin, useT } from '../../../utils/helper';
import {
  MenuOptionContent,
  MenuOptionContentTheme,
} from '../../menus/menu-option-content';
import QuerySuspense from '../../../api/query-suspense';
import { useStoreMenuOptionQueries } from '../../../api/hooks/tenants/use-store-menu-option-queries';
import { useAuth } from '../../../contexs/auth-context';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { useAlert } from '../../../contexs/alert-context';
import { useAuthMutations } from '../../../api/hooks/auth/use-auth-mutations';

export const HeaderBarMenu = () => {
  // Standard Hooks
  const t = useT();
  const navigation = useNavigation<AppStackScreenProps<'Root'>['navigation']>();
  const alert = useAlert();
  // Hooks
  const { fetchStoreMenuOptions } = useStoreMenuOptionQueries();
  const [isOpen, setIsOpen] = useState(false);
  const { setCurrentCashier, signOut } = useAuth();
  const { performLogout } = useAuthMutations();
  const { height } = useWindowDimensions();
  // Renderers
  const { NotAnimatedContextMenu } = renderers;
  const generateMenuOption = (
    name?: string,
    onSelectCallback?: () => void,
    theme?: MenuOptionContentTheme,
  ) => {
    return name ? (
      <MenuOption onSelect={onSelectCallback}>
        <MenuOptionContent text={name} theme={theme} />
      </MenuOption>
    ) : null;
  };
  // Callbacks
  const onSignOut = () => {
    alert.setModalOptions({
      onModalHide: () => {
        alert.hide();
      },
      onConfirm: () => {
        performLogout.mutateAsync().then(signOut);
      },
    });

    alert.show(t('areYouSureWantToLogOut').toString());
  };

  return (
    <Menu
      renderer={NotAnimatedContextMenu}
      style={tw`gap-x-3 items-center`}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}>
      {/* Right Button */}
      <MenuTrigger
        style={tw`flex-row md:py-3 md:pl-6 md:pr-4 md:gap-x-2 p-3 justify-center items-center rounded-lg bg-white`}
        customStyles={{ TriggerTouchableComponent: TouchableOpacity }}>
        <Text style={tw`md:flex hidden text-xl leading-none`}>Menu</Text>
        {isOpen ? (
          <CloseSVG
            stroke={getHeaderBarColorTheme('default')}
            style={tw`w-5 h-5`}
          />
        ) : (
          <MenuSVG
            stroke={getHeaderBarColorTheme('default')}
            style={tw`w-5 h-5`}
          />
        )}
      </MenuTrigger>

      {/* Menu */}
      <MenuOptions
        customStyles={{
          optionsContainer: tw.style(
            `md:w-[376px] md:p-6 md:rounded-lg md:h-auto mt-[${
              HEADER_HEIGHT - 10
            }px] p-4 bg-white w-full h-[${height - HEADER_HEIGHT}px]`,
          ),
          optionsWrapper: tw.style('gap-y-3 content-start h-full'),
          optionWrapper: tw.style(
            'justify-center items-center text-center py-5 px-2 w-full bg-gray-100 rounded-lg',
          ),
        }}>
        {/* Load menu options from API */}
        <QuerySuspense
          query={fetchStoreMenuOptions}
          onSuccess={menu => (
            <>
              {generateMenuOption(menu.administration?.name, navigateToAdmin)}
              {generateMenuOption(menu.order_history?.name, () =>
                navigation.navigate('Orders', {}),
              )}
              {generateMenuOption(menu.print_yearly_report?.name)}
              {generateMenuOption(menu.payment_history?.name, () =>
                navigation.navigate('Invoices', {}),
              )}
              {generateMenuOption(menu.customer?.name, () =>
                navigation.navigate('Customers'),
              )}
              {generateMenuOption(menu.cashbook?.name, () =>
                navigation.navigate('Cashbook'),
              )}
              {generateMenuOption(menu.print_monthly_report?.name)}
              {generateMenuOption(menu.reset_cash_register?.name)}
              {generateMenuOption(menu.cashier_report?.name)}
              {generateMenuOption(menu.print_z_bon?.name)}
              {generateMenuOption(
                menu.lock?.name,
                () => setCurrentCashier(null),
                'danger',
              )}

              <View style={tw`absolute bottom-3 w-full`}>
                {generateMenuOption(
                  t('DeRegister').toString(),
                  () => onSignOut(),
                  'danger',
                )}
              </View>
            </>
          )}
        />
      </MenuOptions>
    </Menu>
  );
};
