import request from '../../../request';
import { useMutation } from '@tanstack/react-query';
import {
  CartParams,
  CartResponse,
} from '../../../types/cash-register/cart-types';

export const useCartMutations = () => {
  const createCart = useMutation(
    async (params: CartParams) =>
      request({
        method: 'post',
        url: '/store/carts.json',
        data: { cart: params },
      }) as Promise<CartResponse>,
  );

  return { createCart };
};
