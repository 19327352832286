import request from '../../../request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  LineItemParams,
  UpdateLineItemQuantityParams,
} from '../../../types/cash-register/line-item-types';
import { Cart, LineItem } from '../../../types/cash-register/cart-types';
import { calculateCartTotal } from '../../../services/calulcate-cart-total';

export const useLineItemMutations = () => {
  const queryClient = useQueryClient();

  const createLineItem = useMutation(
    async (params: LineItemParams) =>
      request({
        method: 'post',
        url: `/store/carts/${params.cart_id}/line_items.json`,
        data: { line_item: params },
      }) as Promise<LineItem>,
  );

  const updateLineItem = useMutation(
    async (params: LineItemParams) =>
      request({
        method: 'put',
        url: `/store/carts/${params.cart_id}/line_items/${params.id}.json`,
        data: { line_item: params },
      }) as Promise<null>,
  );

  const increaseLineItemQuantity = useMutation(
    async (params: UpdateLineItemQuantityParams) =>
      request({
        method: 'put',
        url: `/store/carts/${params.cart_id}/line_items/${params.id}/increase.json`,
      }) as Promise<null>,
  );

  const decreaseLineItemQuantity = useMutation(
    async (params: UpdateLineItemQuantityParams) =>
      request({
        method: 'put',
        url: `/store/carts/${params.cart_id}/line_items/${params.id}/decrease.json`,
      }) as Promise<null>,
  );

  // Optimisticly update line item quantity
  const changeLineItemQuantity = (lineItem: LineItem, quantity: number) => {
    const params = {
      id: lineItem.id,
      cart_id: lineItem.cart_id,
    };

    const onRequestFailed = () => {
      updateLineItemQueryData({
        ...lineItem,
        quantity: lineItem.quantity,
        total_price: lineItem.single_price * lineItem.quantity,
        _destroy: false,
      });
    };

    if (quantity === 0) {
      updateLineItemQueryData({ ...lineItem, _destroy: true });
    } else {
      updateLineItemQueryData({
        ...lineItem,
        quantity,
        total_price: lineItem.single_price * quantity,
      });
    }

    if (quantity > lineItem.quantity) {
      return increaseLineItemQuantity
        .mutateAsync(params)
        .catch(onRequestFailed);
    } else if (quantity < lineItem.quantity) {
      return decreaseLineItemQuantity
        .mutateAsync(params)
        .catch(onRequestFailed);
    }
  };

  // Update query data for line item
  const updateLineItemQueryData = (lineItem: LineItem) => {
    return queryClient.setQueryData(
      ['cashRegister', 'carts', lineItem.cart_id || 'template'],
      (prevCart: Cart | undefined) => {
        if (!prevCart) {
          return undefined;
        }

        const updateLineItems = lineItem.id
          ? prevCart.line_items.map(li =>
              li.id === lineItem.id ? { ...li, ...lineItem } : li,
            )
          : [...prevCart.line_items, lineItem];

        return {
          ...prevCart,
          line_items: updateLineItems,
          total: calculateCartTotal(updateLineItems),
        };
      },
    );
  };

  return {
    createLineItem,
    updateLineItem,
    increaseLineItemQuantity,
    decreaseLineItemQuantity,
    changeLineItemQuantity,
    updateLineItemQueryData,
  };
};
