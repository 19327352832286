import React, { useState } from 'react';
import { tw } from '../../utils/tailwind';
import {
  TextInput,
  TouchableOpacity,
  TextInputProps,
  TouchableOpacityProps,
  Text,
  GestureResponderEvent,
} from 'react-native';
import { twCb } from '../../utils/helper';
import { LoadingAnimation } from '../loading-animation';

interface T0TextInputProps extends TextInputProps {
  name: string;
}

interface T0TouchableOpacityProps extends TouchableOpacityProps {
  label: string;
  theme?: string;
  extraStyle?: string;
  extraLabelStyle?: string;
  disableOnPress?: boolean;
}

const T0TextInput = (props: T0TextInputProps) => {
  return (
    <TextInput
      {...props}
      style={[
        tw`border-[1px] border-solid border-gray-100 bg-gray-100 rounded-xl mt-2.5 py-3 px-4 w-full`,
        props.style,
      ]}
    />
  );
};

const getButtonStyles = (theme?: string) => {
  let touchableStyle = 'p-4 items-center text-sm rounded-lg';
  let labelStyle = 'text-lg leading-none capitalize';

  switch (theme) {
    case 'pressing_white':
    case 'pressing_gray':
    case 'pink':
      touchableStyle = twCb(
        touchableStyle,
        'border-[1px] border-solid border-pink-600 bg-pink-600',
      );
      labelStyle = twCb(labelStyle, 'text-white');
      break;
    case 'pressing_pink':
    case 'white':
      touchableStyle = twCb(
        touchableStyle,
        'border-[1px] border-solid border-gray-200',
      );
      labelStyle = twCb(labelStyle, 'text-gray-500');
      break;
    case 'red':
      touchableStyle = twCb(
        touchableStyle,
        'text-error border-[1px] border-solid border-error',
      );
      labelStyle = twCb(labelStyle, 'text-error');
      break;
    case 'blue':
      touchableStyle = twCb(
        touchableStyle,
        'border-[1px] border-solid border-gray-200 bg-blue-600',
      );
      labelStyle = twCb(labelStyle, 'text-white');
      break;
    case 'gray':
      touchableStyle = twCb(
        touchableStyle,
        'border-[1px] border-transparent text-white bg-gray-900',
      );
      labelStyle = twCb(labelStyle, 'text-white');
      break;
    case 'pressing_lightGray':
      touchableStyle = twCb(
        touchableStyle,
        'border-pink-600 bg-pink-600 py-5 sm:px-3 px-1.5',
      );
      labelStyle += ' text-white sm:text-xl font-medium text-base leading-none';
      break;
    case 'lightGray':
      touchableStyle = twCb(
        touchableStyle,
        'border-gray-100 bg-gray-100 py-5 sm:px-3 px-1.5',
      );
      labelStyle +=
        ' text-gray-800 sm:text-xl font-medium text-base leading-none';
      break;
    case 'green':
      touchableStyle = twCb(
        touchableStyle,
        'border-[1px] border-transparent text-white bg-success',
      );
      labelStyle = twCb(labelStyle, 'text-white');
      break;
  }

  return {
    touchableStyle,
    labelStyle,
  };
};

export const T0TouchableOpacity = ({
  label,
  theme,
  extraStyle,
  extraLabelStyle,
  ...props
}: T0TouchableOpacityProps) => {
  const [pressing, setPressing] = useState(false);
  const [pressed, setPressed] = useState(false);
  const disabled = props.disableOnPress && pressed;

  // pressing_gray, gray
  const { touchableStyle, labelStyle } = getButtonStyles(
    `${pressing ? 'pressing_' : ''}${theme || 'gray'}`,
  );

  const onPress = (e: GestureResponderEvent) => {
    if (props.onPress) {
      setPressed(true);
      props.onPress(e);
    }
  };

  return (
    <TouchableOpacity
      {...props}
      onPressIn={() => setPressing(true)}
      onPressOut={() => setPressing(false)}
      onPress={onPress}
      disabled={props.disabled || disabled}
      activeOpacity={1}
      style={tw`${twCb(touchableStyle, extraStyle)}`}>
      <>
        {disabled ? (
          <LoadingAnimation color={tw.color('white')} />
        ) : (
          <Text style={tw`${twCb(labelStyle, extraLabelStyle)}`}>{label}</Text>
        )}
        {props.children}
      </>
    </TouchableOpacity>
  );
};

export { T0TextInput as TextInput, T0TouchableOpacity as TouchableOpacity };
