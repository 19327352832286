import React, { useMemo, useState } from 'react';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { View, useWindowDimensions } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useT } from '../../../utils/helper';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { OrderSessionItems } from './order-session-items';
import { ModalMoveItems } from '../../../components/cash-register/modals/modal-move-items';
import { useOrderSessionQueries } from '../../../api/hooks/cash-register/order-sessions/use-order-session-queries';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList } from '../../../routes';
import QuerySuspense from '../../../api/query-suspense';
import { SelectedLineItem } from '../../../contexs/payment-context';
import { ModalRemoveItems } from '../../../components/cash-register/modals/modal-remove-items';

// temporary type definition
export interface LineItem {
  id: number;
  name: string;
  quantity: number;
  price: number;
  selectedQuantity?: number;
}

enum ModalVisible {
  MoveItems = 'moveItems',
  RemoveItems = 'removeItems',
  None = 'none',
}

interface OrderSessionEditScreenProps {
  route: RouteProp<AppStackParamList, 'OrderSessionEdit'>;
}

export const OrderSessionEditScreen = (props: OrderSessionEditScreenProps) => {
  // Standard Hooks
  const t = useT();
  const { height, width } = useWindowDimensions();
  // Queries
  const id = props.route.params.id;
  const { fetchOrderSession } = useOrderSessionQueries({
    id,
  });
  // States
  const [modalVisible, setModalVisible] = useState<ModalVisible>();
  const [selectedLineItems, setSelectedLineItems] = useState<
    SelectedLineItem[]
  >([]);
  // Other constants
  const shouldButtonBeDisabled = useMemo(
    () => selectedLineItems.filter(l => l.quantity > 0).length < 1,
    [selectedLineItems],
  );

  return (
    <>
      <QuerySuspense
        query={fetchOrderSession}
        onSuccess={orderSession => (
          <ScreenContainer
            title={orderSession.store_table.name}
            scrollable={true}
            headerWrapperStyle={tw`max-w-[540px] self-center w-full`}>
            <View
              style={tw`max-w-[540px] self-center w-full overflow-scroll h-full md:max-h-[${
                height - 200
              }px] max-h-[${height - 250}px]`}>
              <OrderSessionItems
                lineItems={orderSession.line_items}
                onChange={setSelectedLineItems}
              />
            </View>
          </ScreenContainer>
        )}
      />

      {/* Modals */}
      {fetchOrderSession.data ? (
        <ModalMoveItems
          isVisible={modalVisible === ModalVisible.MoveItems}
          orderSession={fetchOrderSession.data}
          lineItems={selectedLineItems}
          onModalHide={() => setModalVisible(ModalVisible.None)}
        />
      ) : null}

      <ModalRemoveItems
        isVisible={modalVisible === ModalVisible.RemoveItems}
        selectedLineItems={selectedLineItems}
        orderSessionId={id}
        cartType="table_order"
        onModalHide={() => setModalVisible(ModalVisible.None)}
      />
      {/** Action bar */}
      <FooterBar style={tw`gap-0 flex-wrap`}>
        {/** Move Items Button */}
        <FooterBarItem
          text={t('moveItems')}
          active={false}
          disabled={shouldButtonBeDisabled}
          onPress={() => setModalVisible(ModalVisible.MoveItems)}
          buttonStyles={[
            tw`md:basis-1/3 basis-1/2 bg-green-600`,
            { order: width >= 768 ? 1 : 4 },
          ]}
          textStyles={tw`text-2xl leading-none`}
        />
        {/** Remove Items Button */}
        <FooterBarItem
          text={t('removeItems')}
          active={false}
          disabled={shouldButtonBeDisabled}
          onPress={() => setModalVisible(ModalVisible.RemoveItems)}
          buttonStyles={[tw`md:basis-1/3 basis-1/2 bg-red-600`, { order: 2 }]}
          textStyles={tw`text-2xl leading-none`}
        />
      </FooterBar>
    </>
  );
};
