import * as Sentry from '@sentry/react-native';

export class EpsonPrinter {
  private builder: epson.ePOSBuilder;
  private device: epson.ePOSPrint;

  constructor(ipAddress: string, devId: string) {
    const address = `https://${ipAddress}/cgi-bin/epos/service.cgi?devid=${devId}&timeout=60000`;
    this.builder = new epson.ePOSBuilder();
    this.device = new epson.ePOSPrint(address);
    this.builder.addTextSmooth(true);

    this.device.onerror = (error: any) => {
      Sentry.captureException(error.status);
    };

    this.device.oncoveropen = () => alert('Bitte die Druckerklappe schließen.');
  }

  public sendRawPosCommand(posCommand: string) {
    this.builder.addCommand(posCommand);
  }

  public addLogo(logoUrl: string) {
    const PRINTER_MAX_WIDTH = 576;
    const canvas = document.createElement('canvas');
    canvas.width = PRINTER_MAX_WIDTH;

    const ctx = canvas.getContext('2d');
    const img = document.createElement('img') as HTMLImageElement;
    const existedIm = document.getElementById('js-bon-log-img');
    existedIm?.remove();

    img.id = 'js-bon-log-img';
    img.style.display = 'none';
    img.src = logoUrl;
    document.body.appendChild(img);

    this.builder.brightness = 1.0;
    this.builder.halftone = this.builder.HALFTONE_THRESHOLD;
    canvas.height = img.height;

    const xPos = (canvas.width - img.width) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, xPos, 0, img.width, img.height);

    this.builder.addImage(
      ctx,
      0,
      0,
      canvas.width,
      canvas.height,
      this.builder.COLOR_1,
      this.builder.MODE_MONO,
    );
  }

  public print(numberOfBons: number) {
    for (let i = 1; i <= numberOfBons; i++) {
      const a = this.device.send(this.builder.toString());
    }
  }
}

declare global {
  interface Window {
    EpsonPrinter: typeof EpsonPrinter;
  }
}
