import React from 'react';
import { View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import QuerySuspense from '../../../api/query-suspense';
import { SelectList } from '../../forms/select-list';
import { useStoreTableQueries } from '../../../api/hooks/cash-register/store-table/use-store-table-queries';
import { twCb, useT } from '../../../utils/helper';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';
import { useLineItemsMutation } from '../../../api/hooks/cash-register/order-sessions/use-line-items-mutation';
import { OrderSession } from '../../../api/types/cash-register/order-session.types';
import { useAuth } from '../../../contexs/auth-context';
import { SelectedLineItem } from '../../../contexs/payment-context';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { TouchableOpacity as Button } from '../../forms';

interface ModalMoveItemsProps {
  isVisible: boolean;
  lineItems: SelectedLineItem[];
  orderSession: OrderSession;
  onModalHide: () => void;
}

export const ModalMoveItems = (props: ModalMoveItemsProps) => {
  // Standard Hooks
  const t = useT();
  // States & Queries
  const navigation =
    useNavigation<AppStackScreenProps<'OrderSessionEdit'>['navigation']>();
  const [destTableId, setDestTableId] = React.useState<number>();
  const { currentCashier } = useAuth();
  const { fetchStoreTables } = useStoreTableQueries({
    sort_by: 'name',
  });
  const { moveItems } = useLineItemsMutation(props.orderSession.id);
  // Other constants
  const originTableId = props.orderSession.store_table.id;
  // Callbacks
  const handleMoveItems = () => {
    if (!currentCashier || !destTableId) {
      return;
    }

    const data = {
      dest_store_table_id: destTableId,
      order: {
        cashier_id: currentCashier.id,
      },
      line_items: props.lineItems.map(l => ({
        id: l.id,
        quantity: l.selectedQuantity,
      })),
    };

    moveItems.mutateAsync(data).then(response => {
      navigation.navigate('OrderSessionEdit', {
        id: response.order.order_session_id,
      });

      props.onModalHide();
    });
  };

  return (
    <ModalConfirmation
      isVisible={props.isVisible}
      content={t('moveItems').toString()}
      onModalHide={props.onModalHide}>
      <View style={tw`flex-1 w-full z-10`}>
        <>
          <QuerySuspense
            query={fetchStoreTables}
            hideRefetchLoading={true}
            onSuccess={tables => (
              <SelectList
                setSelected={selectedOption =>
                  setDestTableId(selectedOption?.key)
                }
                data={tables.filter(t => t.id !== originTableId)}
                labelKey="name"
                valueKey="id"
                placeholder={t('chooseTable').toString()}
                dropdownStyles={tw`top-[60px]`}
              />
            )}
          />

          <View style={tw`gap-3 my-3 w-full`}>
            <Button
              label={t('moveItems')}
              disabled={!destTableId}
              disableOnPress={true}
              onPress={() => handleMoveItems()}
              theme="white"
              extraStyle={twCb(
                'py-5 bg-warning',
                destTableId ? '' : 'opacity-50',
              )}
              extraLabelStyle="leading-none font-medium text-white"
            />
          </View>
        </>
      </View>
    </ModalConfirmation>
  );
};
