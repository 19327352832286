import React, { useEffect, useState } from 'react';
import { SerialNumberInfo } from '../../utils/serial-number-info';
import TenantDeviceSetting from '../tenant-device-setting';
import * as Sentry from '@sentry/react-native';

export const WebViewConnector = () => {
  const [serialNumberInfo, setSerialNumberInfo] =
    useState<SerialNumberInfo | null>(null);

  useEffect(() => {
    if (
      window.ReactNativeWebView?.injectedObjectJson &&
      window.ReactNativeWebView?.injectedObjectJson()
    ) {
      const parsedInjectedObject: SerialNumberInfo = JSON.parse(
        window.ReactNativeWebView.injectedObjectJson(),
      );

      if (parsedInjectedObject.raw) {
        setSerialNumberInfo(parsedInjectedObject);
      }

      // getCurrentSope is not available yet in react-native
      Sentry.configureScope(scope => {
        scope.setTag('injectedObject', JSON.stringify(window.injectedObject));
      });
    }
  }, [window.ReactNativeWebView]);

  return serialNumberInfo ? (
    <TenantDeviceSetting
      serialNumber={serialNumberInfo.raw}
      encryptedSerialNumber={serialNumberInfo.encrypted}
    />
  ) : null;
};
