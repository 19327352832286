import { createContext, useContext } from 'react';
import { ModalConfirmationProp } from '../components/shared/modals/modal-confirmation';

export type AuthDataType = {
  token: string;
};
type AlertContextType = {
  isVisible: boolean;
  show: (content: string) => void;
  hide: () => void;
  setModalOptions: (options: Partial<ModalConfirmationProp>) => void;
};

const AlertContext = createContext<AlertContextType>({
  isVisible: false,
  show: () => {},
  hide: () => {},
  setModalOptions: () => {},
});

function useAlert(): AlertContextType {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }

  return context;
}

export { AlertContext, useAlert };
