import React, { useRef, useState } from 'react';
import { Platform, Text, View } from 'react-native';
import DatePicker from 'react-native-date-picker';
import { tw } from '../../utils/tailwind';
import { useT } from '../../utils/helper';
import { TouchableOpacity as Button } from '../forms';
import { useCartsMutations } from '../../api/hooks/vendor/carts/use-carts-mutations';
import { AirDatepickerInstance, WebDatePicker } from '../web/web-date-picker';
import { processPOSCommand } from '../../utils/print-module';
import { subDays } from 'date-fns';
import { useAlert } from '../../contexs/alert-context';
import { ModalFullBase } from './modal-full-base';

interface ModalPrintReceiptProp {
  isVisible: boolean;
  onModalHide: () => void;
}

export const ModalPrintReceipt = (props: ModalPrintReceiptProp) => {
  const t = useT();
  const [open, setOpen] = useState(false);
  const datePickerRef = useRef<AirDatepickerInstance>();
  const yesterday = subDays(new Date(), 1);
  const { printCartsSummary } = useCartsMutations();
  const alert = useAlert();

  const openCalender = () => {
    if (Platform.OS === 'web') {
      datePickerRef.current?.show();
    } else {
      setOpen(true);
    }
  };

  // Get printing command, execute all print jobs and close modal.
  const onPrintCartsSummary = async (date: Date) => {
    props.onModalHide();
    printCartsSummary.mutateAsync(date).then(data => {
      if (data.print_jobs.length) {
        processPOSCommand(data.print_jobs);
      }

      if (data.detail) {
        alert.setModalOptions({ contentWrapperClassNames: 'w-[600px]' });
        alert.show(data.detail);
      }
    });
  };

  return (
    <ModalFullBase
      isVisible={props.isVisible}
      backdropColor={tw.style('bg-white').backgroundColor.toString()}
      backdropOpacity={1}
      onModalHide={props.onModalHide}
      onBackdropPress={props.onModalHide}
      onBackButtonPress={props.onModalHide}>
      <Text style={tw`mb-6 text-xl text-center font-bold text-gray-800`}>
        {t('printDailyReceipt')}
      </Text>

      <Text style={tw`mb-6 text-center text-gray-500`}>
        {t('printFromWhichDate')}
      </Text>

      {/* Print buttons */}
      <View style={tw`gap-y-3 w-full`}>
        <Button
          label={t('today')}
          onPress={() => onPrintCartsSummary(new Date())}
          theme="gray"
        />
        <Button
          label={t('yesterday')}
          onPress={() => onPrintCartsSummary(yesterday)}
          theme="gray"
        />
        <Button
          label={t('differentDate')}
          onPress={() => openCalender()}
          theme="gray"
        />
      </View>

      {Platform.OS === 'web' ? (
        <WebDatePicker
          isMobile={true}
          ref={datePickerRef}
          autoClose={true}
          onSelect={value => onPrintCartsSummary(value.date as Date)} // value.date can be Date | Date[] but we use single date picker here
        />
      ) : (
        <DatePicker
          modal
          mode="date"
          open={open}
          date={new Date()}
          onConfirm={date => {
            setOpen(false);
            onPrintCartsSummary(date);
          }}
          onCancel={() => setOpen(false)}
        />
      )}
    </ModalFullBase>
  );
};
