import request from '../../../request';
import { ProductsResponse } from '../../../types/vendor/products-types';
import { useQuery } from '@tanstack/react-query';

export const useProductsQueries = () => {
  const fetchProducts = useQuery(
    ['products'],
    async () =>
      request({
        method: 'get',
        url: '/store/vendor/products.json',
      }) as Promise<ProductsResponse>,
  );

  return { fetchProducts };
};
