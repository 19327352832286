import React from 'react';
import { View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useT } from '../../../utils/helper';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';
import { TouchableOpacity as Button } from '../../forms';
import { SelectedLineItem } from '../../../contexs/payment-context';
import { useLineItemsMutation } from '../../../api/hooks/cash-register/order-sessions/use-line-items-mutation';
import { useAuth } from '../../../contexs/auth-context';
import { CartType } from '../../../api/types/cash-register/cart-types';
import { useQueryClient } from '@tanstack/react-query';

interface ModalRemoveItemsProps {
  isVisible: boolean;
  selectedLineItems: SelectedLineItem[];
  orderSessionId: number;
  cartType: CartType;
  onModalHide: () => void;
}

export const ModalRemoveItems = (props: ModalRemoveItemsProps) => {
  // Standard Hooks
  const t = useT('screens', { keyPrefix: 'OrderSessionEdit' });
  const queryClient = useQueryClient();
  // Queries
  const { currentCashier } = useAuth();
  const { removeItems } = useLineItemsMutation(props.orderSessionId);
  // Callbacks
  const handleRemoveItems = (line_items_returned: boolean) => {
    if (!currentCashier) {
      return;
    }

    removeItems
      .mutateAsync({
        line_items_returned: line_items_returned,
        cashier_id: currentCashier.id,
        cart_type: props.cartType,
        line_items: props.selectedLineItems.map(item => ({
          id: item.id,
          quantity: item.selectedQuantity,
        })),
      })
      .then(() => {
        queryClient.refetchQueries(['orderSessions', props.orderSessionId]);
      })
      .finally(props.onModalHide);
  };

  return (
    <ModalConfirmation
      isVisible={props.isVisible}
      content={t('wereProductsPrepared').toString()}
      onModalHide={props.onModalHide}
      hideCancel={true}>
      <View style={tw`gap-3 mb-3 w-full`}>
        <Button
          label={t('notPrepared')}
          disableOnPress={true}
          onPress={() => handleRemoveItems(false)}
          theme="white"
          extraStyle="py-5 bg-warning"
          extraLabelStyle="leading-none font-medium text-white"
        />
        <Button
          label={t('alreadyPrepared')}
          disableOnPress={true}
          onPress={() => handleRemoveItems(true)}
          theme="white"
          extraStyle="py-5 bg-error"
          extraLabelStyle="leading-none font-medium text-white"
        />
      </View>
    </ModalConfirmation>
  );
};
