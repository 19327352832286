import CryptoJS from 'crypto-js';
import env from '../env';

const convertHexToBase64 = (hexString: string): string => {
  const words = CryptoJS.enc.Hex.parse(hexString);
  return CryptoJS.enc.Base64.stringify(words);
};

export async function decryptSignInToken(
  encryptedSignInToken: string,
  iv: string,
) {
  const keyHex = CryptoJS.enc.Hex.parse(env.TERMINAL_SERIAL_ENCRYPTION_KEY);
  const ivHex = CryptoJS.enc.Hex.parse(iv);
  const encryptedHex = CryptoJS.enc.Base64.parse(
    convertHexToBase64(encryptedSignInToken),
  );
  const ciphertextString = CryptoJS.enc.Base64.stringify(encryptedHex);

  const decrypted = CryptoJS.AES.decrypt(ciphertextString, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}
