import { useEffect } from 'react';
import { useTenantsMutations } from '../../api/hooks/tenants/use-tenants-mutations';
import { useAuth } from '../../contexs/auth-context';
import env from '../../env';
import useActionCable from './use-action-cable';
import useChannel from './use-channel';
import { useTenantsQueries } from '../../api/hooks/tenants/use-tenants-queries';

export default function useOpeningStateChannel() {
  const { authData } = useAuth();
  const { actionCable } = useActionCable(
    `${env.WEBSOCKET_URL}?token=${authData?.token}`,
  );
  const { subscribe, unsubscribe } = useChannel(actionCable);
  const { setDisconnected } = useTenantsMutations();
  const { fetchTenantsDashboard } = useTenantsQueries();

  const refetchTenantsDashboard = () => {
    if (fetchTenantsDashboard.isStale) {
      fetchTenantsDashboard.refetch();
    }
  };

  useEffect(() => {
    subscribe(
      { channel: 'OpeningStateChannel' },
      {
        received: () => {
          // Set the screen brightness to normal.
          refetchTenantsDashboard();
        },
        connected: () => {
          // refetching when internet connected is handled by react-query.
          // this is to handle when server is restarted.
          refetchTenantsDashboard();
        },
        disconnected: () => {
          // Set the opening state to 'Getrennt'.
          setDisconnected();
        },
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);
}
