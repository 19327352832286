import { Platform } from 'react-native';
import { twCb } from './helper';
import { tw } from './tailwind';

export const getVendorHeaderBarStyle = (openingState: string) => {
  if (['paused', 'disconnected', 'closed'].includes(openingState)) {
    return 'bg-pink-700';
  } else if (['delivery_paused', 'pickup_paused'].includes(openingState)) {
    return 'bg-orange-600';
  } else {
    return 'bg-gray-900';
  }
};

export const getHeaderBarColorTheme = (openingState: string) => {
  if (['paused', 'disconnected', 'closed'].includes(openingState)) {
    return tw.color('pink-700');
  } else if (['delivery_paused', 'pickup_paused'].includes(openingState)) {
    return tw.color('orange-600');
  } else {
    return tw.color('gray-900');
  }
};

export const getModalBaseClasses = () => {
  return twCb(
    'pt-4 px-4 pb-6 bg-white rounded-2xl items-center shadow-lg max-w-full',
    Platform.OS === 'web' ? 'w-[428px] mx-auto' : '',
  );
};

export const getContainerClass = (width: number, innerPad?: number) => {
  const padding = width / 2 - 1360 / 2 - (innerPad || 0);
  const defaultPadd = innerPad ? '' : 'md:pl-6 md:pr-6 pl-4 pr-4';
  return `xl:pr-[${padding}px] xl:pl-[${padding}px] ${defaultPadd}`;
};
