import request from '../../../request';
import { useMutation } from '@tanstack/react-query';
import {
  CreateCashbookEntryParams,
  CreateCashbookEntryResponse,
  DestroyCashbookEntryResponse,
} from '../../../types/cash-register/cashbook-entry.types';

export const useCashbookEntryMutations = () => {
  const createCashbookEntry = useMutation(
    async (params: CreateCashbookEntryParams) =>
      request({
        method: 'post',
        url: '/store/cashbook_entries.json',
        params: {
          cashbook_entry: params,
        },
      }) as Promise<CreateCashbookEntryResponse>,
  );

  const removeCashbookEntry = useMutation(
    async (id: number) =>
      request({
        method: 'delete',
        url: `/store/cashbook_entries/${id}.json`,
      }) as Promise<DestroyCashbookEntryResponse>,
  );

  return { createCashbookEntry, removeCashbookEntry };
};
