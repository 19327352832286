import { useNavigation, useRoute } from '@react-navigation/native';
import { AppStackScreenProps } from '../../routes';

// This hook is used to navigate to category path based on the current screen and the selected category.
const useCategoryPath = (cartId: number | null) => {
  const navigation = useNavigation<AppStackScreenProps<'Root'>['navigation']>();
  const route = useRoute<AppStackScreenProps<'CartNew'>['route']>();
  const paramsCategoryId = route.params.product_category_id || null; // convert undefined to null here to easily compare with categoryId
  const inProductCategoryScreen = [
    'product_categories',
    'product_category_id',
  ].includes(window.location.pathname);

  const setCategoryPath = (categoryId: number | null) => {
    const navigateToCartNewScren = () => {
      navigation.navigate(
        'CartNew',
        Object.assign({}, route.params, {
          product_category_id: categoryId ?? undefined, // don't set params if null
        }),
      );
    };

    // If category set, navigate to cart category path
    // - existing cart: /cart/:cart_id/category/:product_categories
    // - new cart: /cart/new?params+product_category_id=:product_category_id
    // else navigate to cart path /cart/:cart_id | /cart/new
    if (
      categoryId &&
      paramsCategoryId !== categoryId &&
      !inProductCategoryScreen
    ) {
      if (cartId) {
        navigation.navigate(
          'CartShowCategory',
          Object.assign(route.params, {
            cart_id: cartId,
            product_category_id: categoryId,
          }),
        );
      } else {
        navigateToCartNewScren();
      }
    } else if (paramsCategoryId && !categoryId) {
      if (cartId) {
        navigation.navigate('CartShow', { cart_id: cartId });
      } else {
        navigateToCartNewScren();
      }
    }
  };

  return {
    setCategoryPath,
  };
};

export default useCategoryPath;
