import useWorkflowChannel from '../websocket/hooks/use-workflow-channel';

export interface WorkflowListenerProps {
  channel: string;
  onMessage: (message: any) => void;
}

export const WorkflowListener = (props: WorkflowListenerProps) => {
  useWorkflowChannel(props.channel, props.onMessage);

  return null;
};
