import React, { useState } from 'react';
import { InvoiceContext } from '../contexs/invoice-context';
import { Invoice } from '../api/types/cash-register/invoice.types';

interface InvoiceProviderProps {
  children: React.ReactNode;
}

export const InvoiceProvider = (props: InvoiceProviderProps) => {
  const [invoice, setInvoice] = useState<Invoice>();

  return (
    <InvoiceContext.Provider
      value={{
        invoice,
        setInvoice,
      }}>
      {props.children}
    </InvoiceContext.Provider>
  );
};
