import React, { useRef, useState } from 'react';
import {
  Platform,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import DatePicker from 'react-native-date-picker';
import { tw } from '../../utils/tailwind';
import { CalendarSVG } from '../../../assets/svg';
import { useDeliveredCartsQueries } from '../../api/hooks/vendor/carts/use-delivered-carts-queries';
import QuerySuspense from '../../api/query-suspense';
import { STANDARD_DATE_FORMAT, twCb, useT } from '../../utils/helper';
import { CartList } from '../../components/cart-list';
import {
  AirDatepickerInstance,
  WebDatePicker,
} from '../../components/web/web-date-picker';
import { useBackHandler } from '../../hooks/use-back-handler';
import { useTerminalSetting } from '../../contexs/terminal-setting-context';
import { getContainerClass } from '../../utils/conditional-style';
import { format } from 'date-fns';

export const DeliveredCartsScreen = () => {
  useBackHandler();

  const { store_app_type } = useTerminalSetting();
  const [date, setDate] = useState<Date>(new Date());
  const [open, setOpen] = useState(false);
  const { fetchDeliveredCarts } = useDeliveredCartsQueries(
    format(date, STANDARD_DATE_FORMAT),
  );
  const { width } = useWindowDimensions();
  const t = useT();
  const datePickerRef = useRef<AirDatepickerInstance>();
  const isNative = store_app_type === 'native';

  const openCalender = () => {
    if (Platform.OS === 'web') {
      datePickerRef.current?.show();
    } else {
      setOpen(true);
    }
  };

  return (
    <View style={tw`${getContainerClass(width, 16)}`}>
      {/* Date Picker */}
      <View style={tw`p-4 gap-y-4`}>
        <Text style={tw`text-2xl font-bold text-gray-900`}>
          {t('fullfilledOrders')}
        </Text>

        <TouchableOpacity
          onPress={openCalender}
          style={tw`flex-row justify-center items-center py-3 px-6 rounded-[10px] bg-blue-600`}>
          <Text style={tw`text-base text-white pr-3`}>{t('chooseADate')}</Text>
          <CalendarSVG style={tw`w-4 h-4`} stroke="white" />
        </TouchableOpacity>

        <View style={tw`flex-row justify-between items-center`}>
          <Text style={tw`text-lg font-medium text-gray-900`}>
            {t('ordersFrom')}
          </Text>
          <Text style={tw`text-lg font-bold text-gray-900`}>
            {date ? format(date, STANDARD_DATE_FORMAT) : '--.--.----'}
          </Text>
        </View>
      </View>

      {Platform.OS === 'web' ? (
        <WebDatePicker
          isMobile={true}
          ref={datePickerRef}
          autoClose={true}
          onSelect={value => setDate((value.date as Date) || new Date())} // value.date can be Date | Date[] but we use single date picker here
        />
      ) : (
        <DatePicker
          modal
          mode="date"
          open={open}
          date={date}
          onConfirm={selectedDate => {
            setOpen(false);
            setDate(selectedDate);
          }}
          onCancel={() => {
            setOpen(false);
          }}
        />
      )}

      {/* Cart List */}
      <QuerySuspense
        query={fetchDeliveredCarts}
        onSuccess={data => (
          <CartList
            carts={data}
            refreshing={fetchDeliveredCarts.isRefetching}
            onRefresh={fetchDeliveredCarts.refetch}
            classNames={twCb(isNative ? 'px-4 mb-45' : 'px-2')}
          />
        )}
      />
    </View>
  );
};
