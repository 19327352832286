import React, { useState } from 'react';
import { ModalTakeaway } from '../../../components/cash-register/modals/modal-takeaway';
import { ModalCustomerForm } from '../../../components/cash-register/modals/modal-customer-form';
import { tw } from '../../../utils/tailwind';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { useT } from '../../../utils/helper';
import { TouchableOpacity } from 'react-native';
import { PlusSvg } from '../../../../assets/svg';

enum ModalVisible {
  Takeaway,
  PickupCustomerForm,
  DeliveryCustomerForm,
  None,
}

export const TakeawayButton = () => {
  const t = useT('cashRegister');
  const navigation =
    useNavigation<AppStackScreenProps<'StoreArea'>['navigation']>();

  // States
  const [modalVisible, setModalVisible] = useState(ModalVisible.None);

  return (
    <>
      <TouchableOpacity
        onPress={() => setModalVisible(ModalVisible.Takeaway)}
        activeOpacity={1}
        style={tw`justify-center items-center md:min-w-[140px] md:min-h-[140px] min-w-[128px] min-h-[128px] rounded-2xl gap-y-2.5 p-3 bg-gray-100`}>
        <PlusSvg width={24} height={24} strokeWidth={3} />
      </TouchableOpacity>

      {/* Modals */}
      <ModalTakeaway
        isVisible={modalVisible === ModalVisible.Takeaway}
        onModalHide={() => setModalVisible(ModalVisible.None)}
        onCartTypeSelected={cartType =>
          setModalVisible(
            cartType === 'pickup'
              ? ModalVisible.PickupCustomerForm
              : ModalVisible.DeliveryCustomerForm,
          )
        }
      />

      <ModalCustomerForm
        isVisible={[
          ModalVisible.PickupCustomerForm,
          ModalVisible.DeliveryCustomerForm,
        ].includes(modalVisible)}
        contentContainerStyle={tw`md:max-w-[592px] w-full max-w-[432px]`}
        submitButtonText={t('proceedToOrder').toString()}
        customer={null}
        isAddressRequired={modalVisible === ModalVisible.DeliveryCustomerForm}
        onModalHide={() => setModalVisible(ModalVisible.None)}
        onSubmitSuccess={customerId =>
          navigation.navigate('CartNew', {
            cart_type: 'delivery',
            customer_id: customerId,
          })
        }
      />
    </>
  );
};
