import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import { InvoiceResponse } from '../../../types/cash-register/invoice.types';

export const useInvoiceQueries = (id: number) => {
  const fetchInvoice = useQuery(
    ['invoices', id],
    async () =>
      request({
        method: 'get',
        url: `/store/invoices/${id}.json`,
      }) as Promise<InvoiceResponse>,
  );

  return { fetchInvoice };
};
