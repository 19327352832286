import React from 'react';
import { LineItemStatus } from '../../../api/types/cash-register/line-item-types';
import { OrderLineItem } from '../../../api/types/cash-register/order-types';
import { SummmaryCardItem } from './summary-card-item';
import { Text, View } from 'react-native';
import { tw } from '../../../utils/tailwind';

interface SummaryCardItemGroupProps {
  status: LineItemStatus;
  lineItems: OrderLineItem[];
  headline?: string;
}

export const SummaryCardItemGroup = (props: SummaryCardItemGroupProps) => {
  const lineItemsByStatus = props.lineItems.filter(
    lineItem => lineItem.status === props.status,
  );

  if (lineItemsByStatus.length < 1) {
    return null;
  }

  return (
    <View style={tw`gap-y-3`}>
      {props.headline ? (
        <Text style={tw`text-lg leading-none font-medium text-gray-800`}>
          {props.headline}:
        </Text>
      ) : null}

      <View style={tw`gap-y-[18px]`}>
        {lineItemsByStatus.map((lineItem, index) => (
          <SummmaryCardItem key={index} lineItem={lineItem} />
        ))}
      </View>
    </View>
  );
};
