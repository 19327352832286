import React, { forwardRef, Ref, useState } from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../../../utils/tailwind';
import { LineItemOptionButton } from './line-item-option-button';
import {
  Ingredient,
  ProductSize,
} from '../../../../api/types/cash-register/product-category-types';
import { FormattedBundleOptionType } from '../../../../utils/cash-register/line-item-form.helper';
import { twCb } from '../../../../utils/helper';
import { lineItemOptionHelper } from '../../../../utils/helpers/line-item-option.helper';

export type OptionType =
  | ProductSize
  | FormattedBundleOptionType
  | Ingredient
  | any;
export interface LineItemOptionProps {
  options: OptionType[];
  title: string;
  type: 'radio' | 'checkbox';
  required?: boolean;
  canInputQuantity?: boolean;
  initialValue?: any;
  isError?: boolean;
  onSelect: (option: OptionType) => void;
}

// IMPORTANT: checkbox isn't compatible with `required` yet,
// since there's no use case for it at the moment
export const LineItemOption = forwardRef(
  (props: LineItemOptionProps, ref: Ref<View>) => {
    // Hooks
    const [selectedOptions, setSelectedOptions] = useState(
      formatInitialValueByType(props.type, props.initialValue),
    );
    // Functions
    const helper = lineItemOptionHelper(props, selectedOptions);
    // Callbacks
    const onButtonPress = (option: OptionType, quantity: number) => {
      let values: OptionType[] = [];

      if (props.canInputQuantity) {
        option.quantity = quantity;
      }

      // If canInputQuantity is true, when we click on the option again
      // instead of deselecting the option, we just increase the quantity
      if (helper.shouldIncrementQuantity(option, quantity)) {
        const newOptions = helper.updateQuantity(
          selectedOptions,
          option,
          quantity,
        );
        helper.notifyParentComponent(newOptions);
        return;
      }

      values = helper.determineSelectedOptions(option);
      setSelectedOptions(values);
      helper.notifyParentComponent(values);
    };

    return (
      <View ref={ref} style={tw`gap-y-2`}>
        <Text
          style={tw`${twCb(
            'md:text-xl text-lg leading-none font-medium',
            props.isError ? 'text-error' : 'text-gray-800',
          )}`}>
          {props.title} {props.required ? '*' : ''}
        </Text>
        <View style={tw`flex-row flex-wrap gap-2`}>
          {props.options.map((option, index) => (
            <LineItemOptionButton
              key={index}
              label={option.name}
              disabled={option.disabled}
              canInputQuantity={!!props.canInputQuantity}
              active={helper.isActive(option)}
              isError={props.isError}
              initialQuantity={helper.getInitialQuantity(option)}
              onPress={quantity => onButtonPress(option, quantity)}
            />
          ))}
        </View>
      </View>
    );
  },
);

const formatInitialValueByType = (
  type: 'radio' | 'checkbox',
  initialValue: any,
) => {
  if (!initialValue) {
    return [];
  }

  // If it's a checkbox, initialValue should be already an array
  return type === 'checkbox' ? initialValue : [initialValue];
};
