import React from 'react';
import { View, Text, ScrollView, useWindowDimensions } from 'react-native';
import { CashierList } from './cashier-list';
import { useT } from '../../../../utils/helper';
import { tw } from '../../../../utils/tailwind';
import { Cashier } from '../../../../api/types/cash-register/cashier-types';

interface CashierSelectionViewProps {
  cashiers: Cashier[];
  onSelect: (cashier: Cashier) => void;
}

export const CashierSelectionView = (props: CashierSelectionViewProps) => {
  // Hooks
  const t = useT();
  const { height } = useWindowDimensions();

  return (
    <View style={tw`w-full gap-y-4`}>
      <Text style={tw`text-[28px] font-medium leading-9 text-gray-800`}>
        {t('cashier')}
      </Text>
      <View style={tw`rounded-xl overflow-hidden border border-gray-400`}>
        <ScrollView style={tw`h-[${height - 200}px] rounded-xl`}>
          <CashierList
            cashiers={props.cashiers}
            onPressCashier={props.onSelect}
          />
        </ScrollView>
      </View>
    </View>
  );
};
