import React from 'react';
import { View } from 'react-native';
import { ModalConfirmation } from '../shared/modals/modal-confirmation';
import { useT } from '../../utils/helper';

interface OnboardingPopupProps {
  onConfirm: () => void;
}

export const OnboardingPopup = (props: OnboardingPopupProps) => {
  const t = useT();

  return (
    <View>
      <ModalConfirmation
        onConfirm={() => props.onConfirm()}
        onModalHide={() => props.onConfirm()}
        content={t('readyForBattle?')}
        confirmText={t('yesIAm').toString()}
        hideCancel={true}
        isVisible={true}
      />
    </View>
  );
};
