import React from 'react';
import { TouchableOpacity, TouchableOpacityProps, Text } from 'react-native';
import { tw } from '../../utils/tailwind';
import { L1Keys } from './select-list';

interface SelectListOptionProps extends TouchableOpacityProps {
  option: L1Keys;
}

export const SelectListOption = (props: SelectListOptionProps) => {
  return (
    <TouchableOpacity id={`select-list-item-${props.option.key}`} {...props}>
      <Text style={tw`text-lg`}>{props.option.value}</Text>
    </TouchableOpacity>
  );
};
