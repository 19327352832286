import React from 'react';
import { tw } from '../../../utils/tailwind';
import { View, Text, ScrollView } from 'react-native';
import { Product } from '../../../api/types/cash-register/product-category-types';
import { useT } from '../../../utils/helper';
import { ProductTile } from './product-tile';

interface ProductInputProps {
  products: Product[];
  mode: 'default' | 'embed';
  scrollClassName?: string;
  onSelect: (product: Product) => void;
}

export const ProductInput = (props: ProductInputProps) => {
  const t = useT();

  return (
    <View style={tw`gap-y-4`}>
      {props.mode === 'default' ? (
        <Text
          style={tw`md:text-xl text-lg leading-none font-semibold text-gray-800`}>
          {t('products')}
        </Text>
      ) : null}
      <ScrollView
        style={tw`h-[392px] ${props.scrollClassName || ''}`}
        contentContainerStyle={tw`flex-row flex-wrap gap-4 items-start`}>
        {props.products.map((product, i) => (
          <ProductTile
            key={i}
            text={product.name}
            mode={props.mode}
            onPress={() => props.onSelect(product)}
          />
        ))}
      </ScrollView>
    </View>
  );
};

ProductInput.defaultProps = {
  mode: 'default',
};
