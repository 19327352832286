import React from 'react';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';
import { View } from 'react-native';
import { TouchableOpacity as Button } from '../../forms';
import { formatCurrency, useT } from '../../../utils/helper';
import { tw } from '../../../utils/tailwind';
import { FiscalTransactionPayment } from '../../../api/types/cash-register/fiscal-transaction-payment.types';
import { InvoiceInfoRow } from '../../../screens/cash-register/invoices/invoice-info-row';
import { useFiscalTransactionPaymentMutations } from '../../../api/hooks/cash-register/fiscal-transaction-payments/use-fiscal-transaction-payment-mutations';
import { useAuth } from '../../../contexs/auth-context';
interface ModalRemovePaymentProps {
  invoiceId: number;
  payment: FiscalTransactionPayment | null;
  isVisible: boolean;
  onModalHide: () => void;
  onPaymentRemoved: () => void;
}

export const ModalRemovePayment = (props: ModalRemovePaymentProps) => {
  const t = useT();
  const { currentCashier } = useAuth();
  const { destroyFiscalPaymentTransaction } =
    useFiscalTransactionPaymentMutations(props.invoiceId);

  const handleRemovePayment = () => {
    if (props.payment === null || !currentCashier) {
      return;
    }

    destroyFiscalPaymentTransaction
      .mutateAsync({
        id: props.payment.id,
        fiscal_transaction_payment: { cashier_id: currentCashier.id },
      })
      .then(() => {
        props.onPaymentRemoved();
      })
      .finally(() => {
        props.onModalHide();
      });
  };

  if (props.payment === null) {
    return null;
  }

  return (
    <ModalConfirmation
      isVisible={props.isVisible}
      content={t('edit').toString()}
      onModalHide={props.onModalHide}>
      <View style={tw`gap-3 my-3 w-full`}>
        {/* payment method and amount */}
        <View style={tw`bg-gray-100 rounded-md p-5`}>
          <InvoiceInfoRow
            label={props.payment.payment_method}
            value={formatCurrency(props.payment.amount)}
          />
        </View>
        {/* delete button */}
        <Button
          label={t('delete')}
          disableOnPress={true}
          onPress={() => handleRemovePayment()}
          theme="red"
          extraStyle={'py-5 bg-error'}
          extraLabelStyle="leading-none font-medium text-white"
        />
      </View>
    </ModalConfirmation>
  );
};
