import React from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../utils/tailwind';
import { LineItem as LinteItemInterface } from '../../api/types/vendor/carts-types';
import { useT } from '../../utils/helper';
import { LineItemIngredient } from './line-item-ingredient';
import { TotalDeposit } from './total-deposit';

interface LineItemProps {
  item: LinteItemInterface;
}

export const LineItem = (props: LineItemProps) => {
  const t = useT();
  const {
    name,
    quantity,
    price,
    ingredients_as_string,
    excluded_ingredients_as_string,
    total_deposit,
    comment,
  } = props.item;

  return (
    <View style={tw`gap-y-2.5`}>
      <View style={tw`flex-row justify-between gap-x-3`}>
        <View style={tw`flex-row justify-start gap-x-1 shrink-1 grow-1`}>
          <Text style={tw`xl:text-lg text-base font-bold text-gray-900`}>
            {quantity}
          </Text>
          <Text style={tw`xl:text-lg text-base font-medium text-gray-900`}>
            {t('amountX')}
          </Text>
          <View style={tw`grow-1 shrink-1`}>
            <Text style={tw`xl:text-lg text-base font-medium text-gray-900`}>
              {name}
            </Text>

            {total_deposit > 0 ? (
              <TotalDeposit total_deposit={total_deposit} />
            ) : null}
            {ingredients_as_string ? (
              <LineItemIngredient ingredient={ingredients_as_string} />
            ) : null}
            {excluded_ingredients_as_string ? (
              <LineItemIngredient ingredient={excluded_ingredients_as_string} />
            ) : null}
            {comment ? (
              <Text style={tw`font-bold text-gray-900`}>{comment}</Text>
            ) : null}
          </View>
        </View>
        <Text
          style={tw`xl:text-lg xl:pr-0 text-base font-medium text-right text-gray-900 grow-0`}>
          {price}
        </Text>
      </View>
    </View>
  );
};
