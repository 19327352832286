import request from '../../../request';
import { useMutation } from '@tanstack/react-query';
import {
  UnlockCashierErrorResponse,
  UnlockCashierParams,
} from '../../../types/cash-register/cashier-types';

export const useCashierMutations = (cashierId: number) => {
  const unlockCashier = useMutation(
    async ({ pin }: UnlockCashierParams) =>
      request({
        method: 'put',
        url: `/store/cashiers/${cashierId}/unlock.json`,
        params: { pin },
      }) as Promise<UnlockCashierErrorResponse | null>,
  );

  return { unlockCashier };
};
