import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { TransactionSummaryCard } from '../../../components/cash-register/transaction/transaction-summary-card';
import { tw } from '../../../utils/tailwind';
import { InvoiceSummaryActionButtons } from './invoice-summary-action-buttons';
import { useInvoicesQueries } from '../../../api/hooks/cash-register/invoices/use-invoices-queries';
import { InvoicesParams } from '../../../api/types/cash-register/invoice.types';
import { InvoiceProvider } from '../../../providers/invoice-provider';
import { ModalCancelInvoice } from '../../../components/cash-register/modals/modal-cancel-invoice';
import { useWindowSize } from '../../../hooks/use-window-size';
import { useWorkflowListener } from '../../../contexs/workflow-listener-context';

import { useTenant } from '../../../contexs/tenant-context';
import { FlatListPagination } from '../../../components/flat-list-pagination';

enum ModalVisible {
  CancelInvoice = 'CancelInvoice',
  None = 'none',
}

interface InvoiceListProps extends InvoicesParams {}

export const InvoiceList = (props: InvoiceListProps) => {
  const { windowSize } = useWindowSize();
  const workflowListener = useWorkflowListener();
  const { tenant } = useTenant();
  // Queries
  const { fetchInvoices } = useInvoicesQueries({
    q: props.q,
    sort_by: 'created_at desc',
  });
  // States
  const [modalVisible, setModalVisible] = useState<ModalVisible>();

  const isXl = windowSize === 'xl';

  useEffect(() => {
    // when the cancellation workflow is completed, refetch the invoices
    workflowListener.listen('CashRegisterCancellationWorkflow', message => {
      if (message.tenant_id === tenant.id) {
        if (modalVisible === ModalVisible.CancelInvoice) {
          setModalVisible(ModalVisible.None);
        }

        fetchInvoices.refetch();
      }
    });
  }, []);

  return (
    <InvoiceProvider>
      <FlatListPagination
        key={isXl ? 'xl' : 'sm'}
        query={fetchInvoices}
        refreshing={fetchInvoices.isRefetching}
        collectionName="invoices"
        keyExtractor={(item, _index) => item.id.toString()}
        renderItem={({ item }) => (
          <View key={item.id} style={tw`lg:basis-[49.2%]`}>
            <TransactionSummaryCard
              document={item}
              actionButton={() => (
                <InvoiceSummaryActionButtons
                  invoice={item}
                  onCancel={() => setModalVisible(ModalVisible.CancelInvoice)}
                />
              )}
            />
          </View>
        )}
        ItemSeparatorComponent={() => <View style={tw`h-[18px]`} />}
        numColumns={isXl ? 2 : undefined}
        columnWrapperStyle={isXl ? tw`gap-[18px]` : null}
      />

      {/* Modals */}
      <ModalCancelInvoice
        isVisible={modalVisible === ModalVisible.CancelInvoice}
        onModalHide={() => setModalVisible(ModalVisible.None)}
      />
    </InvoiceProvider>
  );
};
