import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

export const useMessageHandler = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    document.addEventListener('message', onMessage);

    return () => {
      document.removeEventListener('message', onMessage);
    };
  }, []);

  const onMessage = (event: any) => {
    // Handle the received message
    const messageData = event.data;
    if (messageData.type === 'appState') {
      // Handle the message data as needed
      if (messageData.payload === 'active') {
        queryClient.refetchQueries({ type: 'active' });
      }
    }
  };
};
