import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useWorkflowListener } from '../contexs/workflow-listener-context';

export const useInvoiceWorkflowListener = (invoiceId: number) => {
  const queryClient = useQueryClient();
  const workflowListener = useWorkflowListener();

  useEffect(() => {
    workflowListener.listen('DeleteInvoicePaymentWorkflow', message => {
      if (message.invoice_id === invoiceId) {
        // Refetch the invoice and its payments
        queryClient.refetchQueries(['invoices', invoiceId]);
        queryClient.refetchQueries([
          'invoices',
          invoiceId,
          'fiscalTransactionPayments',
        ]);
      }
    });

    workflowListener.listen('CreateInvoicePaymentWorkflow', message => {
      // Intentionally log the message to fix hard to reproduce bug
      console.log('CreateInvoicePaymentWorkflow', message);

      if (message.invoice_id === invoiceId) {
        // Refetch the invoice
        queryClient.refetchQueries(['invoices', invoiceId]);

        if (message.invoice_fully_paid) {
          // Refetch the store areas to update table's order unprocessed amount
          queryClient.invalidateQueries(['storeAreas']);
        }
      }
    });
  }, [invoiceId]);
};
