import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { QuantityInput } from '../../../components/shared/quantity-input';
import { LineItem } from '../../../api/types/cash-register/order-session.types';
import { useT } from '../../../utils/helper';

interface OrderSessionItemProps {
  item: LineItem;
  selected: boolean;
  maxQuantity: number;
  onPress: (id: number) => void;
  onQuantityChange: (quantity: number) => void;
}

export const OrderSessionItem = (props: OrderSessionItemProps) => {
  const t = useT('screens', { keyPrefix: 'OrderSessionEdit' });

  return (
    <TouchableOpacity
      style={[
        tw`flex-row justify-between items-center gap-x-3 py-2 px-[18px] border-0 border-t border-solid border-gray-100`,
        props.selected ? tw`bg-success` : tw`bg-gray-50`,
      ]}
      disabled={props.maxQuantity < 1}
      onPress={() => props.onPress(props.item.id)}>
      <Text
        style={[
          tw`text-base leading-none font-medium text-gray-800`,
          props.selected ? tw`text-white` : tw`text-gray-800`,
        ]}>
        {props.item.name}
      </Text>
      <View
        style={tw`flex-row overflow-hidden rounded-lg border border-gray-100 bg-white w-[118px] max-h-[34px]`}>
        {props.maxQuantity > 0 ? (
          <QuantityInput
            size="xs"
            max={props.maxQuantity}
            min={0}
            value={props.item.quantity}
            displayMax={true}
            preventButtonFromDisabled={true}
            onChange={quantity => props.onQuantityChange(quantity)}
          />
        ) : (
          <Text style={tw`text-center`}>{t('itemRemovedAndOrReturned')}</Text>
        )}
      </View>
    </TouchableOpacity>
  );
};
