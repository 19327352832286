import React, { useState } from 'react';
import { useT } from '../../../utils/helper';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { CustomerList } from './customer-list';
import { ModalCustomerForm } from '../../../components/cash-register/modals/modal-customer-form';
import { tw } from '../../../utils/tailwind';
import { View } from 'react-native';
import { Customer } from '../../../api/types/cash-register/customer-types';
import { Input } from '../../../components/cash-register/forms/input';
import { SearchSvg } from '../../../../assets/svg';

export const CustomerIndexScreen = () => {
  // Hooks
  const t = useT();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [query, setQuery] = useState<string>();
  const [isModalCustomerFormVisible, setIsModalCustomerFormVisible] =
    useState(false);

  // Callbacks
  const openCustomerFormModal = (initialCustomerAttributes?: Customer) => {
    setCustomer(initialCustomerAttributes ?? null);
    setIsModalCustomerFormVisible(true);
  };

  return (
    <>
      <ScreenContainer
        title={t('customers').toString()}
        scrollable={true}
        containerStyle={tw`gap-y-[18px]`}
        headerWrapperStyle={tw`w-full m-auto max-w-[800px]`}>
        <View style={tw`w-full m-auto max-w-[800px] gap-y-[18px]`}>
          <>
            <Input
              placeholder={t('search').toString()}
              onChangeText={setQuery}
              rightIcon={
                <SearchSvg
                  style={tw`w-[20px] h-[20px]`}
                  stroke={tw.color('gray-400')}
                  strokeWidth={1.5}
                />
              }
            />
            <CustomerList
              query={query}
              onEditCustomer={openCustomerFormModal}
            />
          </>
        </View>
      </ScreenContainer>

      <FooterBar>
        <FooterBarItem
          active={true}
          text={t('addNewCustomer')}
          onPress={() => openCustomerFormModal()}
        />
      </FooterBar>

      {/* Modals */}
      <ModalCustomerForm
        isVisible={isModalCustomerFormVisible}
        customer={isModalCustomerFormVisible ? customer : null} // Reset customer on modal hide
        isAddressRequired={false}
        onModalHide={() => setIsModalCustomerFormVisible(false)}
      />
    </>
  );
};
