import request from '../../../request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Cart,
  CartResponse,
  PrintReceiptResponse,
  SetOrderReceivedResponse,
} from '../../../types/vendor/carts-types';
import { compareCartsFunction } from '../../../services/compare-carts-function';

export const useCartsMutations = () => {
  const queryClient = useQueryClient();

  const updateQueryData = (
    cart: Cart | Partial<Cart>,
    isPartialCart?: boolean,
  ) => {
    return queryClient.setQueryData(
      ['carts'],
      (prevCarts: Cart[] | undefined) => {
        let newCarts: Cart[] | undefined;
        const isCartNew = !prevCarts?.find(prevCart => prevCart.id === cart.id);
        // Removed delivered later, after tmp fix for the issue is removed
        if (
          ['completed', 'canceled', 'delivered'].includes(cart.status || '')
        ) {
          // remove completed and cancelled cart from the list
          newCarts = prevCarts?.filter(prevCart => prevCart.id !== cart.id);
        } else if (isCartNew && !isPartialCart) {
          // Add new cart to the list.
          const newCart = cart as Cart;
          newCarts = prevCarts ? [...prevCarts, newCart] : [newCart];
        } else {
          // update old cart with new data
          newCarts = prevCarts?.map(prevCart =>
            prevCart.id === cart.id
              ? Object.assign({}, prevCart, cart)
              : prevCart,
          );
        }

        const sortedCart = newCarts?.sort(compareCartsFunction);

        return sortedCart;
      },
    );
  };

  const updateCart = useMutation(
    async (url: string) =>
      request({
        method: 'put',
        url,
      }) as Promise<null | CartResponse>,
    {
      onSuccess: data => {
        // some endpoints returns head :ok instead of json
        if (data) {
          updateQueryData(data);
        }
      },
    },
  );

  const setAllDone = useMutation(
    async () =>
      request({
        method: 'put',
        url: '/store/vendor/carts/set_all_done.json',
      }) as Promise<null>,
    { onSuccess: () => queryClient.invalidateQueries(['carts']) },
  );

  const printCartsSummary = useMutation(
    async (startDate: Date) =>
      request({
        method: 'get',
        url: '/store/vendor/orders/print_orders_summary.json',
        params: { start_date: startDate, validate_printer_configuration: true }, // remove "validate_printer_configuration" params when all clients are updated
      }) as Promise<PrintReceiptResponse>,
  );

  const printReceipt = useMutation(
    async (url: string) =>
      request({
        method: 'get',
        url,
        params: { validate_printer_configuration: true }, // remove "validate_printer_configuration" params when all clients are updated
      }) as Promise<PrintReceiptResponse>,
  );

  const setOrderReceived = useMutation(
    async (cartId: number) =>
      request({
        method: 'put',
        url: `/store/vendor/carts/${cartId}/set_order_received.json`,
      }) as Promise<SetOrderReceivedResponse>,
    {
      onSuccess: cart => {
        // only update query data when order_received_by_vendor_at is changed
        if (cart.order_received_by_vendor_at) {
          updateQueryData(cart, true);
        }
      },
      onError: () => {
        // if error, set order_received_by_vendor_at to current time
        queryClient.setQueryData(['carts'], (prevCarts: Cart[] | undefined) => {
          return prevCarts?.map(prevCart =>
            !prevCart.order_received_by_vendor_at
              ? Object.assign({}, prevCart, {
                  order_received_by_vendor_at: new Date(),
                })
              : prevCart,
          );
        });
      },
    },
  );

  return {
    updateCart,
    setAllDone,
    printCartsSummary,
    printReceipt,
    setOrderReceived,
    updateQueryData,
  };
};
