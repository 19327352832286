import React from 'react';
import { View } from 'react-native';
import { BackSpaceSVG } from '../../../../assets/svg';
import { NumericInputPadButton } from './numeric-input-pad-button';
import { tw } from '../../../utils/tailwind';

interface NumericInputPadProps {
  numbers: string;
  onChange: (input: string) => void;
}

export const NumericInputPad = (props: NumericInputPadProps) => {
  return (
    <View
      style={tw`flex-row flex-wrap xs:m-w-[290px] m-w-[260px] gap-2.5 justify-end m-auto`}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(number => (
        <NumericInputPadButton
          key={number}
          content={number.toString()}
          onPress={() => props.onChange(`${props.numbers}${number}`)}
          style={tw`basis-[29%]`}
        />
      ))}
      <NumericInputPadButton
        content={<BackSpaceSVG style={tw`w-10 h-[33px]`} />}
        onPress={() => props.onChange(props.numbers.slice(0, -1))}
      />
    </View>
  );
};
