import React from 'react';
import { View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { Customer } from '../../../api/types/cash-register/customer-types';
import { TextWithHighlight } from '../shared/text-with-highlight';

interface CustomerListItemProps {
  highlight?: string;
  customer: Customer;
}

export const CustomerListItem = (props: CustomerListItemProps) => {
  // Renderer
  const renderCustomerInfo = (info: string | null) => {
    return (
      <Text
        style={tw`md:text-lg md:leading-none text-base leading-none text-gray-800`}>
        <TextWithHighlight
          highlight={props.highlight ?? ''}
          style={tw`md:text-lg md:leading-none text-base leading-none text-gray-800`}>
          {info || ''}
        </TextWithHighlight>
      </Text>
    );
  };

  return (
    <View style={tw`gap-y-1.5"`}>
      <TextWithHighlight
        highlight={props.highlight ?? ''}
        style={[
          tw`md:text-xl md:leading-none text-lg leading-none text-gray-800`,
          props.highlight ? tw`font-base` : tw`font-medium`,
        ]}>
        {props.customer.name}
      </TextWithHighlight>

      {renderCustomerInfo(props.customer.phone)}
      {renderCustomerInfo(props.customer.address)}
    </View>
  );
};
