import { NavigationContainer } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import React, { useRef } from 'react';
import { useAuth } from '../contexs/auth-context';
import SplashScreen from '../screens/splash/splash.screen';
import { linking } from './linking';
import { Platform } from 'react-native';
import { AuthStackNavigator } from './auth-stack-navigator';
import { ModuleNavigator } from './module-navigator';
import { DefaultHeaderTitle } from '../utils/constants';

const MainNavigator = () => {
  // Create a ref for the navigation container
  const navigation = useRef();
  const { status: authStatus } = useAuth();

  const onReady = () => {
    if (Platform.OS !== 'web') {
      // Register the navigation container with the instrumentation
      const routingInstrumentation =
        new Sentry.ReactNavigationInstrumentation();
      routingInstrumentation.registerNavigationContainer(navigation);
    }
  };

  if (authStatus === 'IDLE') {
    return <SplashScreen />;
  }

  return (
    <NavigationContainer
      linking={linking}
      onReady={onReady}
      documentTitle={{
        formatter: (options, _route) => options?.title ?? DefaultHeaderTitle,
      }}>
      {authStatus === 'SIGNED_IN' ? (
        <ModuleNavigator />
      ) : (
        <AuthStackNavigator />
      )}
    </NavigationContainer>
  );
};

export default MainNavigator;
