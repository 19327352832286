import React from 'react';
import {
  TableSVG,
  SportsSVG,
  ClockSVG,
  FoodSVG,
  CashSVG,
  MailSVG,
  PhoneSVG,
  UserSVG,
  AddressSVG,
} from '../../../assets/svg';
import { TextWithSVG } from '../text-with-svg';
import { twCb } from '../../utils/helper';
import { tw } from '../../utils/tailwind';
import { PAYMENT_METHODS } from '../../api/types/payment-method.types';

interface OrderInfo {
  name: string;
  phone: string;
  email: string;
  payment_method: PAYMENT_METHODS;
  expected_time: string | null;
  store_table_name: string;
  pickup_time: string | null;
  number_of_orders: string;
  address: string | null;
}

interface CartOrderInfoProps {
  info: OrderInfo;
}

export const CartOrderInfo = (props: CartOrderInfoProps) => {
  const {
    name,
    phone,
    email,
    payment_method,
    expected_time,
    store_table_name,
    pickup_time,
    number_of_orders,
  } = props.info;

  return (
    <>
      {pickup_time ? (
        <TextWithSVG
          svg={
            <SportsSVG
              stroke={tw.color('black')}
              strokeWidth="2.5"
              fill="transparent"
            />
          }
          textStyle="font-bold">
          {pickup_time}
        </TextWithSVG>
      ) : null}

      {/* Set to bold if there is no pickup time */}
      {expected_time ? (
        <TextWithSVG
          svg={
            <ClockSVG
              strokeWidth={pickup_time ? '1.6' : '2.5'}
              stroke={pickup_time ? tw.color('gray-500') : tw.color('black')}
              fill="transparent"
            />
          }
          textStyle={twCb(pickup_time ? '' : 'font-bold')}>
          {expected_time}
        </TextWithSVG>
      ) : null}

      <TextWithSVG svg={<UserSVG />}>{name}</TextWithSVG>

      {/* address */}
      {props.info.address ? (
        <TextWithSVG svg={<AddressSVG stroke={tw.color('gray-500')} />}>
          {props.info.address}
        </TextWithSVG>
      ) : null}

      <TextWithSVG svg={<PhoneSVG />}>{phone}</TextWithSVG>
      <TextWithSVG svg={<MailSVG />}>{email}</TextWithSVG>
      <TextWithSVG svg={<CashSVG />}>{payment_method}</TextWithSVG>

      {number_of_orders ? (
        <TextWithSVG svg={<FoodSVG stroke={tw.color('gray-500')} />}>
          {number_of_orders}
        </TextWithSVG>
      ) : null}

      {store_table_name ? (
        <TextWithSVG svg={<TableSVG stroke={tw.color('gray-500')} />}>
          {store_table_name}
        </TextWithSVG>
      ) : null}
    </>
  );
};
