import React from 'react';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';
import { View } from 'react-native';
import { TouchableOpacity as Button } from '../../forms';
import { useT } from '../../../utils/helper';
import { tw } from '../../../utils/tailwind';
import { CashbookEntry } from '../../../screens/cash-register/cashbook/cashbook-entry';
import { CashbookEntry as CashbookEntryType } from '../../../api/types/cash-register/cashbook-entry.types';
import { format } from 'date-fns';
import { useCashbookEntryMutations } from '../../../api/hooks/cash-register/cashbook-entries/use-cashbook-entry-mutations';
import { useQueryClient } from '@tanstack/react-query';

interface ModalRemoveCashbookEntryProps {
  entry: CashbookEntryType;
  isVisible: boolean;
  onModalHide: () => void;
}

export const ModalRemoveCashbookEntry = (
  props: ModalRemoveCashbookEntryProps,
) => {
  const cashbookT = useT('screens', { keyPrefix: 'Cashbook' });
  const t = useT('common');

  const queryClient = useQueryClient();
  const { removeCashbookEntry } = useCashbookEntryMutations();

  return (
    <ModalConfirmation
      isVisible={props.isVisible}
      content={cashbookT('removeEntryConfirmation', {
        date: format(props.entry.created_at, 'dd'),
      }).toString()}
      onModalHide={props.onModalHide}>
      <View style={tw`gap-3 my-3 w-full`}>
        {/* payment method and amount */}
        <View style={tw`bg-gray-100 rounded-md p-2`}>
          <CashbookEntry entry={props.entry} />
        </View>

        {/* delete button */}
        <Button
          label={t('delete')}
          disableOnPress={true}
          onPress={() => {
            removeCashbookEntry.mutateAsync(props.entry.id).then(() => {
              props.onModalHide();
              queryClient.refetchQueries(['cashbookEntries']);
            });
          }}
          theme="red"
          extraStyle={'py-5 bg-error'}
          extraLabelStyle="leading-none font-medium text-white"
        />
      </View>
    </ModalConfirmation>
  );
};
