import request from '../../../request';
import { useMutation } from '@tanstack/react-query';
import {
  OrderSessionMoveItemsParams,
  OrderSessionMoveItemsResponse,
  OrderSessionRemoveItemsParams,
} from '../../../types/cash-register/order-session.types';
import { useOrderMutations } from '../orders/use-order-mutations';

export const useLineItemsMutation = (orderSessionId: number) => {
  const { onOrderMutationSuccess } = useOrderMutations();

  const moveItems = useMutation(
    async (data: OrderSessionMoveItemsParams) =>
      request({
        method: 'put',
        url: `/store/order_sessions/${orderSessionId}/line_items/move.json`,
        data: data,
      }) as Promise<OrderSessionMoveItemsResponse>,
    {
      onSuccess: () => {
        onOrderMutationSuccess(orderSessionId);
      },
    },
  );

  const removeItems = useMutation(
    async (data: OrderSessionRemoveItemsParams) =>
      request({
        method: 'delete',
        url: `/store/order_sessions/${orderSessionId}/line_items.json`,
        data: data,
      }) as Promise<null>,
    {
      onSuccess: () => {
        onOrderMutationSuccess(orderSessionId);
      },
    },
  );

  return { moveItems, removeItems };
};
