import { createContext, useContext } from 'react';
import { SerialNumberInfo } from '../utils/serial-number-info';

export type TerminalSettingContextType = {
  store_app_type: 'web' | 'native' | undefined;
  serial_number_info: SerialNumberInfo;
};

export const TerminalSettingContext = createContext<TerminalSettingContextType>(
  {} as TerminalSettingContextType,
);

export const useTerminalSetting = (): TerminalSettingContextType => {
  const context = useContext(TerminalSettingContext);

  if (!context) {
    throw new Error(
      'useTerminalSetting must be used within an TerminalSettingProvider',
    );
  }

  return context;
};
