import React, { FC } from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../utils/tailwind';

type Props = {
  error: string | undefined;
  visible: boolean | undefined;
};

export const FormErrorMessage: FC<Props> = ({ error, visible }) => {
  if (!error || !visible) {
    return null;
  }

  return (
    <View style={tw`mt-2`}>
      <Text style={tw`text-[#F5185A]`}>{error}</Text>
    </View>
  );
};
