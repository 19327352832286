import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import {} from '../../../types/cash-register/order-types';
import {
  OrderSessionShowParams,
  OrderSessionShowResponse,
} from '../../../types/cash-register/order-session.types';

export const useOrderSessionQueries = (params: OrderSessionShowParams) => {
  const fetchOrderSession = useQuery(
    ['orderSessions', params.id],
    async () =>
      request({
        method: 'get',
        url: `/store/order_sessions/${params.id}.json`,
      }) as Promise<OrderSessionShowResponse>,
    {
      // The next time the query is run, it will refetch the data
      // if it's more than 5 minutes old.
      staleTime: 1000 * 60 * 5,
    },
  );

  return { fetchOrderSession };
};
