import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import {
  CartResponse,
  CartType,
} from '../../../types/cash-register/cart-types';

export const useCartQueries = (cartId: number | null) => {
  const initialData = {
    id: 0,
    total: 0,
    cart_type: 'table_order' as CartType,
    store_area_id: -1,
    line_items: [],
    actions: {
      set_processing_time_options: [],
    },
  };

  const getCart = useQuery(
    ['cashRegister', 'carts', cartId || 'template'],
    async () =>
      cartId
        ? (request({
            method: 'get',
            url: `/store/carts/${cartId}.json`,
          }) as Promise<CartResponse>)
        : (Promise.resolve(initialData) as Promise<CartResponse>),
    {
      initialData: initialData,
    },
  );

  return { getCart };
};
