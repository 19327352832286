import React, { useState } from 'react';
import { PaymentContext, PaymentSteps } from '../contexs/payment-context';

interface PaymentProviderProps {
  initialStep?: PaymentSteps;
  children: React.ReactNode;
}

export const PaymentProvider = (props: PaymentProviderProps) => {
  const [step, setStep] = useState<PaymentSteps>(
    props.initialStep || 'ITEM_SELECTION',
  );

  return (
    <PaymentContext.Provider
      value={{
        step,
        setStep,
      }}>
      {props.children}
    </PaymentContext.Provider>
  );
};
