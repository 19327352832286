import { useEffect } from 'react';
import { NativeModules } from 'react-native';
import { useTenantsQueries } from '../../api/hooks/tenants/use-tenants-queries';
import { useAuth } from '../../contexs/auth-context';
import env from '../../env';
import { WakeSleepChannelPayload } from '../types';
import useActionCable from './use-action-cable';
import useChannel from './use-channel';

export default function useWakeSleepChannel() {
  const { authData } = useAuth();
  const { actionCable } = useActionCable(
    `${env.WEBSOCKET_URL}?token=${authData?.token}`,
  );
  const { subscribe, unsubscribe } = useChannel(actionCable);
  const { ScreenModule } = NativeModules;
  const { fetchTenantsDashboard } = useTenantsQueries();
  let delay: ReturnType<typeof setTimeout> | null;

  useEffect(() => {
    subscribe(
      { channel: 'WakeSleepChannel' },
      {
        received: (payload: WakeSleepChannelPayload) => {
          const { wake_after } = payload;

          if (!ScreenModule) {
            return;
          }

          if (typeof wake_after === 'number') {
            if (wake_after < 60 * 12) {
              // to reload opening state when it's time to wake up
              if (delay) {
                clearTimeout(delay);
              }

              delay = setTimeout(
                fetchTenantsDashboard.refetch,
                wake_after * 1000,
              );
            }
            ScreenModule.wakeOrSleep(wake_after);
          }
        },
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);
}
