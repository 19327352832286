export const linking = {
  prefixes: [],
  config: {
    screens: {
      Root: '',
      StoreArea: {
        path: 'store_areas/:store_area_id/store_tables',
        parse: {
          store_area_id: Number,
        },
      },
      Orders: {
        path: 'orders',
        parse: {
          store_table_id: Number,
        },
      },
      // Invoices
      Invoices: {
        path: 'invoices',
        parse: {
          store_table_id: Number,
        },
      },
      InvoiceShow: {
        path: 'invoices/:id',
        parse: {
          id: Number,
        },
      },
      InvoiceShowQr: {
        path: 'invoices/:id/qr',
        parse: {
          id: Number,
        },
      },
      InvoiceWorkflowPending: {
        path: 'invoices/workflows/:id/pending',
        parse: {
          id: Number,
        },
      },
      VendorDashboard: 'vendor',
      DeliveredCarts: 'vendor/delivered_carts',
      Products: 'vendor/products',
      CashiersSwitch: 'cashiers/switch',
      CartNew: {
        path: 'carts/new',
        parse: {
          store_table_id: Number,
          product_category_id: Number,
        },
      },
      CartShow: {
        path: 'carts/:cart_id',
        parse: {
          cart_id: Number,
        },
      },
      CartShowCategory: {
        path: 'carts/:cart_id/product_categories/:product_category_id',
        parse: {
          cart_id: Number,
          product_category_id: Number,
        },
      },
      Cashbook: 'cashbook',
      OrderSessionEdit: 'order_sessions/:id/edit',
      OrderSessionPay: 'order_sessions/:id/pay',
      Customers: 'customers',
    },
  },
};
