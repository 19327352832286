import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLanguage, languagesResources } from './language-config';

export type i18namespaces = 'common' | 'screens' | 'forms' | 'cashRegister';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: languagesResources,
    compatibilityJSON: 'v3',
    // language to use if translations in user language are not available.
    fallbackLng: defaultLanguage,

    ns: ['common', 'screens', 'forms', 'cashRegister'],
    defaultNS: 'common',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: true,
      defaultTransParent: 'Text',
      transSupportBasicHtmlNodes: false,
    },
  });

export default i18n;
