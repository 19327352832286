/* eslint-disable */
/**************** DO NOT TOUCH *****************/
/* This file gets generated by 'env:*' scripts */
/***********************************************/

export default {
  "APP_URL": "https://app.typo00.com",
  "API_URL": "https://api.typo00.com",
  "ENABLE_SINGLE_SIGN_ON": true,
  "SENTRY_DSN": "https://07522ac3056d4f8eb335ee869eab9870@sentry.prod.typo00.com/7",
  "TERMINAL_SERIAL_ENCRYPTION_KEY": "574a2eec13a04eed4fde92bb8bd066ae9bc37ea05cd025f97348b0e34c75cb4",
  "WEBSOCKET_URL": "https://app.typo00.com/cable",
  "WEBSOCKET_IV": "7b7e907c7dd56ee68d6e27f6f525e9ca"
};
