import React from 'react';
import { useT } from '../../../utils/helper';
import { ModalBase } from '../../shared/modals/modal-base';
import { tw } from '../../../utils/tailwind';
import { View } from 'react-native';
import { Input } from '../forms/input';
import { useCashbookEntryForm } from '../../../forms/use-cashbook-entry-form.hook';
import { FormGroup } from '../forms/form-group';
import { SelectList } from '../../forms/select-list';
import { TouchableOpacity } from '../../forms';
import { EntryTypeSwitch } from '../forms/entry-type-switch';
import { useCashbookEntryCategoriesQueries } from '../../../api/hooks/cash-register/cashbook-entry-categories/use-cashbook-entry-categories-queries';
import { useCashbookEntryMutations } from '../../../api/hooks/cash-register/cashbook-entries/use-cashbook-entry-mutations';
import { useQueryClient } from '@tanstack/react-query';

interface ModalAddCashbookEntryProp {
  isVisible: boolean;
  onModalHide: () => void;
}

export const ModalAddCashbookEntry = (props: ModalAddCashbookEntryProp) => {
  const t = useT();
  const queryClient = useQueryClient();
  // Queries
  const { fetchCashbookEntryCategories } = useCashbookEntryCategoriesQueries();
  const { createCashbookEntry } = useCashbookEntryMutations();
  // Form
  const form = useCashbookEntryForm(params => {
    createCashbookEntry.mutateAsync(params).then(() => {
      form.resetForm();
      props.onModalHide();
      queryClient.refetchQueries(['cashbookEntries']);
    });
  });

  const errorFor = (att: string) => {
    // Ignore since formik typings are not correct when use [att] instead of .att
    // @ts-ignore
    return form.touched[att] || form.submitCount > 0 ? form.errors[att] : null;
  };

  return (
    <ModalBase
      isVisible={props.isVisible}
      onModalHide={props.onModalHide}
      title={t('newEntry').toString()}
      titleStyle={tw`text-center max-w-full`}
      contentContainerStyle={tw`w-[592px]`}>
      <View style={tw`w-full gap-y-5`}>
        {/* Entry type */}
        <EntryTypeSwitch
          defaultType="deposit"
          onTypeChange={entryType =>
            form.setFieldValue('entry_type', entryType)
          }
        />
        {/* Category */}
        <FormGroup
          label={t('cashbookForm.category')}
          viewStyle={tw`z-5`}
          isError={errorFor('cashbook_entry_category_id')}>
          <SelectList
            setSelected={selectedOption => {
              form.setFieldValue(
                'cashbook_entry_category_id',
                selectedOption.id,
              );
            }}
            data={
              fetchCashbookEntryCategories.data?.cashbook_entry_categories || []
            }
            labelKey="name"
            valueKey="id"
            dropdownStyles={tw`mt-[-5px]`}
            errorMessage={errorFor('cashbook_entry_category_id')}
            placeholder=" "
          />
        </FormGroup>
        {/* Gross Amount */}
        <FormGroup
          label={t('cashbookForm.grossAmount')}
          isError={!!errorFor('gross_amount')}>
          <Input
            autoFocus={true}
            keyboardType="numeric"
            inputMode="numeric"
            value={form.values.gross_amount?.toString().replace(/[^0-9]/g, '')}
            onChangeText={form.handleChange('gross_amount')}
            error={errorFor('gross_amount')}
          />
        </FormGroup>
        <View style={tw`flex-row`}>
          {/* Invoice */}
          <FormGroup
            label={t('cashbookForm.invoiceNumber')}
            viewStyle={tw`basis-1/2 pr-2.5`}>
            <Input
              autoFocus={true}
              value={form.values.reference_number}
              onChangeText={form.handleChange('reference_number')}
              error={
                form.touched.reference_number
                  ? form.errors.reference_number
                  : null
              }
            />
          </FormGroup>
          {/* Delivery note number */}
          <FormGroup
            label={t('cashbookForm.deliveryNoteNumber')}
            viewStyle={tw`basis-1/2 pl-2.5`}>
            <Input
              autoFocus={true}
              value={form.values.delivery_note_number}
              onChangeText={form.handleChange('delivery_note_number')}
              error={
                form.touched.delivery_note_number
                  ? form.errors.delivery_note_number
                  : null
              }
            />
          </FormGroup>
        </View>
        {/* Description */}
        <FormGroup label={t('cashbookForm.description')}>
          <Input
            autoFocus={true}
            value={form.values.description}
            onChangeText={form.handleChange('description')}
            error={form.touched.description ? form.errors.description : null}
          />
        </FormGroup>

        <TouchableOpacity
          label={t('save')}
          extraStyle="w-full mt-3"
          disabled={form.isSubmitting}
          onPress={() => form.handleSubmit()}
        />
      </View>
    </ModalBase>
  );
};
