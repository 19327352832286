import React, { useEffect, useState } from 'react';
import { tw } from '../../../utils/tailwind';
import { View } from 'react-native';
import { InvoiceInfoRow } from './invoice-info-row';
import { NumericInputPadButton } from '../../../components/cash-register/shared/numeric-input-pad-button';
import { BackSpaceSVG } from '../../../../assets/svg';
import { CURRENCY_SYMBOL, formatCurrency, useT } from '../../../utils/helper';
import {
  calculatorHandler,
  formatThousand,
  normalizeStringNumber,
} from '../../../utils/helpers/number-helper';

const INPUT_SEQUENCE = [
  '1',
  '2',
  '3',
  '+10',
  '4',
  '5',
  '6',
  '+20',
  '7',
  '8',
  '9',
  '+50',
  'C',
  '0',
  ',',
];

interface InvoiceGivenAndReturnProps {
  unPaidTotal: number;
  paymentAmount: number;
  i18nKeyPrefix: 'payment' | 'calculator';
  tipAmount: number;
  disabled: boolean;
  children?: React.ReactNode;
  onChange?: (value: number) => void;
}

export const InvoiceGivenAndReturn = (props: InvoiceGivenAndReturnProps) => {
  // Standard Hooks
  const t = useT('screens', {
    keyPrefix: `Invoice.paymentView.${props.i18nKeyPrefix}`,
  });
  // States
  const [dirty, setDirty] = useState(false);
  const [givenCash, setGivenCash] = useState(
    props.paymentAmount.toFixed(2).replace('.', ','),
  );
  // Constants
  const givenCashValue = parseFloat(
    // 1.000,12 needs to be converted to 1,000.12 for parseFloat
    givenCash.replace(/\./g, '').replace(',', '.'),
  );
  const returnCash = givenCashValue - props.unPaidTotal + props.tipAmount;
  // Callbacks
  const onInputPadPress = (content: string) => {
    if (dirty) {
      setGivenCash(
        normalizeStringNumber(calculatorHandler(content, givenCash)),
      );
    } else {
      setDirty(true);
      setGivenCash(normalizeStringNumber(calculatorHandler(content, '0')));
    }
  };

  const renderInputPad = (content: string) => {
    return (
      <NumericInputPadButton
        disabled={props.disabled}
        key={content}
        content={content}
        style={content.startsWith('+') ? tw`bg-gray-50` : {}}
        textStyle={tw`text-[38px] xs:text-[42px]`}
        onPress={() => onInputPadPress(content)}
      />
    );
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(givenCashValue);
    }
  }, [givenCashValue]);

  useEffect(() => {
    setDirty(false);
    setGivenCash(props.paymentAmount.toFixed(2).replace('.', ','));
  }, [props.paymentAmount]);

  return (
    <>
      {/* Total & Given section */}
      <View style={tw`gap-y-4`}>
        <InvoiceInfoRow
          label={t('given')}
          value={`${formatThousand(givenCash)} ${CURRENCY_SYMBOL}`}
          valueAsInput={true}
        />
        {/* Return section */}
        <InvoiceInfoRow
          label={t('unpaidTotal')}
          value={formatCurrency(props.unPaidTotal)}
          theme="danger"
        />
        {/* Return section */}
        <InvoiceInfoRow
          label={t('return')}
          value={returnCash >= 0 ? formatCurrency(returnCash) : CURRENCY_SYMBOL}
        />
      </View>

      {props.children ? props.children : null}

      {/* Given input */}
      <View style={tw`flex-row flex-wrap gap-2 m-auto xs:w-[384px] w-[344px]`}>
        {INPUT_SEQUENCE.map(renderInputPad)}

        <NumericInputPadButton
          disabled={props.disabled}
          content={<BackSpaceSVG style={tw`w-10 h-[33px]`} />}
          onPress={() => onInputPadPress('backspace')}
        />
      </View>
    </>
  );
};

InvoiceGivenAndReturn.defaultProps = {
  tipAmount: 0,
  disabled: false,
};
