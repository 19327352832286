import React, { useState } from 'react';
import { Text, View } from 'react-native';
import tw from 'twrnc';
import { CloseSVG } from '../../../../assets/svg';
import { useAuthMutations } from '../../../api/hooks/auth/use-auth-mutations';
import {
  AuthRequestPayload,
  AuthResponse,
} from '../../../api/types/auth-types';
import {
  FormErrorMessage,
  Label,
  TextInput,
  TouchableOpacity,
} from '../../../components/forms';
import { useAuth } from '../../../contexs/auth-context';
import { useLoginForm } from '../../../forms/use-login-form.hook';
import { useT } from '../../../utils/helper';

export const SignInForm = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const t = useT();
  const { performLogin } = useAuthMutations();
  const { signIn } = useAuth();

  const loginForm = useLoginForm((onSubmitValues: AuthRequestPayload) => {
    performLogin
      .mutateAsync(onSubmitValues)
      .then((data: AuthResponse) => signIn(data))
      .catch(response => setErrorMessage(response.data.error))
      .finally(() => setSubmitting(false));
  });

  const {
    errors,
    touched,
    isValid,
    isSubmitting,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setSubmitting,
  } = loginForm;

  return (
    <View style={tw`flex-1`}>
      <Text style={tw`mb-10 ml-4 text-2xl font-bold text-gray-800`}>
        {t('yourAdminArea')}
      </Text>

      {/* Input fields */}
      <Label>{t('email')}</Label>
      <TextInput
        name="email"
        autoCapitalize="none"
        keyboardType="email-address"
        textContentType="emailAddress"
        autoFocus={true}
        value={values.email}
        onChangeText={handleChange('email')}
        onBlur={handleBlur('email')}
      />
      <FormErrorMessage error={errors.email} visible={touched.email} />

      <Label>{t('password')}</Label>
      <TextInput
        name="password"
        autoCapitalize="none"
        autoCorrect={false}
        textContentType="password"
        secureTextEntry={true}
        value={values.password}
        onChangeText={handleChange('password')}
        onBlur={handleBlur('password')}
      />
      <FormErrorMessage error={errors.password} visible={touched.password} />

      {/* Login button */}
      <TouchableOpacity
        onPress={() => handleSubmit()}
        disabled={!isValid || isSubmitting || performLogin.isLoading}
        label={t('login')}
        extraStyle="mt-5 w-full px-7 justify-center py-3.5"
      />

      {errorMessage ? (
        <View style={tw`flex-row py-3 px-4 rounded-lg bg-[#FFEFF8] mt-5`}>
          <View
            style={tw`justify-center items-center p-1 rounded-full bg-[#F5185A]`}>
            <CloseSVG
              style={tw`w-4 h-4`}
              fill="transparent"
              stroke={tw.color('bg-white')}
            />
          </View>
          <Text style={tw`pl-3 text-[#F5185A]`}>{errorMessage}</Text>
        </View>
      ) : null}
    </View>
  );
};
