import { useMutation, useQueryClient } from '@tanstack/react-query';
import request from '../../../request';
import {
  Customer,
  CustomerParams,
} from '../../../types/cash-register/customer-types';

export const useCustomerMutations = () => {
  const queryClient = useQueryClient();
  const onSettled = () => {
    queryClient.invalidateQueries(['customers']);
  };

  const createCustomer = useMutation(
    async (params: CustomerParams) =>
      request({
        method: 'post',
        url: '/store/customers.json',
        data: { customer: params },
      }) as Promise<Customer>,
    {
      onSettled,
    },
  );

  const updateCustomer = useMutation(
    async (params: CustomerParams) =>
      request({
        method: 'put',
        url: `/store/customers/${params.id}.json`,
        data: { customer: params },
      }) as Promise<Customer>,
    {
      onSettled,
    },
  );

  const deleteCustomer = useMutation(
    async (id: number) =>
      request({
        method: 'delete',
        url: `/store/customers/${id}.json`,
      }),
    {
      onSettled,
    },
  );

  return { createCustomer, updateCustomer, deleteCustomer };
};
