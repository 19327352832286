import React, { useState } from 'react';
import { HeaderBarBase } from '../../shared/header-bar-base';
import { TouchableOpacity, View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { UserSVG } from '../../../../assets/svg';
import {
  getHeaderBarColorTheme,
  getVendorHeaderBarStyle,
} from '../../../utils/conditional-style';
import { HeaderBarMenu } from './header-bar-menu';
import { ModalSwitchCashier } from '../modals/modal-switch-cashier';
import { useAuth } from '../../../contexs/auth-context';

export const HeaderBar = () => {
  // Hooks
  const { currentCashier } = useAuth();
  const [isModalSwitchCashierVisible, setIsModalSwitchCashierVisible] =
    useState(false);

  return (
    <>
      <ModalSwitchCashier
        isVisible={isModalSwitchCashierVisible}
        onBackdropPress={() => setIsModalSwitchCashierVisible(false)}
        onBackButtonPress={() => setIsModalSwitchCashierVisible(false)}
      />

      <HeaderBarBase classNames={getVendorHeaderBarStyle('default')}>
        <View>
          <TouchableOpacity
            onPress={() => setIsModalSwitchCashierVisible(true)}
            style={tw`flex-row gap-x-2 items-center`}>
            <View
              style={tw`p-3 justify-center items-center rounded-lg bg-white`}>
              <UserSVG
                style={tw`w-5 h-5`}
                strokeWidth={2.5}
                stroke={getHeaderBarColorTheme('default')}
              />
            </View>
            <Text
              numberOfLines={1}
              style={tw`text-2xl leading-none font-bold text-white overflow-hidden xs:max-w-none max-w-[235px]`}>
              {currentCashier?.name}
            </Text>
          </TouchableOpacity>
        </View>
        <HeaderBarMenu />
      </HeaderBarBase>
    </>
  );
};
