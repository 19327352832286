import { useQuery } from '@tanstack/react-query';
import request from '../../request';
import { TenantsDashboardResponse } from '../../types/tenants-types';

export const useTenantsQueries = () => {
  const fetchTenantsDashboard = useQuery(
    ['tenantsDashboard'],
    async () =>
      request({
        method: 'get',
        url: '/store/vendor/tenants/dashboard.json',
      }) as Promise<TenantsDashboardResponse>,
    {
      // This means that the next time the query is run, it will refetch
      // the data if it's more than 3 seconds old.
      staleTime: 3000,
    },
  );

  return { fetchTenantsDashboard };
};
