import { useInfiniteQuery } from '@tanstack/react-query';
import request from '../../../request';
import { CashbookEntriesResponse } from '../../../types/cash-register/cashbook-entry.types';

export const useCashbookEntriesQueries = () => {
  const fetchCashbookEntries = useInfiniteQuery(
    ['cashbookEntries'],
    async ({ pageParam }) =>
      request({
        method: 'get',
        url: '/store/cashbook_entries.json',
        params: {
          page: pageParam,
          sort_by: 'id desc',
        },
      }) as Promise<CashbookEntriesResponse>,
    {
      getNextPageParam: (lastPage, _pages) => {
        // Return the next page number if there are more pages
        return lastPage.next_page || false;
      },
      // This means that the next time the query is run, it will refetch
      // the data if it's more than 15 minutes old.
      staleTime: 1000 * 60 * 15,
      initialData: {
        pages: [
          {
            cashbook_entries: [],
            current_balance: 0,
            todays_deposit_sum: 0,
            todays_withdrawal_sum: 0,
            current_page: 0,
            per_page: 0,
            total_entries: 0,
            total_pages: 0,
            next_page: 0,
            previous_page: 0,
            offset: 0,
          },
        ],
        pageParams: [],
      },
    },
  );

  return { fetchCashbookEntries };
};
