import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { LoadingAnimation } from '../components/loading-animation';
import { ErrorView } from '../components/error-view';
import { useT } from '../utils/helper';

interface QuerySuspenseProps<T> {
  query: UseQueryResult<T, unknown>;
  onSuccess: (data: T) => JSX.Element | JSX.Element[];
  loadingComponent?: JSX.Element;
  errorMessage?: string;
  errorMessageStyle?: string;
  hideRefetchLoading?: boolean;
  memo?: number | string;
  flattenPages?: string;
}

const QuerySuspense = <T extends any>(props: QuerySuspenseProps<T>) => {
  const t = useT();

  const queryData = props.flattenPages
    ? props.query.data?.pages.map(page => page[props.flattenPages]).flat()
    : props.query.data;

  const content = useMemo(() => {
    if (props.hideRefetchLoading && queryData) {
      return <>{props.onSuccess(queryData)}</>;
    } else if (props.query.isLoading || props.query.isFetching) {
      // case: loading / fetching / refetching / pending
      return props.loadingComponent ? (
        props.loadingComponent
      ) : (
        <LoadingAnimation />
      );
    } else {
      if (props.query.error || !queryData) {
        // case: error
        return (
          <ErrorView
            textStyle={props.errorMessageStyle}
            message={
              typeof props.query.error === 'string' //handle uknown ts error
                ? props.query.error
                : t('defaultFetchErrorMessage').toString()
            }
          />
        );
      } else {
        // case: success, query data is now available
        return <>{props.onSuccess(queryData)}</>;
      }
    }
  }, [
    props.query.isLoading,
    props.query.isFetching,
    props.query.data,
    props.memo,
  ]);

  return content;
};

export default QuerySuspense;
