import Sound from 'react-native-sound';

const onError = (error: string) => {
  if (error) {
    console.log('failed to load the sound', error);
    return;
  }
};

const notificationSounds = {
  bell: () => new Sound('bell.mp3', Sound.MAIN_BUNDLE, onError),
};

export default notificationSounds;
