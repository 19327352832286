import { useFormik } from 'formik';
import * as yup from 'yup';
import { useT } from '../utils/helper';
import { AuthRequestWithEmailAndPassword } from '../api/types/auth-types';

const useLoginForm = (
  onSubmit: ({ email, password }: AuthRequestWithEmailAndPassword) => void,
) => {
  const t = useT('forms');
  const loginFormSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalidEmail').toString())
      .required(t('requiredField').toString()),
    password: yup
      .string()
      .min(6, t('minimumInputLength', { minLength: 6 }).toString())
      .required(t('requiredField').toString()),
  });

  type FormContents = yup.InferType<typeof loginFormSchema>;

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    } as FormContents,
    initialTouched: {},
    validationSchema: loginFormSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit,
  });

  return form;
};

export { useLoginForm };
