import request from '../../../request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  InvoiceCancelParams,
  InvoiceCancelResponse,
  InvoiceCreateParams,
  InvoiceCreateResponse,
  InvoicePrintParams,
} from '../../../types/cash-register/invoice.types';
import { PrintReceiptResponse } from '../../../types/vendor/carts-types';
import { useNavigation } from '@react-navigation/native';

export const useInvoiceMutations = () => {
  const queryClient = useQueryClient();
  const navigation = useNavigation();

  const createInvoice = useMutation(
    async (params: InvoiceCreateParams) =>
      request({
        method: 'post',
        url: '/store/invoices.json',
        data: { invoice: params },
      }) as Promise<InvoiceCreateResponse>,
  );

  const cancelInvoice = useMutation(
    async (params: InvoiceCancelParams) =>
      request({
        method: 'delete',
        url: `/store/invoices/${params.id}.json`,
        data: {
          reason: params.reason,
          return_items_to_table: params.return_items_to_table,
        },
      }) as Promise<InvoiceCancelResponse>,
  );

  const printInvoice = useMutation(
    async (params: InvoicePrintParams) =>
      request({
        method: 'post',
        url: `/store/invoices/${params.id}/print.json`,
        params,
      }) as Promise<PrintReceiptResponse>,
  );

  const onCancelInvoiceSuccess = (data: InvoiceCancelResponse) => {
    queryClient.invalidateQueries(['invoices']);
    queryClient.invalidateQueries([
      'storeAreas',
      data.store_table.store_area_id,
      'storeTables',
    ]);

    if (data.order) {
      navigation.navigate('OrderSessionPay', {
        id: data.order.order_session.id,
      });
    }
  };

  return { createInvoice, cancelInvoice, printInvoice, onCancelInvoiceSuccess };
};
