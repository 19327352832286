import React from 'react';
import { tw } from '../../../utils/tailwind';
import { TextInput } from 'react-native';
import { twCb, useT } from '../../../utils/helper';

interface CartCommentInputProps {
  className?: string;
  initialValue?: string;
  onChange?: (value: string) => void;
}

export const CartCommentInput = (props: CartCommentInputProps) => {
  const t = useT();

  return (
    <TextInput
      onChangeText={props.onChange}
      value={props.initialValue}
      placeholder={t('comment').toString()}
      multiline={true}
      placeholderTextColor={tw.color('gray-400')}
      style={tw`${twCb(
        'xl:py-3.5 py-3 px-4 text-xl font-medium leading-none text-gray-800 rounded-lg border-[1.5px] border-solid border-gray-400 shadow-sm md:min-h-[100px] min-h-[144px]',
        props.className,
      )}`}
    />
  );
};
