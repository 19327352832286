import React, { useState } from 'react';
import { ModalFullBase } from '../../modals/modal-full-base';
import { useAuth } from '../../../contexs/auth-context';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { Cashier } from '../../../api/types/cash-register/cashier-types';
import { ModalUnlockCashier } from './modal-unlock-cashier';
import { useCashiersQueries } from '../../../api/hooks/cash-register/cashiers/use-cashiers-queries';
import QuerySuspense from '../../../api/query-suspense';
import { CashierSelectionView } from './modal-switch-cashier/cashier-selection-view';
import { NoCashierView } from './modal-switch-cashier/no-cashier-view';

interface ModalSwitchCashierProps {
  isVisible: boolean;
  onModalHide?: () => void;
  onBackdropPress?: () => void;
  onBackButtonPress?: () => void;
}

export const ModalSwitchCashier = (props: ModalSwitchCashierProps) => {
  // Hooks
  const { setCurrentCashier } = useAuth();
  const { fetchCashiers } = useCashiersQueries();
  // States
  const [selectedCashier, setSelectedCashier] = useState<Cashier | null>();
  const navigation = useNavigation<AppStackScreenProps<'Root'>['navigation']>();
  // Functions
  const onUnlockSuccess = (cashier: Cashier) => {
    setCurrentCashier(cashier);
    props.onModalHide ? props.onModalHide() : navigation.replace('Root');
  };

  if (selectedCashier) {
    return (
      <ModalUnlockCashier
        isVisible={props.isVisible}
        onModalHide={() => setSelectedCashier(null)}
        onBackdropPress={
          props.onBackdropPress || props.onModalHide || (() => {})
        }
        cashier={selectedCashier}
        onUnlockSuccess={onUnlockSuccess}
      />
    );
  }

  return (
    <ModalFullBase
      isVisible={props.isVisible}
      onModalHide={props.onModalHide}
      onBackdropPress={props.onBackdropPress || props.onModalHide || (() => {})}
      onBackButtonPress={
        props.onBackButtonPress || props.onModalHide || (() => {})
      }
      style="justify-center">
      <QuerySuspense
        query={fetchCashiers}
        onSuccess={cashiers =>
          cashiers.length ? (
            <CashierSelectionView
              cashiers={cashiers}
              onSelect={setSelectedCashier}
            />
          ) : (
            <NoCashierView />
          )
        }
      />
    </ModalFullBase>
  );
};
