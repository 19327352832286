import React from 'react';
import { tw } from '../../utils/tailwind';
import { Text } from 'react-native';

interface LineItemIngredientProps {
  ingredient: string;
}

export const LineItemIngredient = (props: LineItemIngredientProps) => {
  return (
    <Text style={tw`tracking-wider xl:text-base text-sm text-gray-700`}>
      {props.ingredient}
    </Text>
  );
};
