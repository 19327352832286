import React, { useEffect, useState } from 'react';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { tw } from '../../../utils/tailwind';
import { View } from 'react-native';
import { useInvoiceQueries } from '../../../api/hooks/cash-register/invoices/use-invoice-queries';
import { AppStackParamList } from '../../../routes';
import { RouteProp, useNavigation } from '@react-navigation/native';
import QuerySuspense from '../../../api/query-suspense';
import { PaymentProvider } from '../../../providers/payment-provider';
import { InvoicePaymentButtons } from './invoice-payment-buttons';
import { InvoicePrintButtons } from './invoice-print-buttons';
import { TouchableOpacity as Button } from '../../../components/forms';
import { useT } from '../../../utils/helper';
import { InvoiceResponse } from '../../../api/types/cash-register/invoice.types';
import { useInvoiceMutations } from '../../../api/hooks/cash-register/invoices/use-invoice-mutation';
import SplashScreen from '../../splash/splash.screen';
import { WorkflowListenerProvider } from '../../../providers/workflow-listener-provider';
import { usePayment } from '../../../contexs/payment-context';
import { useInvoiceWorkflowListener } from '../../../hooks/use-invoice-workflow-listener';
import { useAlert } from '../../../contexs/alert-context';
import {
  InvoicePaymentViewNavigation,
  PAYMENT_VIEW,
} from './invoice-payment-view-navigation';
import { InvoicePaymentViewPay } from './invoice-payment-view-pay';
import { InvoicePaymentViewCalculator } from './invoice-payment-view-calculator';

interface InvoiceShowProps {
  invoice: InvoiceResponse;
}

const InvoiceShow = (props: InvoiceShowProps) => {
  // Standard Hooks
  const t = useT();
  const navigation = useNavigation();
  const alert = useAlert();
  useInvoiceWorkflowListener(props.invoice.id);
  // States
  const [paymentView, setPaymentView] = useState(PAYMENT_VIEW.PAYMENT);
  const [paymentAmount, setPaymentAmount] = useState(0);
  // Contexts
  const payment = usePayment();
  // Queries
  const { cancelInvoice, onCancelInvoiceSuccess } = useInvoiceMutations();

  // To prevent -0.00 add Math.abs
  const unPaidTotal = Math.abs(
    props.invoice.total - (props.invoice.total_paid - props.invoice.tip_amount),
  );

  const handleCancelInvoice = () => {
    cancelInvoice
      .mutateAsync({
        id: props.invoice.id,
        reason: 'customer_canceled',
        return_items_to_table: true,
      })
      .then(onCancelInvoiceSuccess);
  };

  useEffect(() => {
    // To update the footer bar to display print / payment buttons
    payment.setStep(props.invoice.paid ? 'PRINT' : 'PAYMENT');
  }, [props.invoice.paid]);

  useEffect(() => {
    alert.setModalOptions({
      onModalHide: () => {
        alert.hide();
      },
      onConfirm: handleCancelInvoice,
    });
  }, []);

  return (
    <>
      <ScreenContainer
        title={props.invoice.store_table.name}
        scrollable={true}
        headerWrapperStyle={tw`flex-wrap gap-y-2 max-w-[540px] self-center w-full mb-6`}
        containerStyle={tw`my-auto`}
        headerButtons={
          <View style={tw`flex-row gap-x-2`}>
            <Button
              label={t('back')}
              onPress={() =>
                // if invoice is created on top of virtual table
                // it won't belong to any store area once it completed
                // so just navigate to the root
                props.invoice.store_area
                  ? navigation.navigate('StoreArea', {
                      store_area_id: props.invoice.store_area.id,
                    })
                  : navigation.navigate('Root')
              }
              extraStyle="bg-white border border-solid md:py-4 py-3
              px-3 border-gray-800 shadow-sm rounded-lg w-[130px]"
              extraLabelStyle="text-gray-800 font-mediu"
            />
            {props.invoice.paid ? null : (
              <Button
                label={t('cancelInvoice')}
                onPress={() => alert.show(t('areYouSureWantToCancel'))}
                disabled={cancelInvoice.isLoading}
                theme="red"
                extraStyle="w-[210px]"
                extraLabelStyle="normal-case"
              />
            )}
          </View>
        }>
        <View style={tw`max-w-[540px] self-center w-full gap-y-6`}>
          <InvoicePaymentViewNavigation
            paymentView={paymentView}
            onPaymentViewChanged={view => setPaymentView(view)}
          />

          {paymentView === PAYMENT_VIEW.PAYMENT ? (
            <InvoicePaymentViewPay
              invoice={props.invoice}
              unPaidTotal={unPaidTotal}
              onPaymentAmountChanged={amount => setPaymentAmount(amount)}
            />
          ) : (
            <InvoicePaymentViewCalculator
              invoice={props.invoice}
              unPaidTotal={unPaidTotal}
            />
          )}
        </View>
      </ScreenContainer>

      {/* Action bar */}
      {paymentView === PAYMENT_VIEW.PAYMENT ? (
        <InvoicePaymentButtons
          invoiceId={props.invoice.id}
          amount={paymentAmount}
        />
      ) : null}
      <InvoicePrintButtons invoiceId={props.invoice.id} />
    </>
  );
};

interface InvoiceShowScreenProps {
  route: RouteProp<AppStackParamList, 'InvoiceShow'>;
}

export const InvoiceShowScreen = (props: InvoiceShowScreenProps) => {
  const id = props.route.params.id;
  // Queries
  const { fetchInvoice } = useInvoiceQueries(id);

  return (
    <QuerySuspense
      query={fetchInvoice}
      loadingComponent={<SplashScreen />}
      hideRefetchLoading={true}
      onSuccess={invoice => (
        <WorkflowListenerProvider>
          <PaymentProvider initialStep={invoice.paid ? 'PRINT' : 'PAYMENT'}>
            <InvoiceShow invoice={invoice} />
          </PaymentProvider>
        </WorkflowListenerProvider>
      )}
    />
  );
};
