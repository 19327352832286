import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';

export const useBackHandler = () => {
  const navigation = useNavigation();
  let screenRenderedOnPath: string;

  useEffect(() => {
    document.addEventListener('navigate-back', handlePopstate);

    // Listen to navigation state (screen) change
    const unsubscribeNavigationListener = navigation.addListener(
      'state',
      () => {
        // Delay 100 ms to wait for the URL change
        setTimeout(
          () => (screenRenderedOnPath = window.location.pathname),
          100,
        );
      },
    );

    return () => {
      document.removeEventListener('navigate-back', handlePopstate);
      unsubscribeNavigationListener();
    };
  }, []);

  const handlePopstate = () => {
    // Stack is not updated when (browser) back button is pressed
    // so we need to manually update it if the current screen and component did not match
    setTimeout(() => {
      if (screenRenderedOnPath !== window.location.pathname) {
        if (window.removingRoute) {
          window.removingRoute = false;
        } else {
          window.location.href = window.location.href;
        }
      }
    }, 200);
  };
};
