import React, { useEffect } from 'react';
import { View, Text } from 'react-native';
import { formatCurrency, useT } from '../../../utils/helper';
import { CashbookSummaryItem } from './cashbook-summary-item';
import { tw } from '../../../utils/tailwind';
import QuerySuspense from '../../../api/query-suspense';
import { useCashbookEntriesQueries } from '../../../api/hooks/cash-register/cashbook-entries/use-cashbook-entry-queries';
import { CashbookNewEntryButton } from './cashbook-new-entry-button';

interface CashbookSummaryProps {}

export const CashbookSummary: React.FC<CashbookSummaryProps> = () => {
  const t = useT('screens', { keyPrefix: 'Cashbook' });
  // Queries
  const { fetchCashbookEntries } = useCashbookEntriesQueries();

  useEffect(() => {
    fetchCashbookEntries.refetch();
  }, []);

  return (
    <View style={tw`gap-y-6 p-5 rounded-2xl border border-gray-300 w-full`}>
      <QuerySuspense
        query={fetchCashbookEntries}
        hideRefetchLoading={true}
        onSuccess={data => (
          <>
            {/* Cash register */}
            <View style={tw`flex-row justify-between gap-x-4 items-start`}>
              <View>
                <Text
                  style={tw`md:text-[48px] text-[40px] font-bold leading-[130%] text-gray-900`}>
                  {formatCurrency(data.pages[0].current_balance)}
                </Text>
                <Text style={tw`text-base leading-5 text-gray-500`}>
                  {t('currentCashRegister')}
                </Text>
              </View>

              <CashbookNewEntryButton responsive={true} />
            </View>

            {/* Divider */}
            <View style={tw`w-full h-px bg-gray-200`} />

            {/* Summary entrances/exits */}
            <View style={tw`gap-y-2`}>
              <CashbookSummaryItem
                text={t('entrancesToday')}
                type="entrance"
                amount={data.pages[0].todays_deposit_sum}
              />
              <CashbookSummaryItem
                text={t('exitsToday')}
                type="exit"
                amount={data.pages[0].todays_withdrawal_sum}
              />
            </View>
          </>
        )}
      />
    </View>
  );
};
