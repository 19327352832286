import {
  LineItemOptionProps,
  OptionType,
} from '../../components/cash-register/modals/modal-line-item-form/line-item-option';

export const lineItemOptionHelper = (
  props: LineItemOptionProps,
  selectedOptions: OptionType[],
) => {
  const shouldIncrementQuantity = (option: OptionType, quantity: number) => {
    return props.canInputQuantity && quantity > 0 && isActive(option);
  };

  const updateQuantity = (
    options: OptionType[],
    option: OptionType,
    quantity: number,
  ) => {
    return options.map((o: OptionType) => {
      if (o.name === option.name) {
        o.quantity = quantity;
      }
      return o;
    });
  };

  const notifyParentComponent = (options: OptionType[]) => {
    const selectedValue = props.type === 'checkbox' ? options : options[0];
    props.onSelect(selectedValue);
  };

  const determineSelectedOptions = (option: OptionType) => {
    return props.type === 'checkbox'
      ? determineCheckboxOptions(option)
      : determineRadioOptions(option);
  };

  const determineCheckboxOptions = (option: OptionType) => {
    return isActive(option)
      ? selectedOptions.filter((item: OptionType) => item.name !== option.name)
      : [...selectedOptions, option];
  };

  const determineRadioOptions = (option: OptionType) => {
    return !props.required && isActive(option) ? [] : [option];
  };

  const getInitialQuantity = (option: OptionType) => {
    if (!props.canInputQuantity) {
      return 0;
    }

    return findOption(option)?.quantity;
  };

  const findOption = (option: OptionType) => {
    return selectedOptions.find((o: OptionType) => o.name === option.name);
  };

  const isActive = (option: OptionType) => {
    // Comparing object sometimes doesn't work, so we compare by name
    return !!findOption(option);
  };

  return {
    shouldIncrementQuantity,
    updateQuantity,
    notifyParentComponent,
    determineSelectedOptions,
    determineCheckboxOptions,
    determineRadioOptions,
    getInitialQuantity,
    isActive,
  };
};
