import React from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../utils/tailwind';
import { useTenantsMutations } from '../../api/hooks/tenants/use-tenants-mutations';
import { AutoEstimationOptions, Option } from '../../api/types/tenants-types';
import { chunkArray, splitLastArray } from '../../utils/helper';
import { TouchableOpacity as Button } from '../forms';
import { ModalFullBase } from './modal-full-base';
import { calculateOpacity } from '../../utils/style.helper';

interface ModalSetAutoEstimationProps {
  isVisible: boolean;
  onModalHide: () => void;
  autoEstimation: AutoEstimationOptions;
}

export const ModalSetAutoEstimation = (props: ModalSetAutoEstimationProps) => {
  const { setTurnOff } = useTenantsMutations();
  const { allOptionsExceptTheLast, lastOption } = splitLastArray([
    ...props.autoEstimation.auto_estimation_options,
  ]);

  const onSetAutoEstimationOptionClick = (path: string) => {
    props.onModalHide();
    setTurnOff.mutate(path);
  };

  const renderOptionButton = (
    option: Option,
    index: number,
    extraStyle?: string,
  ) => {
    return (
      <Button
        key={index}
        label={option.name}
        onPress={() => onSetAutoEstimationOptionClick(option.path)}
        theme="gray"
        extraStyle={extraStyle || ''}
      />
    );
  };

  return (
    <ModalFullBase
      isVisible={props.isVisible}
      backdropColor={tw.style('bg-white').backgroundColor.toString()}
      backdropOpacity={1}
      onModalHide={props.onModalHide}
      onBackdropPress={props.onModalHide}
      onBackButtonPress={props.onModalHide}>
      <Text style={tw`mb-6 text-xl text-center font-bold text-gray-800`}>
        {props.autoEstimation.headline}
      </Text>

      <Text style={tw`mb-6 text-center text-gray-500`}>
        {props.autoEstimation.sub_headline}
      </Text>

      <View style={tw`w-full`}>
        {props.autoEstimation.manual_estimation &&
          renderOptionButton(
            props.autoEstimation.manual_estimation,
            -2,
            'bg-gray-900 mb-6',
          )}

        {/* Render all options except the last one */}
        {chunkArray(allOptionsExceptTheLast, 3).map((options, index) => (
          <View key={index} style={tw`flex-row gap-3 mb-3 `}>
            {options.map((option, idx) =>
              renderOptionButton(
                option,
                idx,
                `flex-1 bg-gray-${calculateOpacity(index)}`,
              ),
            )}
          </View>
        ))}

        {/* Last option always have full width */}
        {renderOptionButton(lastOption, -1, 'bg-gray-900')}
      </View>
    </ModalFullBase>
  );
};
