import React from 'react';
import { T0TouchableOpacity as Button } from '../../../components/forms/overrides';
import { View, useWindowDimensions } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useInvoice } from '../../../contexs/invoice-context';
import { Invoice } from '../../../api/types/cash-register/invoice.types';
import { useT } from '../../../utils/helper';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';

interface InvoiceSummaryActionButtonsProps {
  invoice: Invoice;
  onCancel: () => void;
}

export const InvoiceSummaryActionButtons = (
  props: InvoiceSummaryActionButtonsProps,
) => {
  // Standard Hooks
  const t = useT();
  const { width } = useWindowDimensions();
  const navigation =
    useNavigation<AppStackScreenProps<'Invoices'>['navigation']>();
  // Custom Hooks
  const { setInvoice } = useInvoice();
  // Callbacks
  const handleCancel = () => {
    setInvoice(props.invoice);
    props.onCancel();
  };

  return (
    <View style={tw`flex-row flex-wrap justify-between`}>
      <View
        style={[tw`md:basis-1/3 basis-1/1`, { order: width >= 768 ? 1 : 4 }]}>
        <Button
          theme={props.invoice.cancelable ? 'red' : 'white'}
          disabled={!props.invoice.cancelable}
          label={t('cancel')}
          extraStyle="py-5 px-3"
          extraLabelStyle="leading-none"
          onPress={handleCancel}
        />
      </View>
      <View
        style={[
          tw`md:basis-1/3 md:px-3 md:pb-0 pr-3 pb-3 basis-1/2`,
          { order: 2 },
        ]}>
        <Button
          label={t('qrReceipt')}
          extraStyle="py-5 px-3"
          extraLabelStyle="leading-none"
          onPress={() =>
            navigation.navigate('InvoiceShowQr', { id: props.invoice.id })
          }
        />
      </View>
      <View style={[tw`md:basis-1/3 basis-1/2`, { order: 3 }]}>
        <Button
          theme="green"
          label={t('printReceipt')}
          extraStyle="py-5 px-3"
          extraLabelStyle="leading-none text-center"
        />
      </View>
    </View>
  );
};
