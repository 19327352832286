import React, { useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { tw } from '../../utils/tailwind';
import { AutoEstimation } from '../../api/types/tenants-types';
import { ModalSetAutoEstimation } from '../modals/modal-set-auto-estimation';

enum EstimationMode {
  OFF = 'OFF',
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
}

interface SetAutoEstimationProps {
  autoEstimation: AutoEstimation;
}

export const SetAutoEstimation = (props: SetAutoEstimationProps) => {
  const [modalAutoEstimationMode, setModalAutoEstimationMode] =
    useState<EstimationMode>(EstimationMode.OFF);

  return (
    <>
      <TouchableOpacity
        onPress={() => setModalAutoEstimationMode(EstimationMode.DELIVERY)}>
        <Text style={tw`text-base font-bold text-white`}>
          {props.autoEstimation.delivery.message}
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => setModalAutoEstimationMode(EstimationMode.PICKUP)}>
        <Text style={tw`text-base font-bold text-white`}>
          {props.autoEstimation.pickup.message}
        </Text>
      </TouchableOpacity>

      {props.autoEstimation[modalAutoEstimationMode] ? (
        <ModalSetAutoEstimation
          isVisible={modalAutoEstimationMode !== EstimationMode.OFF}
          onModalHide={() => setModalAutoEstimationMode(EstimationMode.OFF)}
          autoEstimation={props.autoEstimation[modalAutoEstimationMode]}
        />
      ) : null}
    </>
  );
};
