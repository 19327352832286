import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { tw } from '../../../../utils/tailwind';
import { Cashier } from '../../../../api/types/cash-register/cashier-types';
import { ChevronRightSVG } from '../../../../../assets/svg';

interface CashierListProps {
  cashiers: Cashier[];
  onPressCashier: (cashier: Cashier) => void;
}

export const CashierList = (props: CashierListProps) => {
  return (
    <>
      {props.cashiers.map(cashier => (
        <View key={cashier.id} style={tw`ml-4 border-b border-gray-300`}>
          <TouchableOpacity
            onPress={() => props.onPressCashier(cashier)}
            style={tw`flex-row justify-between items-center py-5 pr-3 w-full bg-white`}>
            <Text
              style={tw`md:text-2xl md:leading-none text-xl leading-none text-gray-800`}>
              {cashier.name}
            </Text>
            <ChevronRightSVG
              style={tw`w-[18px] h-[18px]`}
              stroke={tw.color('gray-400')}
            />
          </TouchableOpacity>
        </View>
      ))}
    </>
  );
};
