import { QueryObserver, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useCartsMutations } from '../api/hooks/vendor/carts/use-carts-mutations';
import { Cart } from '../api/types/vendor/carts-types';
import notificationSounds from '../utils/notification-sounds';
import { processPOSCommand } from '../utils/print-module';

// Create an observer to watch the query and ring bell
// when there's unestimated cart
export default function useCartsQueryObserver() {
  const queryClient = useQueryClient();
  const { setOrderReceived, printReceipt } = useCartsMutations();

  useEffect(() => {
    const bellSound = notificationSounds.bell();
    const observer = new QueryObserver(queryClient, {
      queryKey: ['carts'],
    });

    const unsubscribe = observer.subscribe(queryResult => {
      const carts = Array.isArray(queryResult.data) ? queryResult.data : [];
      const newCarts = carts.filter(cart => !cart.order_received_by_vendor_at);
      const anyUnestimatedCart = carts.find((cart: Cart) => !cart.estimated_at);
      const isSoundLooping = bellSound.getNumberOfLoops() === -1;

      if (anyUnestimatedCart) {
        if (!isSoundLooping) {
          bellSound.setNumberOfLoops(-1).play();
        }
      } else {
        // stop loop when there's no new cart
        bellSound.setNumberOfLoops(0);
      }

      // if there's no new cart stop here
      if (!newCarts.length) {
        return;
      }

      // Check if all new carts is estimated
      if (newCarts.every(cart => cart.estimated_at)) {
        bellSound.play();
      }

      newCarts.forEach(cart => {
        // Set the order as received and print if necessary.
        setOrderReceived.mutateAsync(cart.id).then(({ auto_print }) => {
          if (auto_print) {
            printReceipt
              .mutateAsync(`/store/vendor/carts/${cart.id}/print_receipt.json`)
              .then(data => processPOSCommand(data.print_jobs));
          }
        });
      });
    });

    return () => {
      unsubscribe();
      bellSound.release();
    };
  }, []);
}
