import React, { useCallback, useMemo, useState } from 'react';
import { SectionList, View, useWindowDimensions } from 'react-native';
import { CashbookGroupEntries } from './cashbook-group-entries';
import { tw } from '../../../utils/tailwind';
import { useCashbookEntriesQueries } from '../../../api/hooks/cash-register/cashbook-entries/use-cashbook-entry-queries';
import { CashbookEntry } from './cashbook-entry';
import { CashbookEntry as CashbookEntryType } from '../../../api/types/cash-register/cashbook-entry.types';
import { ModalRemoveCashbookEntry } from '../../../components/cash-register/modals/modal-remove-cashbook-entry';
import { CashbookEntriesEmpty } from './cashbook-entries-empty';
import SplashScreen from '../../splash/splash.screen';

enum ModalVisible {
  RemoveCashbook = 'removeCashbook',
  None = 'none',
}

interface CashbookEntriesProps {}

export const CashbookEntries: React.FC<CashbookEntriesProps> = () => {
  const { height } = useWindowDimensions();
  // Queries
  const { fetchCashbookEntries } = useCashbookEntriesQueries();
  // States
  const [selectedEntry, setSelectedEntry] = useState<CashbookEntryType | null>(
    null,
  );
  const [modalVisible, setModalVisible] = useState<ModalVisible>(
    ModalVisible.None,
  );

  const { hasNextPage, data } = fetchCashbookEntries;

  const cashbookEntries = data?.pages
    ? data.pages.flatMap(page => page.cashbook_entries)
    : [];

  const loadMore = () => {
    if (hasNextPage && !fetchCashbookEntries.isFetchingNextPage) {
      fetchCashbookEntries.fetchNextPage();
    }
  };

  // Function to group entries by date
  const groupEntriesByDate = useCallback((entries: CashbookEntryType[]) => {
    const groupedEntries = entries.reduce<Record<string, CashbookEntryType[]>>(
      (acc, entry) => {
        const date = new Date(entry.created_at).toDateString(); // Format the date as needed

        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(entry);

        return acc;
      },
      {},
    );

    return Object.keys(groupedEntries).map(date => ({
      title: date,
      data: groupedEntries[date],
    }));
  }, []);

  // Group entries by date
  const groupedEntries = useMemo(
    () => groupEntriesByDate(cashbookEntries),
    [cashbookEntries, groupEntriesByDate],
  );

  const toggleRemoveEntry = (entry: CashbookEntryType | null) => {
    setSelectedEntry(entry);
    setModalVisible(entry ? ModalVisible.RemoveCashbook : ModalVisible.None);
  };

  return (
    <View
      style={tw`h-full max-h-[${
        height - 410
      }px] rounded-2xl border border-gray-300 justify-center`}>
      <SectionList
        refreshing={fetchCashbookEntries.isFetching}
        sections={groupedEntries}
        keyExtractor={(item, _index) => item.id.toString()}
        renderItem={({ item }) => (
          <CashbookEntry entry={item} onRemoveEntry={toggleRemoveEntry} />
        )}
        renderSectionHeader={({ section }) => (
          <CashbookGroupEntries date={section.title} />
        )}
        ListEmptyComponent={() =>
          fetchCashbookEntries.isFetched ? (
            <CashbookEntriesEmpty />
          ) : (
            <SplashScreen />
          )
        }
        contentContainerStyle={[
          tw`p-5 min-h-full`,
          groupedEntries.length === 0 ? tw`justify-center` : null,
        ]}
        ItemSeparatorComponent={() => <View style={tw`h-4`} />}
        SectionSeparatorComponent={() => <View style={tw`h-4`} />}
        onEndReached={loadMore}
        onEndReachedThreshold={0.5}
      />

      {selectedEntry ? (
        <ModalRemoveCashbookEntry
          entry={selectedEntry}
          isVisible={modalVisible === ModalVisible.RemoveCashbook}
          onModalHide={() => toggleRemoveEntry(null)}
        />
      ) : null}
    </View>
  );
};
