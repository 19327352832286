import React, { useState } from 'react';
import { View } from 'react-native';
import { tw } from '../utils/tailwind';
import QuerySuspense from '../api/query-suspense';
import { MenuHeaderBar } from './menus/menu-header-bar';
import { ModalStoreControl } from './modals/modal-store-control';
import { SetAutoEstimation } from './header-bars/set-auto-estimation';
import { StoreControlButton } from './store-control-button';
import { getOpeningState } from '../utils/tenant-helper';
import { getVendorHeaderBarStyle } from '../utils/conditional-style';
import { HeaderBarBase } from './shared/header-bar-base';
import { useTenant } from '../contexs/tenant-context';
import { useTenantsQueries } from '../api/hooks/tenants/use-tenants-queries';

export const HeaderBar = () => {
  const [isModalStoreControlVisible, setModalStoreControlVisible] =
    useState(false);
  const { fetchTenantsDashboard } = useTenantsQueries();
  const { tenant } = useTenant();

  return (
    <HeaderBarBase
      classNames={getVendorHeaderBarStyle(
        tenant.opening_state
          ? getOpeningState(tenant.opening_state)
          : 'default',
      )}>
      {/* Modal */}
      <ModalStoreControl
        isVisible={isModalStoreControlVisible}
        onModalHide={() => setModalStoreControlVisible(false)}
      />

      {/* Left Button */}
      <QuerySuspense
        query={fetchTenantsDashboard}
        onSuccess={dashboard => (
          <>
            <StoreControlButton
              dashboard={dashboard}
              onPress={() => setModalStoreControlVisible(true)}
            />

            <View style={tw`flex-row items-center gap-x-2`}>
              {dashboard.auto_estimation ? (
                <SetAutoEstimation autoEstimation={dashboard.auto_estimation} />
              ) : null}

              <MenuHeaderBar
                openingStateName={getOpeningState(dashboard.opening_state)}
              />
            </View>
          </>
        )}
        errorMessageStyle="text-white"
      />
    </HeaderBarBase>
  );
};
