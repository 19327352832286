import React from 'react';
import { View, Text, TextInput, ViewStyle } from 'react-native';
import { tw } from '../../../utils/tailwind';

interface InvoiceInfoRowProps {
  label: string;
  value: number | string;
  size: 'sm' | 'md';
  theme: 'default' | 'secondary' | 'danger';
  valueAsInput: boolean;
  style?: ViewStyle;
}

export const InvoiceInfoRow = (props: InvoiceInfoRowProps) => {
  // View Constants
  const textStyles = props.size === 'sm' ? Styles.textSm : Styles.textMd;

  return (
    <View style={[tw`flex-row justify-between gap-x-3`, props.style]}>
      <Text style={[textStyles, Styles.textColor[props.theme]]}>
        {props.label}
      </Text>
      {props.valueAsInput ? (
        <TextInput
          value={props.value.toString()}
          aria-disabled={true}
          style={[
            tw`px-3 py-4 text-lg w-[100-px] leading-none text-gray-800 rounded-lg border bg-white text-right border-gray-400`,
          ]}
        />
      ) : (
        <Text style={[textStyles, Styles.textColor[props.theme]]}>
          {props.value}
        </Text>
      )}
    </View>
  );
};

const Styles = {
  textSm: tw`text-lg leading-none font-medium text-gray-600`,
  textMd: tw`text-xl leading-none font-medium text-gray-800`,
  textColor: {
    default: tw`text-gray-800`,
    secondary: tw`text-gray-600`,
    danger: tw`text-error`,
  },
};

InvoiceInfoRow.defaultProps = {
  size: 'md',
  theme: 'default',
  valueAsInput: false,
};
