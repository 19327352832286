import React from 'react';
import { View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { StoreAreaNavigation } from './store-area-navigation';
import { StoreTables } from './store-tables';
import QuerySuspense from '../../../api/query-suspense';
import { useStoreAreaQueries } from '../../../api/hooks/cash-register/store-area/use-store-area-queries';
import SplashScreen from '../../splash/splash.screen';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList } from '../../../routes';

interface StoreAreaScreenProps {
  route: RouteProp<AppStackParamList, 'StoreArea' | 'Root'>;
}

export const StoreAreaScreen = (props: StoreAreaScreenProps) => {
  // Hooks
  // useProductCategoryQueries();
  const { fetchStoreAreas } = useStoreAreaQueries();
  // params always string
  const storeAreaId = props.route.params?.store_area_id;

  return (
    <View style={tw`flex-1 bg-white`}>
      <QuerySuspense
        query={fetchStoreAreas}
        loadingComponent={<SplashScreen />}
        hideRefetchLoading={true}
        onSuccess={storeAreas => {
          const currentStoreArea = storeAreaId
            ? storeAreas.find(storeArea => storeArea.id === +storeAreaId)
            : storeAreas[0];

          return (
            <>
              <StoreTables storeArea={currentStoreArea} />

              <StoreAreaNavigation
                storeAreas={storeAreas}
                activeStoreAreaId={currentStoreArea?.id}
              />
            </>
          );
        }}
      />
    </View>
  );
};
