import React from 'react';
import { View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import {
  ArrowDownRightSVG,
  ArrowUpRightSVG,
  MinusSvg,
  PlusSvg,
} from '../../../../assets/svg';
import { formatCurrency } from '../../../utils/helper';

interface CashbookSummaryItemProps {
  text: string;
  type: 'entrance' | 'exit';
  amount: number;
}

export const CashbookSummaryItem: React.FC<
  CashbookSummaryItemProps
> = props => {
  const isEntrance = props.type === 'entrance';
  const textColor = isEntrance ? 'text-success' : 'text-error';

  return (
    <View style={tw`flex-row justify-between items-center gap-x-3`}>
      <View style={tw`flex-row gap-x-2 items-center`}>
        {props.type === 'entrance' ? (
          <ArrowUpRightSVG stroke={tw.color(textColor)} />
        ) : (
          <ArrowDownRightSVG stroke={tw.color(textColor)} />
        )}
        <Text style={tw`text-lg leading-[130%] font-medium ${textColor}`}>
          {props.text}
        </Text>
      </View>

      <View style={tw`flex-row gap-x-0.5 items-center`}>
        <View style={tw`h-6 w-6 m-auto`}>
          {isEntrance ? (
            <PlusSvg
              style={tw`m-auto h-4 w-4`}
              strokeWidth={1}
              stroke={tw.color(textColor)}
            />
          ) : (
            <MinusSvg
              style={tw`m-auto h-4 w-4`}
              strokeWidth={1}
              stroke={tw.color(textColor)}
            />
          )}
        </View>

        <Text style={tw`text-lg leading-[130%] font-medium ${textColor}`}>
          {formatCurrency(props.amount)}
        </Text>
      </View>
    </View>
  );
};
