import React, { useState } from 'react';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { ModalSetEstimation } from '../../../components/shared/modals/modal-set-estimation';
import { useCartQueries } from '../../../api/hooks/cash-register/carts/use-cart-queries';
import { isTakeaway, useT } from '../../../utils/helper';
import { tw } from '../../../utils/tailwind';
import { useRoute } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';

interface CartFormActionBarProps {
  cartId: number | null;
  onChangeEstimation: (estimation: number) => void;
  onSubmit: () => void;
}

export const CartFormActionBar = (props: CartFormActionBarProps) => {
  // Hooks
  const t = useT();
  const route = useRoute<AppStackScreenProps<'CartNew'>['route']>();
  const { getCart } = useCartQueries(props.cartId);
  const [isModalSetEstimationVisible, setIsModalSetEstimationVisible] =
    useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  // Other variables
  const estimationOptions =
    getCart.data?.actions.set_processing_time_options || [];
  const shouldButtonBeDisabled = requesting || !props.cartId;

  // Callbacks
  const onEstimationSet = (estimation: number) => {
    props.onChangeEstimation(estimation);
    setIsModalSetEstimationVisible(false);
  };
  const handleSubmit = () => {
    setRequesting(true);
    props.onSubmit();
  };

  return (
    <>
      {/** Modal */}
      <ModalSetEstimation
        isVisible={isModalSetEstimationVisible}
        onModalHide={() => setIsModalSetEstimationVisible(false)}
        options={estimationOptions}
        onEstimationSet={onEstimationSet}
      />

      {/** Action bar */}
      <FooterBar style={tw`gap-0`}>
        {isTakeaway(route.params.cart_type) ? (
          <FooterBarItem
            text={t('setEstimation')}
            active={false}
            disabled={shouldButtonBeDisabled}
            onPress={() => setIsModalSetEstimationVisible(true)}
            buttonStyles={tw`md:basis-1/2 basis-1/1`}
          />
        ) : null}
        <FooterBarItem
          text={t('createNewOrder')}
          active={true}
          loading={requesting}
          disabled={shouldButtonBeDisabled}
          onPress={handleSubmit}
          buttonStyles={tw`md:basis-1/2 basis-1/1`}
        />
      </FooterBar>
    </>
  );
};
