import React, { useState } from 'react';
import { ModalFullBase } from '../../modals/modal-full-base';
import {
  View,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { tw } from '../../../utils/tailwind';
import { chunkArray, twCb, useT } from '../../../utils/helper';
import { SetProcessingTimeOption } from '../../../api/types/cash-register/cart-types';
import { ButtonModalOption } from '../form/button/button-modal-option';
import { calculateOpacity } from '../../../utils/style.helper';
import { getModalBaseClasses } from '../../../utils/conditional-style';
import { TouchableOpacity as Button } from '../../forms';
import { calculateMinutesDifferenceFromNow } from '../../../utils/cash-register/cart-form.helper';

interface ModalSetEstimationProp {
  isVisible: boolean;
  options: SetProcessingTimeOption[];
  onEstimationSet: (estimation: number) => void;
  onModalHide: () => void;
  onBackdropPress?: () => void;
  onBackButtonPress?: () => void;
}

export const ModalSetEstimation = (props: ModalSetEstimationProp) => {
  // Hooks
  const [estimation, setEstimation] = useState<number>();
  const [customEstimation, setCustomEstimation] = useState<string>('');
  const t = useT();

  // Callbacks
  const onEstimationOptionPress = (value: number) => {
    setEstimation(value);
    setCustomEstimation('');
  };

  const onCustomEstimationChange = (value: string) => {
    setEstimation(undefined);
    setCustomEstimation(value);
  };

  const onSubmit = () => {
    if (estimation) {
      props.onEstimationSet(estimation);
    } else if (customEstimation) {
      props.onEstimationSet(
        calculateMinutesDifferenceFromNow(customEstimation),
      );
    }
  };

  const onModalHide = () => {
    setEstimation(undefined);
    setCustomEstimation('');
    props.onModalHide();
  };

  return (
    <ModalFullBase
      isVisible={props.isVisible}
      onModalHide={props.onModalHide}
      onBackdropPress={props.onBackdropPress || props.onModalHide}
      type="simple"
      onBackButtonPress={
        props.onBackButtonPress || props.onModalHide || (() => {})
      }
      style="m-0">
      <View style={tw`flex flex-col h-full`}>
        <ScrollView
          style={tw`p-4`}
          contentContainerStyle={tw`${getModalBaseClasses()} shadow-none p-0 gap-y-6 grow justify-center`}>
          {/* Close modal button */}
          <View style={tw`flex-row-reverse w-full`}>
            <Button
              label={t('back')}
              onPress={onModalHide}
              theme="white"
              extraStyle="w-full border-gray-800 mb-6"
              extraLabelStyle="text-gray-800 font-medium"
            />
          </View>

          <Text
            style={tw`text-center text-[28px] leading-none font-bold text-gray-800`}>
            {t('whenWillTheOrderBeReady')}
          </Text>

          <Text
            style={tw`justify-self-center py-5 px-3 w-full text-center text-xl leading-none font-medium
                        text-gray-800 rounded-lg bg-gray-100 shadow-sm`}>
            {t('customerIsAlreadyWaiting')}
          </Text>

          <View style={tw`gap-y-4 w-full`}>
            <View>
              {chunkArray(props.options, 3).map((options, index) => (
                <View key={index} style={tw`flex-row gap-3 mb-3 `}>
                  {options.map((option, idx) => (
                    <ButtonModalOption
                      key={idx}
                      label={option.name}
                      onPress={() => onEstimationOptionPress(option.value)}
                      extraStyle={`${twCb(
                        'flex-1',
                        estimation === option.value
                          ? 'bg-green-600'
                          : `bg-gray-${calculateOpacity(index)}`,
                      )}`}
                    />
                  ))}
                </View>
              ))}
            </View>

            <TextInput
              onChangeText={onCustomEstimationChange}
              value={customEstimation}
              placeholder="Custom time (11:00)"
              multiline={false}
              placeholderTextColor={tw.color('gray-400')}
              style={tw`justify-self-center mt-2.5 py-4 pl-4 pr-3 w-full text-xl
                        leading-none font-medium rounded-lg border border-solid
                        border-gray-400`}
            />
          </View>
        </ScrollView>

        <TouchableOpacity
          style={tw`justify-center items-center px-3 py-6 bg-success`}
          onPress={onSubmit}>
          <Text style={tw`text-2xl leading-none font-medium text-white`}>
            {t('submit')}
          </Text>
        </TouchableOpacity>
      </View>
    </ModalFullBase>
  );
};
