import React from 'react';
import { FlatList, View, useWindowDimensions } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useStoreAreaTableQueries } from '../../../api/hooks/cash-register/store-area/use-store-area-table-queries';
import QuerySuspense from '../../../api/query-suspense';
import SplashScreen from '../../splash/splash.screen';
import { StoreTable } from './store-table';
import { HEADER_HEIGHT } from '../../../utils/helper';
import { useWindowSize } from '../../../hooks/use-window-size';
import { StoreTablesResponse } from '../../../api/types/cash-register/store-table-types';
import { StoreArea } from '../../../api/types/cash-register/store-area-types';
import { TakeawayButton } from './takeaway-button';

interface StoreTablesProps {
  storeArea: StoreArea | undefined; // undefined when there are no store area created for the tenant, otherwise it will fallnack to the first store area
}

export const StoreTables = (props: StoreTablesProps) => {
  const { fetchStoreTables } = useStoreAreaTableQueries(props.storeArea?.id);
  const { windowSize } = useWindowSize();
  const { height } = useWindowDimensions();

  const isXs = windowSize === 'xs';

  // Append + button when displaying list of store tables for virtual store area
  // the + button will rendered differently, and when clicked, it will open the modal takeaway
  const withTakeaway = (storeTables: StoreTablesResponse) => {
    return props.storeArea?.virtual
      ? [
          {
            id: -1,
            name: '+',
            store_area: props.storeArea,
          },
          ...storeTables,
        ]
      : storeTables;
  };

  return (
    <View style={tw`grow max-h-[${height - 67 - HEADER_HEIGHT}px]`}>
      <QuerySuspense
        query={fetchStoreTables}
        loadingComponent={<SplashScreen />}
        hideRefetchLoading={true}
        onSuccess={storeTables => (
          <FlatList
            key={windowSize}
            data={withTakeaway(storeTables)}
            keyExtractor={item => item.id.toString()}
            numColumns={isXs ? 2 : 3}
            renderItem={({ item }) =>
              item.id === -1 ? (
                <TakeawayButton />
              ) : (
                <StoreTable key={item.id} storeTable={item} />
              )
            }
            contentContainerStyle={tw`mx-auto max-w-[500px] w-full md:py-10 py-4 justify-center pl-4 pr-4`}
            columnWrapperStyle={tw`sm:gap-6 gap-4 justify-center`}
            ItemSeparatorComponent={() => <View style={tw`sm:h-6 h-4`} />}
          />
        )}
      />
    </View>
  );
};
