import { useEffect, useRef } from 'react';
import { ChannelPayload } from '../types';

// Needed for @rails/actioncable
// https://github.com/rails/rails/pull/36652#issuecomment-656151080
// @ts-ignore
global.addEventListener = () => {};
// @ts-ignore
global.removeEventListener = () => {};

export default function useChannel(actionCable) {
  const channelRef = useRef();

  type SubscriptionInfo = {
    channel: string;
  };

  type SubscriptionInfoCallback = {
    received?: (data: any) => void;
    initialized?: () => void;
    connected?: () => void;
    disconnected?: () => void;
  };

  const subscribe = (
    data: SubscriptionInfo,
    callbacks: SubscriptionInfoCallback,
  ) => {
    console.log(`useChannel - INFO: Connecting to ${JSON.stringify(data)}`);
    const channel = actionCable.subscriptions.create(data, {
      received: (payload: ChannelPayload) => {
        if (callbacks.received) {
          callbacks.received(payload);
        }
      },
      initialized: () => {
        console.log('useChannel - INFO: Init ' + data.channel);
        if (callbacks.initialized) {
          callbacks.initialized();
        }
      },
      connected: () => {
        console.log('useChannel - INFO: Connected to ' + data.channel);
        if (callbacks.connected) {
          callbacks.connected();
        }
      },
      disconnected: () => {
        console.log('useChannel - INFO: Disconnected');
        if (callbacks.disconnected) {
          callbacks.disconnected();
        }
      },
    });
    channelRef.current = channel;
  };

  const unsubscribe = () => {
    if (channelRef.current) {
      console.log(
        'useChannel - INFO: Unsubscribing from ' +
          channelRef.current.identifier,
      );
      actionCable.subscriptions.remove(channelRef.current);
      channelRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, []);

  return { subscribe, unsubscribe };
}
