import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList, AppStackScreenProps } from '../../../routes';
import { useCartMutations } from '../../../api/hooks/cash-register/carts/use-cart-mutations';
import { CartForm } from './cart-form';
import { LineItemAttributes } from '../../../api/types/cash-register/line-item-types';
import { useLineItemMutations } from '../../../api/hooks/cash-register/line-items/use-line-item-mutations';
import { buildLineItemFromParams } from '../../../utils/cash-register/line-item.helper';
import { Product } from '../../../api/types/cash-register/product-category-types';
import { useQueryClient } from '@tanstack/react-query';
import { CartType } from '../../../api/types/cash-register/cart-types';
import { useNavigationHelper } from '../../../hooks/use-navigation-helper';

interface CartNewScreenProps {
  route: RouteProp<AppStackParamList, 'CartNew'>;
  navigation: AppStackScreenProps<'CartNew'>['navigation'];
}

export const CartNewScreen = (props: CartNewScreenProps) => {
  // Hooks
  const { createLineItem, updateLineItemQueryData } = useLineItemMutations();
  const { createCart } = useCartMutations();
  const queryClient = useQueryClient();
  const { removeLastRoute } = useNavigationHelper();
  // Other variables
  const cartType = props.route.params.cart_type as CartType;
  // Callbacks
  const onFormSubmit = (attributes: LineItemAttributes, product: Product) => {
    if (props.route.params.cart_type) {
      createCart
        .mutateAsync({
          store_table_id: props.route.params.store_table_id,
          customer_id: props.route.params.customer_id,
          cart_type: cartType,
        })
        .then(cart => {
          const params = {
            ...attributes,
            cart_id: cart.id,
          };

          // Optimistic update
          updateLineItemQueryData(buildLineItemFromParams(params, product));
          // Create line item
          createLineItem.mutateAsync(params).then(_data => {
            // Refetch store tables
            queryClient.refetchQueries({
              queryKey: ['storeAreas', cart.store_area_id, 'storeTables'],
            });
            // until here we have 2 routes in the stack
            // /carts/new?params and /carts/new?params+category_id
            // remove one and replace the other, so when user press back button
            // it will go back to the table selection screen.
            removeLastRoute();
            // Now we have the ID of the cart, navigate to the cart show screen
            props.navigation.replace('CartShow', { cart_id: cart.id });
          });
        });
    } else {
      props.navigation.goBack();
    }
  };

  return (
    <CartForm
      cartType={cartType}
      productCategoryId={props.route.params.product_category_id}
      onFormSubmit={onFormSubmit}
    />
  );
};
