import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { formatCurrency, useT } from '../../../utils/helper';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';
import { useFiscalTransactionPaymentsQueries } from '../../../api/hooks/cash-register/fiscal-transaction-payments/use-fiscal-transaction-payments-queries';
import QuerySuspense from '../../../api/query-suspense';
import { BinSVG } from '../../../../assets/svg';
import { ModalRemovePayment } from './modal-remove-payment';
import { FiscalTransactionPayment } from '../../../api/types/cash-register/fiscal-transaction-payment.types';

enum ModalVisible {
  RemovePayment = 'removePayment',
  None = 'none',
}
interface ModalEditPaymentProps {
  invoiceId: number;
  isVisible: boolean;
  onModalHide: () => void;
}

export const ModalEditPayment = (props: ModalEditPaymentProps) => {
  // Standard Hooks
  const t = useT();
  // Queries
  const { fetchFiscalTransactionPayments } =
    useFiscalTransactionPaymentsQueries(props.invoiceId);
  // States
  const [modalVisible, setModalVisible] = useState<ModalVisible>();
  const [selectedPayment, setSelectedPayment] =
    useState<FiscalTransactionPayment | null>(null);

  const handleRemovePayment = (
    payment: FiscalTransactionPayment,
    index: number,
  ) => {
    setModalVisible(ModalVisible.RemovePayment);
    setSelectedPayment(
      Object.assign({}, payment, {
        payment_method: `${index + 1}. ${t(payment.payment_method)}`,
      }),
    );
  };
  return (
    <>
      <ModalConfirmation
        isVisible={props.isVisible}
        content={t('edit').toString()}
        hideCancel={true}
        onModalHide={props.onModalHide}>
        <QuerySuspense
          query={fetchFiscalTransactionPayments}
          onSuccess={data => (
            <View style={tw`w-full`}>
              {data.fiscal_transaction_payments.map((payment, index) => (
                <View
                  key={payment.id}
                  style={[
                    tw`flex-row justify-between py-2 border-0 border-b-[2px] border-solid border-gray-300`,
                    payment.sync ? tw`opacity-50` : tw`opacity-100`,
                  ]}>
                  {/* Payment Method */}
                  <Text style={tw`text-lg leading-none my-auto text-gray-700`}>
                    {index + 1}. {t(payment.payment_method)}
                  </Text>

                  <View style={tw`flex-row gap-3`}>
                    {/* Total */}
                    <Text
                      style={tw`text-lg font-medium leading-none my-auto text-gray-700`}>
                      {formatCurrency(payment.amount)}
                    </Text>

                    {/* Remove Button */}
                    <TouchableOpacity
                      onPress={() => {
                        handleRemovePayment(payment, index);
                      }}
                      disabled={payment.sync}
                      style={tw`bg-gray-100 p-3 rounded-lg`}>
                      <BinSVG stroke={tw.color('gray-700')} strokeWidth={1.8} />
                    </TouchableOpacity>
                  </View>
                </View>
              ))}
            </View>
          )}
        />
      </ModalConfirmation>

      <ModalRemovePayment
        invoiceId={props.invoiceId}
        payment={selectedPayment}
        isVisible={modalVisible === ModalVisible.RemovePayment}
        onModalHide={() => setModalVisible(ModalVisible.None)}
        onPaymentRemoved={() => props.onModalHide()}
      />
    </>
  );
};
