import { useEffect } from 'react';
import env from '../../env';
import { TenantDeviceChannelPayload } from '../types';
import useActionCable from './use-action-cable';
import useChannel from './use-channel';
import { AuthResponse } from '../../api/types/auth-types';
import { useAuthMutations } from '../../api/hooks/auth/use-auth-mutations';
import { useAuth } from '../../contexs/auth-context';
import { decryptSignInToken } from '../../utils/decrypt-sign-in-token';

export default function useTenantDeviceChannel(serialNumber: string) {
  const { actionCable } = useActionCable(
    `${env.WEBSOCKET_URL}?encrypted_serial_number=${serialNumber}`,
  );

  const { subscribe, unsubscribe } = useChannel(actionCable);
  const { performLogin } = useAuthMutations();
  const { signIn } = useAuth();

  useEffect(() => {
    subscribe(
      { channel: 'TenantDeviceChannel' },
      {
        received: (payload: TenantDeviceChannelPayload) => {
          const { token, iv } = payload.body.sign_in_token;

          // decrypt the sign in token and perform login
          decryptSignInToken(token, iv).then(signInToken =>
            performLogin
              .mutateAsync({ sign_in_token: signInToken })
              .then((data: AuthResponse) => signIn(data)),
          );
        },
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);
}
