import React from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../utils/tailwind';
import { useT } from '../../utils/helper';

interface CartSummaryProps {
  total: string;
}

export const CartSummary = (props: CartSummaryProps) => {
  const t = useT();

  return (
    <View
      style={tw`flex-row justify-between xl:mb-0 mb-2.5 px-3 pt-1 xl:pb-3 pb-[22px] border-0 xl:border-b-0 border-b-2 border-solid border-gray-100`}>
      <Text style={tw`xl:text-lg text-lg font-bold text-gray-900`}>
        {t('total')}
      </Text>
      <Text
        style={tw`xl:text-lg text-lg xl:pr-0 text-right font-bold text-gray-900`}>
        {props.total}
      </Text>
    </View>
  );
};
