import React from 'react';
import { tw } from '../../utils/tailwind';
import { StoreControl } from './components/store-control';
import { ModalFullBase } from './modal-full-base';
import { useTenant } from '../../contexs/tenant-context';

interface ModalStoreControlProp {
  isVisible: boolean;
  onModalHide: () => void;
}

export const ModalStoreControl = (props: ModalStoreControlProp) => {
  const { tenant } = useTenant();

  return (
    <ModalFullBase
      isVisible={props.isVisible}
      backdropColor={tw.style('bg-white').backgroundColor.toString()}
      backdropOpacity={1}
      onBackdropPress={props.onModalHide}
      onBackButtonPress={props.onModalHide}
      onModalHide={props.onModalHide}
      coverScreen={true}>
      <StoreControl
        openingState={tenant.opening_state}
        onModalHide={props.onModalHide}
      />
    </ModalFullBase>
  );
};
