import React from 'react';
import { InvoicePaymentSummary } from './invoice-payment-summary';
import { InvoiceResponse } from '../../../api/types/cash-register/invoice.types';
import { InvoiceGivenAndReturn } from './invoice-given-and-return';

interface InvoicePaymentViewPayProps {
  invoice: InvoiceResponse;
  unPaidTotal: number;
  onPaymentAmountChanged: (amount: number) => void;
}

export const InvoicePaymentViewPay = (props: InvoicePaymentViewPayProps) => {
  return (
    <>
      <InvoicePaymentSummary
        lineItems={props.invoice.line_items}
        invoiceId={props.invoice.id}
        total={props.invoice.total}
        editable={!props.invoice.invoice_finalized}
      />

      <InvoiceGivenAndReturn
        i18nKeyPrefix="payment"
        unPaidTotal={props.unPaidTotal}
        paymentAmount={props.unPaidTotal}
        tipAmount={props.invoice.tip_amount}
        disabled={props.invoice.invoice_finalized}
        onChange={props.onPaymentAmountChanged}
      />
    </>
  );
};
