import React from 'react';

import {
  TenantContext,
  TenantWithOpeningState,
} from '../contexs/tenant-context';

interface TenantProviderProps {
  tenant: TenantWithOpeningState;
  children: React.ReactNode;
}

export const TenantProvider = (props: TenantProviderProps) => {
  const adminTenantPath = (path: string) =>
    `/admin/tenants/${props.tenant.slug}/${path}`;

  return (
    <TenantContext.Provider
      value={{ tenant: props.tenant, adminTenantPath: adminTenantPath }}>
      {props.children}
    </TenantContext.Provider>
  );
};
