import { createContext, useContext } from 'react';

type WorkflowListenerContextType = {
  listen: (workflow: string, callback: (arg?: any) => void) => void;
};

const WorkflowListenerContext = createContext<WorkflowListenerContextType>({
  listen: () => {},
});

function useWorkflowListener(): WorkflowListenerContextType {
  const context = useContext(WorkflowListenerContext);

  if (!context) {
    throw new Error(
      'useWorkflowListener must be used within an WorkflowListenerProvider',
    );
  }

  return context;
}

export { WorkflowListenerContext, useWorkflowListener };
