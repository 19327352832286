import React, { useState } from 'react';
import { InvoiceSplitPaymentButtons } from './invoice-split-payment-buttons';
import { InvoiceGivenAndReturn } from './invoice-given-and-return';
import { InvoiceResponse } from '../../../api/types/cash-register/invoice.types';

interface InvoicePaymentViewCalculatorProps {
  invoice: InvoiceResponse;
  unPaidTotal: number;
}

export const InvoicePaymentViewCalculator = (
  props: InvoicePaymentViewCalculatorProps,
) => {
  // States
  const [paymentSplitBy, setPaymentSplitBy] = useState(1);

  const paymentAmount = props.unPaidTotal / paymentSplitBy;

  return (
    <InvoiceGivenAndReturn
      i18nKeyPrefix="calculator"
      unPaidTotal={props.unPaidTotal}
      paymentAmount={paymentAmount}>
      <InvoiceSplitPaymentButtons onClick={setPaymentSplitBy} />
    </InvoiceGivenAndReturn>
  );
};
