import { useFormik } from 'formik';
import * as yup from 'yup';

const transactionilterFormSchema = yup.object().shape({
  store_table_id_eq: yup.number(),
  cashier_id_eq: yup.number(),
  date: yup.string(),
  invoice_type_eq: yup.string(),
});

const useTransactionFilterForm = () => {
  type FormContents = yup.InferType<typeof transactionilterFormSchema>;

  const form = useFormik({
    initialValues: {} as FormContents,
    validationSchema: transactionilterFormSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  return form;
};

export { useTransactionFilterForm };
