import React, { useState } from 'react';
import { View } from 'react-native';
import { useT } from '../../../../utils/helper';
import { SelectList } from '../../../forms/select-list';
import { tw } from '../../../../utils/tailwind';
import { CustomerSearchInputOption } from './customer-search-input-option';
import { useCustomerQueries } from '../../../../api/hooks/cash-register/customers/use-customer-queries';
import { SearchSvg } from '../../../../../assets/svg';
import { Customer } from '../../../../api/types/cash-register/customer-types';

interface CustomerSearchInputProps {
  onSelected: (option: Customer) => void;
}

export const CustomerSearchInput = (props: CustomerSearchInputProps) => {
  // Standard hooks
  const t = useT();
  // States & queries
  const [query, setQuery] = useState<string>();
  const { fetchCustomers } = useCustomerQueries({
    q: {
      name_or_phone_or_street_or_street_number_or_zipcode_or_city_i_cont: query,
    },
  });

  return (
    <View style={tw`z-10`}>
      <SelectList
        setSelected={selectedOption => props.onSelected(selectedOption)}
        data={[]}
        dropdownStyles={tw`top-[60px]`}
        query={fetchCustomers}
        labelKey="name"
        valueKey="id"
        placeholder={t('search').toString()}
        onChangeQuery={setQuery}
        optionRenderer={(option, onSelected) => (
          <CustomerSearchInputOption
            key={option.id}
            option={option}
            highlight={query}
            onSelected={() => {
              props.onSelected(option);
              onSelected();
            }}
          />
        )}
        rightIcon={
          <SearchSvg
            style={tw`w-[20px] h-[20px]`}
            stroke={tw.color('gray-400')}
            strokeWidth={1.5}
          />
        }
      />
    </View>
  );
};
