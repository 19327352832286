import request from '../../../request';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  OrdersParams,
  OrdersResponse,
} from '../../../types/cash-register/order-types';

export const useOrderQueries = (params: OrdersParams) => {
  const fetchOrders = useInfiniteQuery(
    ['orders', params],
    async ({ pageParam }) =>
      request({
        method: 'get',
        url: '/store/orders.json',
        params: { ...params, page: pageParam },
      }) as Promise<OrdersResponse>,
    {
      getNextPageParam: (lastPage, _pages) => {
        // Return the next page number if there are more pages
        return lastPage.next_page || false;
      },
    },
  );

  return { fetchOrders };
};
