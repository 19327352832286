import React from 'react';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { useT } from '../../../utils/helper';
import { tw } from '../../../utils/tailwind';

export enum PAYMENT_VIEW {
  PAYMENT = 'payment',
  CALCULATOR = 'calculator',
}

interface InvoicePaymentViewNavigationProps {
  paymentView: PAYMENT_VIEW;
  onPaymentViewChanged: (view: PAYMENT_VIEW) => void;
}

export const InvoicePaymentViewNavigation = (
  props: InvoicePaymentViewNavigationProps,
) => {
  const t = useT('screens', { keyPrefix: 'Invoice.paymentViewNavigation' });

  return (
    <FooterBar>
      {['payment', 'calculator'].map((view, index) => (
        <FooterBarItem
          text={t(view)}
          key={index}
          active={view === props.paymentView}
          buttonStyles={ButtonStyle[index]}
          onPress={() => props.onPaymentViewChanged(view as PAYMENT_VIEW)}
        />
      ))}
    </FooterBar>
  );
};

const ButtonStyle = [tw`rounded-l-lg`, tw`rounded-r-lg`];
