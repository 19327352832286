import React, { useState } from 'react';
import { View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useT } from '../../../utils/helper';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';
import { useInvoice } from '../../../contexs/invoice-context';
import { TouchableOpacity as Button } from '../../forms';
import { useInvoiceMutations } from '../../../api/hooks/cash-register/invoices/use-invoice-mutation';
import { Input } from '../forms/input';
import { InvoiceCancelResponse } from '../../../api/types/cash-register/invoice.types';
import { SelectList } from '../../forms/select-list';
import { CancellationReasons } from '../../../utils/constants';

const CustomReason = 'customReason';

interface ModalCancelInvoiceProps {
  isVisible: boolean;
  onModalHide: () => void;
}

export const ModalCancelInvoice = (props: ModalCancelInvoiceProps) => {
  // Standard Hooks
  const t = useT('screens', { keyPrefix: 'Invoices' });
  // Queries
  const { cancelInvoice, onCancelInvoiceSuccess } = useInvoiceMutations();
  // States
  const [reason, setReason] = useState<string>();
  const [isReasonInputVisible, setIsReasonInputVisible] = useState(false);
  // Custom Hooks
  const { invoice } = useInvoice();
  // Callback
  const handleCancelSuccess = (data: InvoiceCancelResponse) => {
    props.onModalHide();
    onCancelInvoiceSuccess(data);
  };

  const handleReasonSelect = (selectedReasonId: string) => {
    if (selectedReasonId === CustomReason) {
      setIsReasonInputVisible(true);
    } else {
      setReason(selectedReasonId);
      setIsReasonInputVisible(false);
    }
  };

  const getCancellationReaons = () => {
    return [
      ...CancellationReasons.map(r => ({
        name: t(`cancellationReasons.${r}`).toString(),
        id: r,
      })),
      {
        name: t('enterReason').toString(),
        id: CustomReason,
      },
    ];
  };

  if (!invoice) {
    return null;
  }

  return (
    <ModalConfirmation
      isVisible={props.isVisible}
      content={t('cancelConfirmationMessage', {
        invoiceNumber: invoice.invoice_number,
      }).toString()}
      onModalHide={props.onModalHide}>
      <View style={tw`gap-3 mb-3 w-full z-5`}>
        <View style={tw`z-1`}>
          <SelectList
            setSelected={selectListOption =>
              handleReasonSelect(selectListOption.id)
            }
            data={getCancellationReaons()}
            labelKey="name"
            valueKey="id"
            placeholder={t('cancellationReason').toString()}
            dropdownStyles={tw`top-[60px]`}
          />
        </View>
        {isReasonInputVisible ? (
          <Input
            onChangeText={text => setReason(text)}
            autoFocus={true}
            placeholder={t('cancellationReason').toString()}
          />
        ) : null}
        <Button
          label={t('cancelAndReturnInvoice')}
          disabled={!reason}
          disableOnPress={true}
          onPress={() =>
            cancelInvoice
              .mutateAsync({
                id: invoice.id,
                return_items_to_table: true,
                reason: reason!,
              })
              .then(handleCancelSuccess)
          }
          theme="white"
          extraStyle="py-5 bg-warning"
          extraLabelStyle="leading-none font-medium text-white normal-case text-center"
        />
        <Button
          label={t('cancelInvoice')}
          disabled={!reason}
          disableOnPress={true}
          onPress={() =>
            cancelInvoice
              .mutateAsync({ id: invoice.id, reason: reason! })
              .then(handleCancelSuccess)
          }
          theme="white"
          extraStyle="py-5 bg-error"
          extraLabelStyle="leading-none font-medium text-white normal-case text-center"
        />
      </View>
    </ModalConfirmation>
  );
};
