import React from 'react';
import { ModalBase } from '../../shared/modals/modal-base';
import { useT } from '../../../utils/helper';
import { View } from 'react-native';
import { TouchableOpacity as Button } from '../../forms';
import { tw } from '../../../utils/tailwind';
import { CartType } from '../../../api/types/cash-register/cart-types';

interface ModalTakeAwayProps {
  isVisible: boolean;
  onModalHide: () => void;
  onCartTypeSelected: (cartType: CartType) => void;
}

export const ModalTakeaway = (props: ModalTakeAwayProps) => {
  // Hooks
  const t = useT(); // common
  const tCashRegister = useT('cashRegister');

  const renderButton = (cartType: CartType) => {
    return (
      <Button
        label={t(cartType)}
        onPress={() => props.onCartTypeSelected(cartType)}
        extraStyle="flex-1 px-3"
        extraLabelStyle="md:text-xl text-base leading-none"
      />
    );
  };

  return (
    <ModalBase
      isVisible={props.isVisible}
      onModalHide={props.onModalHide}
      title={tCashRegister('whatKindOfTakeaway').toString()}>
      <View style={tw`flex-row gap-3 w-full`}>
        {renderButton('pickup')}
        {renderButton('delivery')}
      </View>
    </ModalBase>
  );
};
