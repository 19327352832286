import { useEffect, useMemo } from 'react';
import { createConsumer } from '@rails/actioncable';

export default function useActionCable(url: string) {
  const actionCable = useMemo(() => createConsumer(`${url}`), []);

  useEffect(() => {
    return () => {
      actionCable.disconnect();
    };
  }, []);

  return { actionCable };
}
