import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import {
  CashRegisterInvoiceWorkflowParams,
  CashRegisterInvoiceWorkflowResponse,
} from '../../../types/cash-register/cash-register-invoice-workflow.types';

export const useCashRegisterInvoiceWorkflowQueries = (
  params: CashRegisterInvoiceWorkflowParams,
) => {
  const fetchCashRegisterInvoiceWorkflow = useQuery(
    ['cashRegisterInvoiceWorkflow', params.id],
    async () =>
      request({
        method: 'get',
        url: `/store/cash_register_workflows/cash_register_invoice/${params.id}.json`,
        params,
      }) as Promise<CashRegisterInvoiceWorkflowResponse>,
  );

  return { fetchCashRegisterInvoiceWorkflow };
};
