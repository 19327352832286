import React, { useRef, useState } from 'react';
import { WorkflowListenerContext } from '../contexs/workflow-listener-context';
import { WorkflowListener } from '../components/workflow-listener';

interface WorkflowListenerProviderProps {
  children: React.ReactNode;
}

export const WorkflowListenerProvider = (
  props: WorkflowListenerProviderProps,
) => {
  const [channels, setChannels] = useState<string[]>([]);
  const callbacksRef = useRef<Map<string, (arg?: any) => void>>(new Map());

  const handleMassage = (channel: string, message: any) => {
    const callback = callbacksRef.current.get(channel);

    if (callback) {
      callback(message);
    }
  };

  const listen = (workflow: string, callback: (arg?: any) => void) => {
    setChannels(prevChannels => [...prevChannels, workflow]);
    callbacksRef.current.set(workflow, callback);
  };

  return (
    <WorkflowListenerContext.Provider value={{ listen }}>
      {props.children}
      {channels.map(channel => (
        <WorkflowListener
          key={channel}
          channel={channel}
          onMessage={message => handleMassage(channel, message)}
        />
      ))}
    </WorkflowListenerContext.Provider>
  );
};
