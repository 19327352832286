import React, { useMemo, useState } from 'react';
import { View, Text, Switch } from 'react-native';
import { OrderSessionItem } from './order-session-item';
import { tw } from '../../../utils/tailwind';
import { formatCurrency, useT } from '../../../utils/helper';
import { LineItem } from '../../../api/types/cash-register/order-session.types';
import { SelectedLineItem } from '../../../contexs/payment-context';

interface OrderSessionItemsProps {
  lineItems: LineItem[];
  onChange: (lineItems: SelectedLineItem[]) => void;
  displayTotal?: boolean;
}

export const OrderSessionItems = (props: OrderSessionItemsProps) => {
  // Hooks
  const t = useT();
  const [lineItems, setLineItems] = useState<SelectedLineItem[]>(
    props.lineItems.map(lineItem =>
      Object.assign({}, lineItem, { selected: false, selectedQuantity: 0 }),
    ),
  );
  // Other Constants
  const isAllSelected = useMemo(
    () => lineItems.every(l => l.selected && l.selectedQuantity === l.quantity),
    [lineItems],
  );
  const totalSelected = useMemo(
    () =>
      lineItems.reduce((acc, l) => {
        return l.selected && l.selectedQuantity
          ? acc + l.single_price * l.selectedQuantity
          : acc;
      }, 0),
    [lineItems],
  );
  // Callbacks
  // Update selected line items quantity or selected status
  const updateSelectedLineItems = (
    id: number,
    attr: Partial<SelectedLineItem>,
  ) => {
    const lineItem = lineItems.find(l => l.id === id);

    if (lineItem) {
      const updatedLineItem = lineItems.map(l =>
        l.id === id ? Object.assign({}, l, attr) : l,
      );

      setLineItems(updatedLineItem);
      props.onChange(updatedLineItem.filter(l => l.selected));
    }
  };
  // Toggle all line items selected status
  const toggleSelectedAll = (selected: boolean) => {
    const updatedLineItem: SelectedLineItem[] = lineItems.map(l =>
      Object.assign({}, l, {
        selected: selected,
        selectedQuantity: selected ? l.quantity : 0,
      }),
    );

    setLineItems(updatedLineItem);
    props.onChange(updatedLineItem.filter(l => l.selected));
  };

  return (
    <>
      <View
        style={tw`flex-row justify-between items-center gap-x-3 py-3 px-[18px] bg-gray-50 min-h-[56px]`}>
        <Text style={tw`text-base leading-none font-medium text-gray-500`}>
          {t('selectAllPositions')}
        </Text>

        <Switch
          trackColor={{
            true: tw.color('green-600'),
            false: tw.color('gray-300'),
          }}
          style={tw`bg-gray-100`}
          thumbColor={tw.color('white')}
          value={isAllSelected}
          onValueChange={() => {
            toggleSelectedAll(!isAllSelected);
          }}
        />
      </View>

      {lineItems.map(lineItem => (
        <OrderSessionItem
          key={lineItem.id}
          selected={lineItem.selected}
          maxQuantity={lineItem.quantity}
          onPress={() =>
            updateSelectedLineItems(lineItem.id, {
              selected: !lineItem.selected,
              selectedQuantity: lineItem.selected ? 0 : 1,
            })
          }
          onQuantityChange={quantity => {
            updateSelectedLineItems(lineItem.id, {
              selectedQuantity: quantity,
              selected: quantity > 0,
            });
          }}
          item={{ ...lineItem, quantity: lineItem.selectedQuantity || 0 }}
        />
      ))}

      {props.displayTotal ? (
        <View style={tw`flex-row justify-between gap-x-3 mt-4`}>
          <Text style={tw`text-lg leading-none font-semibold text-gray-800`}>
            {t('total')}:
          </Text>
          <Text style={tw`text-lg leading-none font-semibold text-gray-800`}>
            {formatCurrency(totalSelected)}
          </Text>
        </View>
      ) : null}
    </>
  );
};
