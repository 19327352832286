import { Invoice } from '../../api/types/cash-register/invoice.types';
import { Order } from '../../api/types/cash-register/order-types';

type TransactionDocument = Omit<
  Order | Invoice,
  'created_at' | 'ordered_at'
> & {
  date: string;
  type?: string;
};

export const transactionDocumentHelper = (document: Order | Invoice) => {
  const isOrder = isOrderDocument(document);

  const transactionDocument: TransactionDocument = {
    ...document,
    date: isOrder ? (document as Order).ordered_at : document.created_at,
    type: isOrder ? 'order' : (document as Invoice).invoice_type,
  };

  return {
    isOrder,
    transactionDocument,
  };
};

// Type guard function
const isOrderDocument = (document: Order | Invoice): document is Order => {
  return 'ordered_at' in document;
};
