import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useTenantsMutations } from '../../../api/hooks/tenants/use-tenants-mutations';
import {
  OpeningState,
  Option,
  TurnOffTimeOptions,
} from '../../../api/types/tenants-types';
import { chunkArray, useT } from '../../../utils/helper';
import { TouchableOpacity as Button } from '../../forms';

interface StoreControlProps {
  openingState: OpeningState;
  onModalHide: () => void;
}

export const StoreControl = (props: StoreControlProps) => {
  const t = useT();
  const { setTurnOff } = useTenantsMutations();
  const {
    current_state,
    turn_on,
    general_turn_off_times,
    pickup_turn_off_times,
    delivery_turn_off_times,
    cart_type_pause,
  } = props.openingState;

  const [turnOffOptions, setTurnOffOptions] = useState<TurnOffTimeOptions>(
    general_turn_off_times,
  );

  // pink if active
  const getTurnOffTypeButtonTheme = (options: TurnOffTimeOptions) => {
    return turnOffOptions === options ? 'pink' : 'white';
  };

  const performTurnOff = (path: string) => () => {
    props.onModalHide();

    return setTurnOff.mutate(path);
  };

  let turnOffButtonOptionBgOpacity = 400;

  // section to show puase status and paused until time
  const renderCartTypePause = () => {
    if (!cart_type_pause) {
      return null;
    }

    const date = new Date(cart_type_pause.paused_until);
    const pad = (string: number) => String(string).padStart(2, '0');

    return (
      <View
        style={tw`mb-8 pb-8 border-0 border-b-[1px] border-solid border-gray-100`}>
        <Text style={tw`mb-4 text-center text-gray-500`}>
          {cart_type_pause.message}
        </Text>

        <Text style={tw`mb-4 text-[32px] font-bold text-gray-800 text-center`}>
          {pad(date.getHours())}:{pad(date.getMinutes())}
        </Text>

        <View style={tw`gap-y-3`}>
          <Button
            label={t('resumptionOfOrderAcceptance')}
            onPress={performTurnOff(turn_on.path)}
          />
        </View>
      </View>
    );
  };

  // turn off duration options. E.g. 10 minutes.
  const renderTurnOffButtonOptions = (
    options: Array<Option>,
    groupOptionsIdx: number,
  ) => {
    return (
      <View key={groupOptionsIdx} style={tw`flex-row gap-x-2 mx-1 mb-2 w-full`}>
        {options.map((option, idx) => (
          <Button
            key={idx}
            onPress={performTurnOff(option.path)}
            label={option.name}
            extraStyle={`flex-1 bg-gray-${(turnOffButtonOptionBgOpacity += 100)}`}
          />
        ))}
      </View>
    );
  };

  // run off button group based on cart type
  const renderTurnOffButtons = ({
    closed_for_today,
    options,
  }: TurnOffTimeOptions) => {
    return (
      <>
        {closed_for_today && (
          <>
            <Text
              style={tw`items-start text-center w-full mb-6 text-lg font-bold text-gray-800`}>
              {closed_for_today.turn_off_category}
            </Text>

            <View style={tw`w-full mb-2`}>
              <Button
                label={closed_for_today.name}
                onPress={performTurnOff(closed_for_today.path)}
                extraLabelStyle="normal-case"
              />
            </View>
          </>
        )}

        {options && chunkArray(options, 2).map(renderTurnOffButtonOptions)}
      </>
    );
  };

  return (
    <>
      <Text
        style={tw`lg:text-2xl mb-2 text-lg text-center font-bold text-gray-800`}>
        {t('pauseOrders')}
      </Text>

      {cart_type_pause ? renderCartTypePause() : null}

      {!cart_type_pause ? (
        <View
          style={tw`mb-6 pb-7 border-0 border-b-[1px] border-solid border-gray-100 w-full`}>
          <Text style={tw`mb-4 text-center text-gray-500`}>
            {current_state}
          </Text>
          <View style={tw`gap-x-2`}>
            <Button
              label={turn_on.name}
              onPress={performTurnOff(turn_on.path)}
              extraLabelStyle="normal-case"
            />
          </View>
        </View>
      ) : null}

      <View style={tw`flex-row flex-wrap mb-6 w-full`}>
        <View style={tw`basis-1/2 pr-1`}>
          <Button
            label={t('completely')}
            onPress={() => setTurnOffOptions(general_turn_off_times)}
            theme={getTurnOffTypeButtonTheme(general_turn_off_times)}
            extraStyle="lg:p-3"
          />
        </View>
        <View style={tw`basis-1/2 pl-1`}>
          <Button
            label={t('delivery')}
            onPress={() => setTurnOffOptions(delivery_turn_off_times)}
            theme={getTurnOffTypeButtonTheme(delivery_turn_off_times)}
            extraStyle="lg:p-3"
          />
        </View>
        <View style={tw`basis-1/1 pt-2`}>
          <Button
            label={t('pickup')}
            onPress={() => setTurnOffOptions(pickup_turn_off_times)}
            theme={getTurnOffTypeButtonTheme(pickup_turn_off_times)}
            extraStyle="lg:p-3"
          />
        </View>
      </View>

      {turnOffOptions && renderTurnOffButtons(turnOffOptions)}
    </>
  );
};
