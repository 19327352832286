import React, { ReactNode } from 'react';
import {
  Text,
  TextStyle,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import Modal from 'react-native-modal';
import { tw } from '../../../utils/tailwind';
import { CloseSVG } from '../../../../assets/svg';

export interface ModalBaseProp {
  isVisible: boolean;
  onModalHide?: () => void;
  title?: string;
  titleStyle?: TextStyle;
  children: ReactNode;
  contentContainerStyle?: ViewStyle;
}

export const ModalBase = (props: ModalBaseProp) => {
  const windowDimensions = useWindowDimensions();

  return (
    <Modal
      isVisible={props.isVisible}
      onBackdropPress={props.onModalHide}
      onBackButtonPress={props.onModalHide}
      hideModalContentWhileAnimating={true}
      backdropTransitionInTiming={1}
      backdropTransitionOutTiming={1}
      animationOutTiming={1}
      animationInTiming={1}
      deviceWidth={windowDimensions.width}
      deviceHeight={windowDimensions.height}>
      <TouchableWithoutFeedback touchSoundDisabled={true}>
        <View
          style={[
            tw`sm:w-[438px] sm:mx-auto bg-white rounded-xl shadow-lg max-w-full p-6 items-start content-start`,
            props.contentContainerStyle,
          ]}>
          {/* Close modal button */}
          {props.onModalHide ? (
            <TouchableOpacity
              onPress={props.onModalHide}
              style={tw`absolute top-3 right-3 justify-center items-center cursor-pointer z-5`}>
              <CloseSVG stroke={tw.color('gray-500')} style={tw`w-8 h-8`} />
            </TouchableOpacity>
          ) : null}

          {props.title ? (
            <Text
              style={[
                tw`mb-4 max-w-[315px] w-full md:text-2xl text-xl md:leading-8 leading-[26px] font-bold text-gray-800`,
                props.titleStyle,
              ]}>
              {props.title}
            </Text>
          ) : null}

          {props.children}
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};
