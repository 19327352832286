import React, { useCallback, useState } from 'react';
import { tw } from '../../../utils/tailwind';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { formatCurrency, twCb, useT } from '../../../utils/helper';
import { EditSvg } from '../../../../assets/svg';
import { QuantityInput } from '../../shared/quantity-input';
import {
  CartType,
  LineItem,
} from '../../../api/types/cash-register/cart-types';
import { ModalLineItemForm } from '../modals/modal-line-item-form';
import { Product } from '../../../api/types/cash-register/product-category-types';
import { getLineItemInitialValues } from '../../../utils/cash-register/line-item-form.helper';
import { useLineItemMutations } from '../../../api/hooks/cash-register/line-items/use-line-item-mutations';
import { LineItemAttributes } from '../../../api/types/cash-register/line-item-types';
import { buildLineItemFromParams } from '../../../utils/cash-register/line-item.helper';
import { useProductFilter } from '../../../hooks/use-product-filter.hook';

interface LineItemListProps {
  cartType: CartType;
  lineItems: LineItem[];
  onLineItemUpdated: () => void;
}

export const LineItemList = (props: LineItemListProps) => {
  // Hooks
  const t = useT('screens', { keyPrefix: 'CartShow' });
  const { updateLineItem, changeLineItemQuantity, updateLineItemQueryData } =
    useLineItemMutations();
  const [isModalLineItemFormVisible, setIsModalLineItemFormVisible] =
    useState<boolean>(false);
  const [product, setProduct] = useState<Product>();
  const [selectedLineItem, setSelectedLineItem] = useState<LineItem>();
  const { filter } = useProductFilter(props.cartType);
  // Functions
  const toggleLineItemFormModal = useCallback(
    (line_item?: LineItem) => {
      const lineItemProduct = filter({ productId: line_item?.product_id })?.[0];
      setSelectedLineItem(line_item);
      setProduct(lineItemProduct);
      setIsModalLineItemFormVisible(!!line_item);
    },
    [props.cartType],
  );

  // Callbacks
  const onFormSubmit = (attributes: LineItemAttributes) => {
    // Optimistic update
    if (product) {
      updateLineItemQueryData(buildLineItemFromParams(attributes, product));
    }
    // Update line item
    updateLineItem
      .mutateAsync(attributes)
      .then(() => props.onLineItemUpdated())
      .catch(() => {});
    // Close modal
    toggleLineItemFormModal();
  };
  // Other variables
  const hasLineItems = props.lineItems.length > 0;

  return (
    <>
      {selectedLineItem && product ? (
        <ModalLineItemForm
          isVisible={isModalLineItemFormVisible}
          onModalHide={() => toggleLineItemFormModal()}
          product={product}
          initialValues={getLineItemInitialValues(product, selectedLineItem)}
          onFormSubmit={onFormSubmit}
        />
      ) : null}

      <ScrollView
        style={tw`h-[316px]`}
        contentContainerStyle={tw`${twCb(
          'gap-y-6 mr-4',
          !hasLineItems ? 'm-auto' : '',
        )}`}>
        {!hasLineItems ? (
          <Text
            style={tw`justify-self-center self-center text-xl leading-none
                      text-gray-400`}>
            {t('orderIsEmpty')}
          </Text>
        ) : (
          props.lineItems.map((line_item, index) => (
            <View key={index} style={tw`flex flex-row justify-between`}>
              <View style={tw`grow-0`}>
                <View
                  style={tw`flex-row md:gap-x-1.5 gap-x-1 md:mr-3 rounded-lg
                                  border border-gray-100 overflow-hidden items-center
                                  justify-start`}>
                  <QuantityInput
                    size="sm"
                    value={line_item.quantity}
                    onChange={qty =>
                      changeLineItemQuantity(line_item, qty)?.then(() =>
                        props.onLineItemUpdated(),
                      )
                    }
                  />
                </View>
              </View>
              <View style={tw`grow-1 shrink-1`}>
                <View style={tw`mx-3 gap-y-1.5 mt-3`}>
                  <Text
                    style={tw`md:text-lg md:leading-none text-base leading-none
                                        font-medium text-gray-800`}>
                    {line_item.name}
                  </Text>
                  <View
                    style={tw`gap-y-1.5 ml-3 md:text-base xl:leading-none text-[15px] leading-none`}>
                    {line_item.excluded_ingredients.map((ingredient, idx) => (
                      <Text key={idx}>{`- ${ingredient}`}</Text>
                    ))}
                    {line_item.included_ingredients.map((ingredient, idx) => (
                      <Text key={idx}>{`+ ${ingredient}`}</Text>
                    ))}
                    {line_item.comment ? (
                      <Text style={tw`font-bold text-gray-900`}>
                        {line_item.comment}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </View>
              <View style={tw`items-end grow-0`}>
                <TouchableOpacity
                  onPress={() => toggleLineItemFormModal(line_item)}
                  disabled={line_item.persisted === false}
                  style={tw`flex-row md:gap-x-4 gap-x-2 mt-3`}>
                  <Text
                    style={tw`md:text-lg md:leading-none text-[15px]
                                        leading-none font-medium text-gray-800`}>
                    {formatCurrency(line_item.total_price)}
                  </Text>
                  <EditSvg
                    style={tw`md:w-5 md:h-5 w-[18px] h-[18px]`}
                    stroke={tw.color('gray-600')}
                  />
                </TouchableOpacity>
              </View>
            </View>
          ))
        )}
      </ScrollView>
    </>
  );
};
